// import { SHOW_MENU } from "@/Redux/common/constant";
// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SHOW_MENU } from "../../Redux/common/constant";
import { URL } from "../../Redux/common/url";
// import { Link, useLocation } from "react-router-dom";
// import { SHOW_MENU } from "../../../Redux/common/constant";
// import { URL } from "../../../Redux/common/url";
// import { useRouter } from "next/router";

function Sidebar() {
  const [menu, setmenu] = useState(false);
  const [showHide, setshowHide] = useState("dashboard");
  const dispatch = useDispatch();
  const router = useNavigate();
  const id = useLocation();
  const { pathname } = id;

  const splitLocation = pathname.split("/");

  useEffect(() => {
    if (splitLocation[2] === undefined) {
      setshowHide("dashboard");
    } else if (splitLocation[2] === "product") {
      setshowHide("product");
    } else if (splitLocation[2] === "wallet") {
      setshowHide("wallet");
    } else if (splitLocation[2] === "business-profile") {
      setshowHide("business-profile");
    } else if (splitLocation[2] === "rank-report") {
      setshowHide("rank-report");
    } else if (splitLocation[2] === "ticket") {
      setshowHide("ticket");
    } else if (splitLocation[2] === "idcard") {
      setshowHide("idcard");
    } else if (splitLocation[2] === "genelogy") {
      setshowHide("genelogy");
    } else if (splitLocation[2] === "orders") {
      setshowHide("orders");
    } else if (splitLocation[2] === "monthly-report") {
      setshowHide("monthly-report");
    } else if (splitLocation[2] === "today-business") {
      setshowHide("today-business");
    } else if (splitLocation[2] === "my-direct-team") {
      setshowHide("my-direct-team");
    } else if (splitLocation[2] === "my-income") {
      setshowHide("my-income");
    } else if (splitLocation[2] === "my-statement") {
      setshowHide("my-statement");
    } else if (splitLocation[2] === "all-team") {
      setshowHide("all-team");
    } else if (splitLocation[2] === "challange-marquis") {
      setshowHide("challange-marquis");
    } else if (splitLocation[2] === "profile") {
      setshowHide("profile");
    } else if (splitLocation[2] === "license") {
      setshowHide("license");
     
    } else if (splitLocation[2] === "promotion") {
      setshowHide("promotion");
    }
  }, [router]);

  const getsubsusbcategoriesAction = (data) => {
    return { type: SHOW_MENU, payload: data };
  };

  const menu_show = useSelector((state) =>
    state?.allapi?.menu_show ? state?.allapi?.menu_show : false
  );

  const get_footer_all_data = useSelector((state) =>
    state?.allapi?.get_footer_all_data ? state?.allapi?.get_footer_all_data : {}
  );
  //   menu_show

  const hendleShowMenu = () => {
    const menuValue = menu_show == true ? false : true;
    dispatch(getsubsusbcategoriesAction(menuValue));
  };

  const [width, setWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth]);

  return (
    <div>
      <aside
        className={
          width < 769 && menu_show
            ? "navbar-aside ps ps--active-y show"
            : "navbar-aside ps ps--active-y"
        }
        id="offcanvas_aside"
      >
        <div className="aside-top">
          {/* <a href="index.html" className="brand-wrap">
            <img
                src="/assets/imgs/theme/logo.svg"
                className="logo"
                alt="Nest Dashboard"
              />
            </a> */}
          <Link
            to={"/"}
            className="brand-wrap"
            onClick={() => {
              //   setmenu(menu == true ? false : true);
              hendleShowMenu();
            }}
          >
            {/* <img
              src={URL.API_BASE_URL + get_footer_all_data?.socialIcon?.homeLogo}
              className="logo"
              alt=""
              style={{ maxWidth: "120px", minWidth: "120px" }}
            /> */}
           <h6>Ril India</h6> 
          </Link>
          <div>
            <button className="menubtn btn-icon btn-aside-minimize">
              <i
                className="text-muted material-icons md-menu_open"
                onClick={() => {
                  //   setmenu(menu == true ? false : true);
                  hendleShowMenu();
                }}
              ></i>
            </button>
          </div>
        </div>
        <nav>
          <ul className="menu-aside">
            {/* <li className="menu-item active">
                <a className="menu-link" href="index.html">
                  <i className="icon material-icons md-home"></i>
                  <span className="text">Dashboard</span>
                </a>
              </li> */}
            <li
              className={
                showHide == "dashboard" ? "menu-item active" : "menu-item"
              }
              onClick={() => {
                //   setmenu(menu == true ? false : true);
                hendleShowMenu();
              }}
            >
              <Link to={"/home"} className="menu-link">
                <i className="fa fa-tachometer"></i>
                <span className="text">Dashboard</span>
              </Link>
              {/* <a className="menu-link" href="index.html">
                  <i className="icon material-icons md-home"></i>
                  <span className="text">Dashboard</span>
                </a> */}
            </li>
            {/* <li
                className={
                  showHide == "product" ? "menu-item active" : "menu-item"
                }
              >
                <Link href={"/home/product"} className="menu-link">
                  <i className="icon material-icons md-shopping_bag"></i>
                  <span className="text">Product</span>
                </Link>
              
              </li>

              <li
                className={
                  showHide == "whatsapp-inquiry"
                    ? "menu-item active"
                    : "menu-item"
                }
              >
                <Link href={"/home/whatsapp-inquiry"} className="menu-link">
                  <i className="icon material-icons md-comment"></i>
                  <span className="text">Whatsapp Inquiry</span>
                </Link>
              </li>
              <li
                className={
                  showHide == "email-inquiry" ? "menu-item active" : "menu-item"
                }
              >
                <Link href={"/home/email-inquiry"} className="menu-link">
                  <i className="icon material-icons md-comment"></i>
                  <span className="text">Email Inquiry</span>
                </Link>
              </li>
              <li
                className={
                  showHide == "ticket" ? "menu-item active" : "menu-item"
                }
              >
                <Link href={"/home/ticket"} className="menu-link">
                  <i className="icon material-icons md-stars"></i>
                  <span className="text">Ticket</span>
                </Link>
              </li> */}
            {/* <li
              className={
                showHide == "promotion" ? "menu-item active" : "menu-item"
              }
              onClick={() => {
                //   setmenu(menu == true ? false : true);
                hendleShowMenu();
              }}
            >
              <Link to={"/home/promotion"} className="menu-link">
                <i className="fa fa-user-md"></i>
                <span className="text">Promotion</span>
              </Link>
            </li> */}
            <li
              className={
                showHide == "profile" ? "menu-item active" : "menu-item"
              }
              onClick={() => {
                //   setmenu(menu == true ? false : true);
                hendleShowMenu();
              }}
            >
              <Link to={"/home/profile"} className="menu-link">
                <i className="fa fa-user-md"></i>
                <span className="text">Profile</span>
              </Link>
            </li>
            <li
              className={
                showHide == "orders" ? "menu-item active" : "menu-item"
              }
              onClick={() => {
                //   setmenu(menu == true ? false : true);
                hendleShowMenu();
              }}
            >
              <Link to={"/home/orders"} className="menu-link">
                <i className="fa fa-shopping-bag"></i>
                <span className="text">Orders</span>
              </Link>
            </li>
            <li
              className={
                showHide == "wallet" ? "menu-item active" : "menu-item"
              }
              onClick={() => {
                //   setmenu(menu == true ? false : true);
                hendleShowMenu();
              }}
            >
              <Link to={"/home/wallet"} className="menu-link">
                <i className="fa fa-money"></i>
                <span className="text">Wallet</span>
              </Link>
            </li>
            <li
              onClick={() => {
                //   setmenu(menu == true ? false : true);
                hendleShowMenu();
              }}
              className={
                showHide == "genelogy" ? "menu-item active" : "menu-item"
              }
            >
              <Link to={"/home/genelogy"} className="menu-link">
                <i className="fa fa-user"></i>
                <span className="text">Genelogy</span>
              </Link>
            </li>
            {/* <li
                className={
                  showHide == "business-profile"
                    ? "menu-item active"
                    : "menu-item"
                }
              >
                <Link href={"/home/business-profile"} className="menu-link">
                  <i className="icon material-icons md-store"></i>
                  <span className="text">Business Setup</span>
                </Link>
              </li> */}
            <li
              className={
                showHide == "idcard" ? "menu-item active" : "menu-item"
              }
              onClick={() => {
                //   setmenu(menu == true ? false : true);
                hendleShowMenu();
              }}
            >
              <Link to={"/home/idcard"} className="menu-link">
                <i className="fa fa-address-card-o"></i>
                <span className="text">Id Card </span>
              </Link>
            </li>
            <li
              onClick={() => {
                //   setmenu(menu == true ? false : true);
                hendleShowMenu();
              }}
              className={
                showHide == "my-direct-team" ? "menu-item active" : "menu-item"
              }
            >
              <Link to={"/home/my-direct-team"} className="menu-link">
                <i className="fa fa-users"></i>
                <span className="text">My direct team</span>
              </Link>
            </li>
            <li
              onClick={() => {
                //   setmenu(menu == true ? false : true);
                hendleShowMenu();
              }}
              className={
                showHide == "all-team" ? "menu-item active" : "menu-item"
              }
            >
              <Link to={"/home/all-team"} className="menu-link">
                <i className="fa fa-users"></i>
                <span className="text">All team</span>
              </Link>
            </li>
            <li
              onClick={() => {
                //   setmenu(menu == true ? false : true);
                hendleShowMenu();
              }}
              className={
                showHide == "my-statement" ? "menu-item active" : "menu-item"
              }
            >
              <Link to={"/home/my-statement"} className="menu-link">
                <i className="fa fa-university"></i>
                <span className="text">My Statement</span>
              </Link>
            </li>
            {/* <li
                className={
                  showHide == "idcard"
                    ? "menu-item active"
                    : "menu-item"
                }
              >
                <Link href={"/home/business-setup"} className="menu-link">
                  <i className="icon material-icons md-store"></i>
                  <span className="text">Business Setup</span>
                </Link>
              
              </li> */}
            <li
              className={
                showHide == "rank-report" ? "menu-item active" : "menu-item"
              }
              onClick={() => {
                //   setmenu(menu == true ? false : true);
                hendleShowMenu();
              }}
            >
              <Link to={"/home/rank-report"} className="menu-link">
                <i className="fa fa-line-chart"></i>
                <span className="text">Rank Report</span>
              </Link>
            </li>
            <li
              className={
                showHide == "my-income" ? "menu-item active" : "menu-item"
              }
              onClick={() => {
                //   setmenu(menu == true ? false : true);
                hendleShowMenu();
              }}
            >
              <Link to={"/home/my-income"} className="menu-link">
                <i className="fa fa-money"></i>
                <span className="text">My Income</span>
              </Link>
            </li>
            <li
              className={
                showHide == "monthly-report" ? "menu-item active" : "menu-item"
              }
              onClick={() => {
                //   setmenu(menu == true ? false : true);
                hendleShowMenu();
              }}
            >
              <Link to={"/home/monthly-report"} className="menu-link">
                <i className="fa fa-calendar-plus-o"></i>
                <span className="text">Manthly Report</span>
              </Link>
            </li>

            <li
              className={
                showHide == "ticket" ? "menu-item active" : "menu-item"
              }
              onClick={() => {
                //   setmenu(menu == true ? false : true);
                hendleShowMenu();
              }}
            >
              <Link to={"/home/ticket"} className="menu-link">
                <i className="fa fa-ticket"></i>
                <span className="text">Ticket</span>
              </Link>
            </li>
            {/* <li
                className={
                  showHide == "license" ? "menu-item active" : "menu-item"
                }
                onClick={() => {
                  //   setmenu(menu == true ? false : true);
                  hendleShowMenu();
                }}
              >
                <Link href={"/home/license"} className="menu-link">
                  <i className="icon material-icons md-monetization_on"></i>
                  <span className="text">License</span>
                </Link>
              </li> */}
            {/* <li
              className={
                showHide == "today-business" ? "menu-item active" : "menu-item"
              }
              onClick={() => {
                //   setmenu(menu == true ? false : true);
                hendleShowMenu();
              }}
            >
              <Link to={"/home/today-business"} className="menu-link">
                <i className="fa fa-building-o"></i>
                <span className="text">Today Business</span>
              </Link>
            </li> */}
            <li
              className={
                showHide == "challange-marquis"
                  ? "menu-item active"
                  : "menu-item"
              }
              onClick={() => {
                //   setmenu(menu == true ? false : true);
                hendleShowMenu();
              }}
            >
              <Link to={"/home/challange-marquis"} className="menu-link">
                <i className="fa fa-american-sign-language-interpreting"></i>
                <span className="text">Challange Marquis</span>
              </Link>
            </li>

            {/* <li className="menu-item has-submenu">
                <a className="menu-link" href="page-products-list.html">
                  <i className="icon material-icons md-shopping_bag"></i>
                  <span className="text">Products</span>
                </a>
                <div className="submenu">
                  <a href="page-products-list.html">Product List</a>
                  <a href="page-products-grid.html">Product grid</a>
                  <a href="page-products-grid-2.html">Product grid 2</a>
                  <a href="page-categories.html">Categories</a>
                </div>
              </li> */}
            {/*  <li className="menu-item has-submenu">
                <a className="menu-link" href="page-orders-1.html">
                  <i className="icon material-icons md-shopping_cart"></i>
                  <span className="text">Orders</span>
                </a>
                <div className="submenu">
                  <a href="page-orders-1.html">Order list 1</a>
                  <a href="page-orders-2.html">Order list 2</a>
                  <a href="page-orders-detail.html">Order detail</a>
                </div>
              </li>
              <li className="menu-item has-submenu">
                <a className="menu-link" href="page-sellers-cards.html">
                  <i className="icon material-icons md-store"></i>
                  <span className="text">Sellers</span>
                </a>
                <div className="submenu">
                  <a href="page-sellers-cards.html">Sellers cards</a>
                  <a href="page-sellers-list.html">Sellers list</a>
                  <a href="page-seller-detail.html">Seller profile</a>
                </div>
              </li>
              <li className="menu-item has-submenu">
                <a className="menu-link" href="page-form-product-1.html">
                  <i className="icon material-icons md-add_box"></i>
                  <span className="text">Add product</span>
                </a>
                <div className="submenu">
                  <a href="page-form-product-1.html">Add product 1</a>
                  <a href="page-form-product-2.html">Add product 2</a>
                  <a href="page-form-product-3.html">Add product 3</a>
                  <a href="page-form-product-4.html">Add product 4</a>
                </div>
              </li>
              <li className="menu-item has-submenu">
                <a className="menu-link" href="page-transactions-1.html">
                  <i className="icon material-icons md-monetization_on"></i>
                  <span className="text">Transactions</span>
                </a>
                <div className="submenu">
                  <a href="page-transactions-1.html">Transaction 1</a>
                  <a href="page-transactions-2.html">Transaction 2</a>
                </div>
              </li>
              <li className="menu-item has-submenu">
                <a className="menu-link" href="#">
                  <i className="icon material-icons md-person"></i>
                  <span className="text">Account</span>
                </a>
                <div className="submenu">
                  <a href="page-account-login.html">User login</a>
                  <a href="page-account-register.html">User registration</a>
                  <a href="page-error-404.html">Error 404</a>
                </div>
              </li>
              <li className="menu-item">
                <a className="menu-link" href="page-reviews.html">
                  <i className="icon material-icons md-comment"></i>
                  <span className="text">Reviews</span>
                </a>
              </li>
              <li className="menu-item">
                <a className="menu-link" href="page-brands.html">
                  {" "}
                  <i className="icon material-icons md-stars"></i>{" "}
                  <span className="text">Brands</span>{" "}
                </a>
              </li>
              <li className="menu-item">
                <a className="menu-link" disabled href="#">
                  <i className="icon material-icons md-pie_chart"></i>
                  <span className="text">Statistics</span>
                </a>
              </li> */}
          </ul>
          <hr />
          <ul className="menu-aside">
            {/* <li className="menu-item has-submenu">
                <a className="menu-link" href="#">
                  <i className="icon material-icons md-settings"></i>
                  <span className="text">Settings</span>
                </a>
                <div className="submenu">
                  <a href="page-settings-1.html">Setting sample 1</a>
                  <a href="page-settings-2.html">Setting sample 2</a>
                </div>
              </li>
              <li className="menu-item">
                <a className="menu-link" href="page-blank.html">
                  <i className="icon material-icons md-local_offer"></i>
                  <span className="text"> Starter page </span>
                </a>
              </li> */}
          </ul>
          <br />
          <br />
        </nav>
      </aside>
    </div>
  );
}

export default Sidebar;

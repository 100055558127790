// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import FooterMain from "../Layout/FooterMain";
import HeaderMain from "../Layout/HeaderMain";
import AdSlider from "./AdSlider";
import TruncateText from "../TruncateText/TruncateText";
import { Image } from "react-bootstrap";

function WishListApp() {
  const get_all_cart_itme = useSelector((state) =>
    state?.allapi?.get_all_wishlist_itme
      ? state?.allapi?.get_all_wishlist_itme
      : []
  );
  const get_marquse_list = useSelector((state) =>
    state?.allapi?.get_marquse_list ? state?.allapi?.get_marquse_list : []
  );
  const navigate = useNavigate();
  const totalSubtotalPrice = get_all_cart_itme.reduce((total, obj) => {
    return total + obj.subtotalprice;
  }, 0);
  const totalSubtotalPricegst = get_all_cart_itme.reduce((total, obj) => {
    return total + (obj.subtotalprice * obj?.gst) / 100;
  }, 0);

  console.log(totalSubtotalPricegst);

  const dispatch = useDispatch();

  const hendleCartClear = () => {
    if (localStorage.getItem("access_token")) {
      //   setitmeChange([]);
      dispatch(allapiAction.removeAllWishItem());
      return () => {};
    } else {
      //   setitmeChange([]);
      //   setArr([]);
      localStorage.setItem("cart_product", JSON.stringify([]));
      dispatch(allapiAction.getcartProduct({ data: [] }));
      return () => {};
    }

    // removeAllCartItem
  };

  const removeCart = (e, color, size) => {
    if (localStorage.getItem("access_token")) {
      dispatch(
        allapiAction.removeWishItem({
          //   userid: get_user_profile?.id,
          product_id: e,
          color: color,
          size: size,
        })
      );
      //   setTimeout(() => {
      //     dispatch(allapiAction.getcartitem({}));
      //   }, 500);
      return () => {};
    } else {
      var lists = get_all_cart_itme.filter((x) => {
        return x.product_id != e;
      });

      dispatch(
        allapiAction.getcartProduct({
          data: lists,
          //   userid: get_user_profile?.id,
        })
      );
      //   setArr(lists);
      localStorage.setItem("cart_product", JSON.stringify(lists));
      return () => {};
    }
  };

  const addItmeCount = (e, color, size) => {
    if (localStorage.getItem("access_token")) {
      dispatch(
        allapiAction.addItemCartCount({
          //   userid: get_user_profile?.id,
          product_id: e,
          color: color,
          size: size,
        })
      );
      return () => {};
    } else {
      const finalresult =
        get_all_cart_itme &&
        get_all_cart_itme?.map((data) => {
          data.item = data?.product_id == e ? data?.item + 1 : data?.item;
          return data;
        });
      //   setitmeChange(finalresult);
      //   setArr(finalresult);
      dispatch(
        allapiAction.getcartProduct({
          data: finalresult,
          //   userid: get_user_profile?.id,
        })
      );
      localStorage.setItem("cart_product", JSON.stringify(finalresult));
    }
  };
  const removeItmeCount = (e, color, size) => {
    if (localStorage.getItem("access_token")) {
      dispatch(
        allapiAction.removeItemCartCount({
          //   userid: get_user_profile?.id,
          product_id: e,
          color: color,
          size: size,
        })
      );
      return () => {};
    } else {
      const secondObj = get_all_cart_itme.find((item) => item._id == e);
      if (secondObj?.item > 1) {
        const finalresult =
          get_all_cart_itme &&
          get_all_cart_itme?.map((data) => {
            data.item = data?.product_id == e ? data?.item - 1 : data?.item;
            return data;
          });
        //   setitmeChange(finalresult);
        //   setArr(finalresult);
        dispatch(
          allapiAction.getcartProduct({
            data: finalresult,
            //   userid: get_user_profile?.id,
          })
        );
        localStorage.setItem("cart_product", JSON.stringify(finalresult));
      } else {
        toast.error("minimum quntity 1 select");
      }
    }
  };
  const shippingCharge = totalSubtotalPrice > 10000 ? 699 : 199;

  const totalAmount =
    totalSubtotalPrice + shippingCharge + totalSubtotalPricegst;

  const [value, setValue] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      setValue(localStorage.getItem("access_token"));
    } else {
      setValue("");
    }
    dispatch(allapiAction.getMarqusesList({}));

    return () => {};
  }, []);
  const [windowWidth, setWindowWidth] = useState(600);

  // Update window width when the window is resized
  useLayoutEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const imageWidth = windowWidth <= 767 ? 150 : 300;
  const imageHeight = windowWidth <= 767 ? 210 : 229;

  const hendleTomove = async (e, color, size) => {
    // moveToCart

    const response = await dispatch(
      allapiAction.moveToCart({ product_id: e, color: color, size: size })
    );

    dispatch(
      allapiAction.getcartitme({
        data: [],
        // userid: get_user_profile?.id,
      })
    );
    dispatch(
      allapiAction.getwishListitme({
        data: [],
        // userid: get_user_profile?.id,
      })
    );
  };
  return (
    <div className="AppPro">
      <HeaderMain />
      <main className="main">
        <div className="page-header breadcrumb-wrap">
          {get_all_cart_itme?.length > 0 && (
            <h1 className="heading-2 mb-10">Your Wish List</h1>
          )}
          <div className="home22  ">
            {/* ad_slider_list */}
            <AdSlider />
          </div>
          <div className="home22  ">
            <div class="marquee">
              {get_marquse_list &&
                get_marquse_list.map((item, index) => (
                  <span key={index}>{item.title} </span>
                ))}
              {/* <span>
                Exclusive offers on Men's Clothing - Up to 50% off! Hurry up!
              </span> */}
            </div>
          </div>
          <div className="container">
            {/* <div className="breadcrumb">
              <Link to="/" rel="nofollow">
                <i className="fi-rs-home mr-5"></i>home
              </Link>
              <span></span> Shop
              <span></span> Wish List
            </div> */}
          </div>
        </div>
        <div className="container-fluid mb-80 mt-10">
          {get_all_cart_itme?.length == 0 && (
            <div
              className="row justify-content-center align-items-center text-center"
              style={{
                height: "40vh",
                backgroundImage: "url(/wishlist.png)",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <div className="col-12">
                <h4>Your wishlist is empty</h4>
                <p className="  mt-3">
                  You have no items in your wish list. Start shopping to add
                  items.
                </p>
                <Link
                  to="/products?searchp="
                  className="btn btn-outline-danger mt-4"
                >
                  <i className="fi-rs-shopping-bag mr-5"></i>Start Shopping
                </Link>
              </div>
            </div>
          )}
          {get_all_cart_itme?.length > 0 && (
            <div className="row">
              <div className="col-lg-8 mb-10">
                <div className="d-flex justify-content-between">
                  <h6 className="text-body">
                    <span className="text-brand">
                      {get_all_cart_itme?.length}
                    </span>{" "}
                    products in your wish list
                  </h6>
                  <h6 className="text-body">
                    <a
                      onClick={() => {
                        hendleCartClear();
                      }}
                      className="text-muted"
                    >
                      <i className="fi-rs-trash mr-5"></i>Clear Wish List
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          )}
          {get_all_cart_itme?.length > 0 && (
            <div className="row">
              <div className="col-lg-2"></div>
              <div className="col-lg-8 wishp">
                {/* {get_all_cart_itme &&
                  get_all_cart_itme?.map((data, i) => {
                    console.log(data);
                    return (
                      <div class="cart-card row align-items-center">
                        <div className="col-2 imghe">
                          <img
                            src={URL.API_BASE_URL + data?.featureImage}
                            alt="Product Image"
                            class="img-fluid"
                            style={{ width: "60px", height: "60px" }}
                          />
                        </div>
                        <div className="col-9">
                          <div class="flex-grow-1">
                            <h5
                              class="mb-1"
                              onClick={() => {
                                navigate("/product-details/" + data?.pro_sulg);
                              }}
                            >
                              {data?.title}
                            </h5>
                            
                            <p class="text-muted mb-2">
                              {" "}
                              {data?.productcolorId?.color}
                            </p>
                            <p class="text-muted mb-2">
                              {data?.size} , ₹{data?.afterdiscountprice}{" "}
                            </p>
                            
                            <div class="d-flex align-items-center">
                               
                              
                            </div>
                          </div>
                        </div>
                        <div className="col-1">
                          <div class="text-end">
                            <a
                              onClick={(e) => {
                                removeCart(
                                  data?.productId?._id,
                                  data?.productcolorId?._id,
                                  data?._id
                                );
                              }}
                              className="text-body"
                            >
                              
                              <i className="fi-rs-trash mr-5"></i>
                              
                            </a>

                             
                          </div>
                        </div>
                         
                      </div>
                    );
                  })} */}
                <div className="row product-grid">
                  {get_all_cart_itme &&
                    get_all_cart_itme?.map((data, i) => {
                      const delaydata = i * 100;

                      return (
                        <div
                          class="col-md-3 col-6"
                          key={i}
                          data-aos="fade-right"
                          data-aos-delay={delaydata}
                        >
                          <div class="card" style={{ width: "auto" }}>
                            <div class="product-grid">
                              <div class="product-image position-relative">
                                <span
                                  onClick={() => {
                                    removeCart(
                                      data?.productId?._id,
                                      data?.productcolorId?._id,
                                      data?._id
                                    );
                                  }}
                                  className="position-absolute   btst"
                                  style={{
                                    background: "#fff",
                                    padding: "5px",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="fi-rs-trash ms-1 mr-5"></i>
                                </span>
                                <Link to={"/product-details/" + data?.pro_sulg}>
                                  {/* <img
                                                      class="pic-1"
                                                      src={URL.API_BASE_URL + data?.featureImage}
                                                    /> */}
                                  <Image
                                  style={{borderBottom:"1px solid #e6e6e6"}}
                                    className="default-img"
                                    src={URL.API_BASE_URL + data?.featureImage}
                                    alt={data?.title}
                                    width={imageWidth}
                                    height={imageHeight}
                                    // onError={(e) => {
                                    //   e.target.onerror = null;
                                    //   e.target.src =
                                    //     "/assets/imgs/notfound.png";
                                    // }}
                                  />
                                </Link>
                              </div>

                              <div class="card-body text-left pb-2 p-0">
                                {/* {data?.lable && data?.lable !== "undefined" && (
                                <span class="prolable ml-5  mb-5">
                                  {data?.lable}
                                </span>
                              )} */}
                                {/* <br />
                              <p class="abg ml-5 mt-5">{data?.brandId?.name}</p>
                              <br className="mb-5" /> */}
                                <Link
                                  to={"/product-details/" + data?.pro_sulg}
                                  class="card-text text-secondary ml-1 menu m-0"
                                >
                                  {/* {data?.title} */}

                                  <TruncateText
                                    text={data?.title}
                                    maxLength={23}
                                  />
                                </Link>

                                <br />
                                {value && (
                                  <p class="p-0 m-0 act ml-1">
                                    ₹&nbsp;{data?.afterdiscountprice}
                                    <i class="fa fa-podcast"></i>
                                  </p>
                                )}
                                {value && (
                                  <span class="act1 float-right">
                                    ₹ {data?.price}&nbsp;
                                  </span>
                                )}
                                <br />
                               <div className="p-2">
                               <button
                                  class="btn btn-outline-danger  newbtn mt-2 w-100 text-center"
                                  onClick={() => {
                                    hendleTomove(
                                      data?.productId?._id,
                                      data?.productcolorId?._id,
                                      data?._id
                                    );
                                  }}
                                >
                                  Move To Bag
                                </button>
                               </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="shopping-summery"></div>
                <div className="divider-2 mb-30"></div>
              </div>
              <div className="col-lg-2"></div>
            </div>
          )}
        </div>
      </main>
      <FooterMain />
    </div>
  );
}

export default WishListApp;

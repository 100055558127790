import React from "react";

const TruncateText = ({ text, maxLength }) => {
  if (text.length <= maxLength) {
    return (
      <span
        style={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          display: "block",
          // fontSize: "11px",
          // marginBottom: "3px",
          // lineHeight: "1rem",
        }}
      >
        {text}
      </span>
    );
  } else {
    const truncatedText = text.slice(0, maxLength) + "...";
    return (
      <span
        style={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          display: "block",
          // fontSize: "11px",
          // marginBottom: "3px",
          // lineHeight: "1rem",
        }}
      >
        {text}
      </span>
    );
  }
};

export default TruncateText;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import HeaderMain from "../Layout/HeaderMain";
import { URL } from "../../Redux/common/url";
import FooterMain from "../Layout/FooterMain";
import { allapiAction } from "../../Redux/common/action";

function Categories() {
  const all_categories_List = useSelector((state) =>
    state?.allapi?.all_categories_List ? state?.allapi?.all_categories_List : []
  );
const get_buss_pro_1 = useSelector((state) =>
    state?.allapi?.get_buss_pro_1 ? state?.allapi?.get_buss_pro_1 : {}
  );

const dispatch  = useDispatch()
  const [value, setValue] = useState("");
  
    useEffect(() => {
      if (typeof window !== "undefined") {
        setValue(localStorage.getItem("access_token"));
        if (localStorage.getItem("access_token")) {
          // 1
          dispatch(allapiAction.getBussProfile1({}));
        }
      } else {
        setValue("");
      }
    }, []);
  const navigate = useNavigate();
  const [activeCate, setactiveCate] = useState(0);
  const [subCate, setsubCate] = useState([]);
  console.log(subCate);

  useEffect(() => {
    if (all_categories_List?.length > 0) {
      setsubCate(all_categories_List[0]?.subcates);
    }

    return () => {};
  }, [all_categories_List]);

  const hendleTochangeCate = (i) => {
    setactiveCate(i);
    setsubCate(all_categories_List[i]?.subcates);
  };

  return (
    <div className="appCategory ">
      <HeaderMain />
      {/* <div class="container header-down-position ">
        <div class="row bg-white">
          <hr class="hr-down " />
          <div class="d-flex justify-content-around align-items-center text-center ">
            <a class="header-down-a" href="Nykaa.html">
              <div>
                <img class="header-down-img" src="img/icons8-n-50.png" alt="" />
                <h6 class="header-down-h6">Home</h6>
              </div>
            </a>
            <a class="header-down-a" href="offers.html">
              <div>
                <img
                  class="header-down-img"
                  src="img/icons8-badge-50.png"
                  alt=""
                />
                <h6 class="header-down-h6">Offers</h6>
              </div>
            </a>
            <a class="header-down-a" href="Categories.html">
              <div>
                <img
                  class="header-down-img"
                  src="img/icons8-menu-50.png"
                  alt=""
                />
                <h6 class="header-down-h6">Categories</h6>
              </div>
            </a>
            <a class="header-down-a" href="Play.html">
              <div>
                <img class="header-down-img" src="img/icons8-play.gif" alt="" />
                <h6 class="header-down-h6">Play</h6>
              </div>
            </a>
            <a class="header-down-a" href="account.html">
              <div>
                <img
                  class="header-down-img"
                  src="img/icons8-account.gif"
                  alt=""
                />
                <h6 class="header-down-h6">Account</h6>
              </div>
            </a>
          </div>
        </div>
      </div> */}

      <div class="container mt-5 pt-5 ">
        <div class="row mb-5 pb-5">
          <ul class="nav nav-tabs" id="categoryTabs" role="tablist">
            {all_categories_List &&
              all_categories_List?.map((data, i) => {
                return (
                  <li class="nav-item" role="presentation">
                    <button
                      class={
                        activeCate == i
                          ? "nav-link active text-dark"
                          : "nav-link text-dark"
                      }
                      onClick={() => {
                        hendleTochangeCate(i);
                      }}
                      id="women-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#women"
                      type="button"
                      role="tab"
                      aria-controls="women"
                      aria-selected="true"
                    >
                      {data?.name}
                    </button>
                  </li>
                );
              })}
            {/* 
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active text-dark"
                id="women-tab"
                data-bs-toggle="tab"
                data-bs-target="#women"
                type="button"
                role="tab"
                aria-controls="women"
                aria-selected="true"
              >
                Women
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link text-dark"
                id="men-tab"
                data-bs-toggle="tab"
                data-bs-target="#men"
                type="button"
                role="tab"
                aria-controls="men"
                aria-selected="false"
              >
                Men
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link text-dark"
                id="kids-tab"
                data-bs-toggle="tab"
                data-bs-target="#kids"
                type="button"
                role="tab"
                aria-controls="kids"
                aria-selected="false"
              >
                Kids
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link text-dark"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="false"
              >
                Home
              </button>
            </li> */}
          </ul>

          <div class="tab-content m-0 p-0" id="categoryTabsContent">
            <div
              class="tab-pane fade show active"
              id="women"
              role="tabpanel"
              aria-labelledby="women-tab"
            >
              <div class="container-fluid main-categories">
                <div class="d-flex justify-content-around gap-1 p-2">
                  <Link class="text-decoration-none text-dark" to="/offer-product">
                    <div class="border d-flex flex-column justify-content-center align-items-center  p-2 bg-white">
                      <img
                        class="border rounded-5 p-1"
                        src="img/cat 1.png"
                        alt=""
                      />
                      <p class="mt-2">
                        Sale <span class="text-nowrap">50% Off</span>
                      </p>
                    </div>
                  </Link>
                  <Link class="text-decoration-none text-dark" to="/best-selling-product">
                    <div class="border d-flex flex-column justify-content-center align-items-center  p-2 bg-white">
                      <img
                        class="border rounded-5 p-1"
                        src="img/cat2.png"
                        alt=""
                      />
                      <p class="mt-2">Best Sellers</p>
                    </div>
                  </Link>
                  <Link class="text-decoration-none text-dark" to="/new-launch">
                    <div class="border d-flex flex-column justify-content-center align-items-center  p-2 bg-white">
                      <img
                        class="border rounded-5 p-1"
                        src="img/cat 3.png"
                        alt=""
                      />
                      <p class="mt-2">What's New</p>
                    </div>
                  </Link>
                { (value && get_buss_pro_1?.status) > 2 && 
                 <Link class="text-decoration-none text-dark" to="/gpg-product">
                    <div class="border d-flex flex-column justify-content-center align-items-center  p-2 bg-white">
                      <img
                        class="border rounded-5 p-1"
                        src="img/cat 4.png"
                        alt=""
                      />
                      <p class="mt-2">GPG Product</p>
                    </div>
                  </Link>}
                </div>
              </div>

              <div class="container">
                <div class="row">
                  <h5 class="fw-bold mt-3">Shop by Category</h5>

                  {/* <a class="text-dark text-decoration-none" href="">
                    <div class="d-flex justify-content-between mt-3">
                      <div class="d-flex justify-content-between">
                        <img class="w-100" src="img/wom1.jpg" alt="" />
                        <p class="d-flex justify-content-center align-items-center photo-category fw-bold mt-3">
                          Indianwear
                        </p>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <img src="img/man aero.png" alt="" />
                      </div>
                    </div>
                  </a> */}

                  {subCate &&
                    subCate?.map((data, i) => {
                      return (
                        <Link
                          class="text-dark text-decoration-none"
                          to={`/sub-category-product/${data?.cate_sulg}`}
                        >
                          <div class="d-flex justify-content-between mt-3">
                            <div class="d-flex justify-content-between">
                              <img
                                // class="w-100"
                                src={URL?.API_BASE_URL + data?.image}
                                alt=""
                                style={{ width: "50px" }}
                              />
                              <p class="d-flex justify-content-center align-items-center photo-category fw-bold mt-3">
                                {data?.name}
                              </p>
                            </div>
                            <div class="d-flex justify-content-center align-items-center">
                              <img src="/assets/imgs/man aero.png" alt="" />
                            </div>
                          </div>
                        </Link>
                      );
                    })}

                  {/* <a class="text-dark text-decoration-none" href="">
                    <div class="d-flex justify-content-between mt-3">
                      <div class="d-flex justify-content-between">
                        <img class="w-100" src="/assets/imgs/wom2.jpg" alt="" />
                        <p class="d-flex justify-content-center align-items-center photo-category fw-bold mt-3">
                          Westernwear
                        </p>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <img src="img/man aero.png" alt="" />
                      </div>
                    </div>
                  </a>

                  <a class="text-dark text-decoration-none" href="">
                    <div class="d-flex justify-content-between mt-3">
                      <div class="d-flex justify-content-between">
                        <img class="w-100" src="/assets/imgs/wom2.jpg" alt="" />
                        <p class="d-flex justify-content-center align-items-center photo-category fw-bold mt-3">
                          Lingerie
                        </p>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <img src="img/man aero.png" alt="" />
                      </div>
                    </div>
                  </a>

                  <a class="text-dark text-decoration-none" href="">
                    <div class="d-flex justify-content-between mt-3">
                      <div class="d-flex justify-content-between">
                        <img class="w-100" src="/assets/imgs/wom2.jpg" alt="" />
                        <p class="d-flex justify-content-center align-items-center photo-category fw-bold mt-3">
                          Footwear
                        </p>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <img src="img/man aero.png" alt="" />
                      </div>
                    </div>
                  </a>

                  <a class="text-dark text-decoration-none" href="">
                    <div class="d-flex justify-content-between mt-3">
                      <div class="d-flex justify-content-between">
                        <img class="w-100" src="img/wom5.jpg" alt="" />
                        <p class="d-flex justify-content-center align-items-center photo-category fw-bold mt-3">
                          Accessories
                        </p>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <img src="img/man aero.png" alt="" />
                      </div>
                    </div>
                  </a>

                  <a class="text-dark text-decoration-none" href="">
                    <div class="d-flex justify-content-between mt-3">
                      <div class="d-flex justify-content-between">
                        <img class="w-100" src="img/wom6.jpg" alt="" />
                        <p class="d-flex justify-content-center align-items-center photo-category fw-bold mt-3">
                          Watch
                        </p>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <img src="img/man aero.png" alt="" />
                      </div>
                    </div>
                  </a> */}
                </div>
              </div>
            </div>

            <div
              class="tab-pane fade"
              id="men"
              role="tabpanel"
              aria-labelledby="men-tab"
            >
              <div class="container-fluid main-categories">
                <div class="d-flex justify-content-around gap-1 p-2">
                  <a class="text-decoration-none text-dark" href="">
                    <div class="border d-flex flex-column justify-content-center align-items-center  p-2 bg-white">
                      <img
                        class="border rounded-5 p-1"
                        src="img/cat 1.png"
                        alt=""
                      />
                      <p class="mt-2">
                        Sale <span class="text-nowrap">70% Off</span>
                      </p>
                    </div>
                  </a>
                  <a class="text-decoration-none text-dark" href="">
                    <div class="border d-flex flex-column justify-content-center align-items-center  p-2 bg-white">
                      <img
                        class="border rounded-5 p-1"
                        src="img/cat2.png"
                        alt=""
                      />
                      <p class="mt-2">Best Sellers</p>
                    </div>
                  </a>
                  <a class="text-decoration-none text-dark" href="">
                    <div class="border d-flex flex-column justify-content-center align-items-center  p-2 bg-white">
                      <img
                        class="border rounded-5 p-1"
                        src="img/cat 3.png"
                        alt=""
                      />
                      <p class="mt-2">What's New</p>
                    </div>
                  </a>
                  <a class="text-decoration-none text-dark" href="">
                    <div class="border d-flex flex-column justify-content-center align-items-center  p-2 bg-white">
                      <img
                        class="border rounded-5 p-1"
                        src="img/cat 4.png"
                        alt=""
                      />
                      <p class="mt-2">Express Shipping</p>
                    </div>
                  </a>
                </div>
              </div>
              <div class="container">
                <div class="row">
                  <h5 class="fw-bold mt-3">Shop by Category</h5>

                  <a class="text-dark text-decoration-none" href="">
                    <div class="d-flex justify-content-between mt-3">
                      <div class="d-flex justify-content-between">
                        <img class="w-100" src="img/man1.jpg" alt="" />
                        <p class="d-flex justify-content-center align-items-center photo-category fw-bold mt-3">
                          Topwear
                        </p>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <img src="img/man aero.png" alt="" />
                      </div>
                    </div>
                  </a>

                  <a class="text-dark text-decoration-none" href="">
                    <div class="d-flex justify-content-between mt-3">
                      <div class="d-flex justify-content-between">
                        <img class="w-100" src="img/man2.jpg" alt="" />
                        <p class="d-flex justify-content-center align-items-center photo-category fw-bold mt-3">
                          Bottomwear
                        </p>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <img src="img/man aero.png" alt="" />
                      </div>
                    </div>
                  </a>

                  <a class="text-dark text-decoration-none" href="">
                    <div class="d-flex justify-content-between mt-3">
                      <div class="d-flex justify-content-between">
                        <img class="w-100" src="img/man3.jpg" alt="" />
                        <p class="d-flex justify-content-center align-items-center photo-category fw-bold mt-3">
                          Ethnicwear
                        </p>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <img src="img/man aero.png" alt="" />
                      </div>
                    </div>
                  </a>

                  <a class="text-dark text-decoration-none" href="">
                    <div class="d-flex justify-content-between mt-3">
                      <div class="d-flex justify-content-between">
                        <img class="w-100" src="img/man5.jpg" alt="" />
                        <p class="d-flex justify-content-center align-items-center photo-category fw-bold mt-3">
                          Footwear
                        </p>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <img src="img/man aero.png" alt="" />
                      </div>
                    </div>
                  </a>
                  <a class="text-dark text-decoration-none" href="">
                    <div class="d-flex justify-content-between mt-3">
                      <div class="d-flex justify-content-between">
                        <img class="w-100" src="img/man4.jpg" alt="" />
                        <p class="d-flex justify-content-center align-items-center photo-category fw-bold mt-3">
                          Accessories
                        </p>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <img src="img/man aero.png" alt="" />
                      </div>
                    </div>
                  </a>
                  <a class="text-dark text-decoration-none" href="">
                    <div class="d-flex justify-content-between mt-3">
                      <div class="d-flex justify-content-between">
                        <img class="w-100" src="img/man6.jpg" alt="" />
                        <p class="d-flex justify-content-center align-items-center photo-category fw-bold mt-3">
                          Watch
                        </p>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <img src="img/man aero.png" alt="" />
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div
              class="tab-pane fade"
              id="kids"
              role="tabpanel"
              aria-labelledby="kids-tab"
            >
              <div class="container-fluid main-categories">
                <div class="d-flex justify-content-around gap-1 p-2">
                  <a class="text-decoration-none text-dark" href="">
                    <div class="border d-flex flex-column justify-content-center align-items-center  p-2 bg-white">
                      <img
                        class="border rounded-5 p-1"
                        src="img/cat 1.png"
                        alt=""
                      />
                      <p class="mt-2">
                        Sale <span class="text-nowrap">70% Off</span>
                      </p>
                    </div>
                  </a>
                  <a class="text-decoration-none text-dark" href="">
                    <div class="border d-flex flex-column justify-content-center align-items-center  p-2 bg-white">
                      <img
                        class="border rounded-5 p-1"
                        src="img/cat2.png"
                        alt=""
                      />
                      <p class="mt-2">Best Sellers</p>
                    </div>
                  </a>
                  <a class="text-decoration-none text-dark" href="">
                    <div class="border d-flex flex-column justify-content-center align-items-center  p-2 bg-white">
                      <img
                        class="border rounded-5 p-1"
                        src="img/cat 3.png"
                        alt=""
                      />
                      <p class="mt-2">What's New</p>
                    </div>
                  </a>
                  <a class="text-decoration-none text-dark" href="">
                    <div class="border d-flex flex-column justify-content-center align-items-center  p-2 bg-white">
                      <img
                        class="border rounded-5 p-1"
                        src="img/cat 4.png"
                        alt=""
                      />
                      <p class="mt-2">Express Shipping</p>
                    </div>
                  </a>
                </div>
              </div>
              <div class="container">
                <div class="row">
                  <h5 class="fw-bold mt-3">Shop For Girls</h5>

                  <a class="text-dark text-decoration-none" href="">
                    <div class="d-flex justify-content-between mt-2">
                      <div class="d-flex justify-content-between">
                        <img class="w-100" src="img/girl1.jpg" alt="" />

                        <div class="photo-category-kids photo-category mt-3">
                          <p class=" photo-category fw-bold p-0 m-0">Infant</p>
                          <p class=" p-0 m-0  photo-category text-nowrap">
                            0-2 years
                          </p>
                        </div>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <img src="img/man aero.png" alt="" />
                      </div>
                    </div>
                  </a>

                  <a class="text-dark text-decoration-none" href="">
                    <div class="d-flex justify-content-between mt-3">
                      <div class="d-flex justify-content-between">
                        <img class="w-100" src="img/girl2.jpg" alt="" />

                        <div class="photo-category-kids photo-category mt-3">
                          <p class=" photo-category fw-bold p-0 m-0">Toddler</p>
                          <p class=" p-0 m-0  photo-category text-nowrap">
                            2-4 years
                          </p>
                        </div>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <img src="img/man aero.png" alt="" />
                      </div>
                    </div>
                  </a>

                  <a class="text-dark text-decoration-none" href="">
                    <div class="d-flex justify-content-between mt-3">
                      <div class="d-flex justify-content-between">
                        <img class="w-100" src="img/girl3.jpg" alt="" />

                        <div class="photo-category-kids photo-category mt-3">
                          <p class=" photo-category fw-bold p-0 m-0">Child</p>
                          <p class=" p-0 m-0  photo-category  text-nowrap">
                            4-8 years
                          </p>
                        </div>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <img src="img/man aero.png" alt="" />
                      </div>
                    </div>
                  </a>

                  <a class="text-dark text-decoration-none" href="">
                    <div class="d-flex justify-content-between mt-3">
                      <div class="d-flex justify-content-between">
                        <img class="w-100" src="img/girl4.jpg" alt="" />

                        <div class="photo-category-kids photo-category mt-3">
                          <p class=" photo-category fw-bold p-0 m-0">Topwear</p>
                          <p class=" p-0 m-0  photo-category  text-nowrap">
                            8-12 years
                          </p>
                        </div>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <img src="img/man aero.png" alt="" />
                      </div>
                    </div>
                  </a>
                  <a class="text-dark text-decoration-none" href="">
                    <div class="d-flex justify-content-between mt-3">
                      <div class="d-flex justify-content-between">
                        <img class="w-100" src="img/girl5.jpg" alt="" />

                        <div class="photo-category-kids photo-category mt-3">
                          <p class=" photo-category fw-bold p-0 m-0">Teen</p>
                          <p class=" p-0 m-0  photo-category text-nowrap">
                            12-16 years
                          </p>
                        </div>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <img src="img/man aero.png" alt="" />
                      </div>
                    </div>
                  </a>
                  <a class="text-dark text-decoration-none" href="">
                    <div class="d-flex justify-content-between mt-3">
                      <div class="d-flex justify-content-between">
                        <img class="w-100" src="img/girl6.jpg" alt="" />

                        <div class="photo-category-kids photo-category mt-3">
                          <p class=" photo-category fw-bold p-0 m-0">Infant</p>
                          <p class=" p-0 m-0  photo-category text-nowrap">
                            0-2 years
                          </p>
                        </div>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <img src="img/man aero.png" alt="" />
                      </div>
                    </div>
                  </a>

                  <h5 class="fw-bold mt-4">Shop For Boy</h5>

                  <a class="text-dark text-decoration-none" href="">
                    <div class="d-flex justify-content-between mt-2">
                      <div class="d-flex justify-content-between">
                        <img class="w-100" src="img/baby1.jpg" alt="" />

                        <div class="photo-category-kids photo-category mt-3">
                          <p class=" photo-category fw-bold p-0 m-0">Infant</p>
                          <p class=" p-0 m-0  photo-category text-nowrap">
                            0-2 years
                          </p>
                        </div>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <img src="img/man aero.png" alt="" />
                      </div>
                    </div>
                  </a>

                  <a class="text-dark text-decoration-none" href="">
                    <div class="d-flex justify-content-between mt-3">
                      <div class="d-flex justify-content-between">
                        <img class="w-100" src="img/baby2.jpg" alt="" />

                        <div class="photo-category-kids photo-category mt-3">
                          <p class=" photo-category fw-bold p-0 m-0">Toddler</p>
                          <p class=" p-0 m-0  photo-category text-nowrap">
                            2-4 years
                          </p>
                        </div>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <img src="img/man aero.png" alt="" />
                      </div>
                    </div>
                  </a>

                  <a class="text-dark text-decoration-none" href="">
                    <div class="d-flex justify-content-between mt-3">
                      <div class="d-flex justify-content-between">
                        <img class="w-100" src="img/baby3.jpg" alt="" />

                        <div class="photo-category-kids photo-category mt-3">
                          <p class=" photo-category fw-bold p-0 m-0">Child</p>
                          <p class=" p-0 m-0  photo-category  text-nowrap">
                            4-8 years
                          </p>
                        </div>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <img src="img/man aero.png" alt="" />
                      </div>
                    </div>
                  </a>

                  <a class="text-dark text-decoration-none" href="">
                    <div class="d-flex justify-content-between mt-3">
                      <div class="d-flex justify-content-between">
                        <img class="w-100" src="img/baby4.jpg" alt="" />

                        <div class="photo-category-kids photo-category mt-3">
                          <p class=" photo-category fw-bold p-0 m-0">Topwear</p>
                          <p class=" p-0 m-0  photo-category  text-nowrap">
                            8-12 years
                          </p>
                        </div>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <img src="img/man aero.png" alt="" />
                      </div>
                    </div>
                  </a>
                  <a class="text-dark text-decoration-none" href="">
                    <div class="d-flex justify-content-between mt-3">
                      <div class="d-flex justify-content-between">
                        <img class="w-100" src="img/baby5.jpg" alt="" />

                        <div class="photo-category-kids photo-category mt-3">
                          <p class=" photo-category fw-bold p-0 m-0">Teen</p>
                          <p class=" p-0 m-0  photo-category text-nowrap">
                            12-16 years
                          </p>
                        </div>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <img src="img/man aero.png" alt="" />
                      </div>
                    </div>
                  </a>
                  <a class="text-dark text-decoration-none" href="">
                    <div class="d-flex justify-content-between mt-3">
                      <div class="d-flex justify-content-between">
                        <img class="w-100" src="img/baby6.jpg" alt="" />

                        <div class="photo-category-kids photo-category mt-3">
                          <p class=" photo-category fw-bold p-0 m-0">Teen</p>
                          <p class=" p-0 m-0  photo-category text-nowrap">
                            12-16 years
                          </p>
                        </div>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <img src="img/man aero.png" alt="" />
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div
              class="tab-pane fade"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div class="container-fluid main-categories">
                <div class="d-flex justify-content-around gap-1 p-2">
                  <a class="text-decoration-none text-dark" href="">
                    <div class="border d-flex flex-column justify-content-center align-items-center  p-2 bg-white">
                      <img
                        class="border rounded-5 p-1"
                        src="img/cat 1.png"
                        alt=""
                      />
                      <p class="mt-2">
                        Sale <span class="text-nowrap">70% Off</span>
                      </p>
                    </div>
                  </a>
                  <a class="text-decoration-none text-dark" href="">
                    <div class="border d-flex flex-column justify-content-center align-items-center  p-2 bg-white">
                      <img
                        class="border rounded-5 p-1"
                        src="img/cat2.png"
                        alt=""
                      />
                      <p class="mt-2">Best Sellers</p>
                    </div>
                  </a>
                  <a class="text-decoration-none text-dark" href="">
                    <div class="border d-flex flex-column justify-content-center align-items-center  p-2 bg-white">
                      <img
                        class="border rounded-5 p-1"
                        src="img/cat 3.png"
                        alt=""
                      />
                      <p class="mt-2">What's New</p>
                    </div>
                  </a>
                  <a class="text-decoration-none text-dark" href="">
                    <div class="border d-flex flex-column justify-content-center align-items-center  p-2 bg-white">
                      <img
                        class="border rounded-5 p-1"
                        src="img/cat 4.png"
                        alt=""
                      />
                      <p class="mt-2">Express Shipping</p>
                    </div>
                  </a>
                </div>
              </div>
              <div class="container">
                <div class="row">
                  <h5 class="fw-bold mt-3">Shop by Category</h5>

                  <a class="text-dark text-decoration-none" href="">
                    <div class="d-flex justify-content-between mt-3">
                      <div class="d-flex justify-content-between">
                        <img class="w-100" src="img/bartan1.jpg" alt="" />
                        <p class="d-flex justify-content-center align-items-center photo-category fw-bold mt-3 text-nowrap">
                          Shop All
                        </p>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <img src="img/man aero.png" alt="" />
                      </div>
                    </div>
                  </a>

                  <a class="text-dark text-decoration-none" href="">
                    <div class="d-flex justify-content-between mt-3">
                      <div class="d-flex justify-content-between">
                        <img class="w-100" src="img/bartan2.jpg" alt="" />
                        <p class="d-flex justify-content-center align-items-center photo-category fw-bold mt-3 text-nowrap">
                          Kitchen % Dining
                        </p>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <img src="img/man aero.png" alt="" />
                      </div>
                    </div>
                  </a>

                  <a class="text-dark text-decoration-none" href="">
                    <div class="d-flex justify-content-between mt-3">
                      <div class="d-flex justify-content-between">
                        <img class="w-100" src="img/bartan3.jpg" alt="" />
                        <p class="d-flex justify-content-center align-items-center photo-category fw-bold mt-3 text-nowrap">
                          Decor
                        </p>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <img src="img/man aero.png" alt="" />
                      </div>
                    </div>
                  </a>

                  <a class="text-dark text-decoration-none" href="">
                    <div class="d-flex justify-content-between mt-3">
                      <div class="d-flex justify-content-between">
                        <img class="w-100" src="img/bartan4.jpg" alt="" />
                        <p class="d-flex justify-content-center align-items-center photo-category fw-bold mt-3 text-nowrap">
                          Bedding
                        </p>
                      </div>
                      <div class="d-flex justify-content-center align-items-center">
                        <img src="img/man aero.png" alt="" />
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterMain/>
    </div>
  );
}

export default Categories;

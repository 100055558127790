import axios from "axios";
import { toast } from "react-toastify";
import { URL } from "../common/url";
import api from "../common/api";
import {
  LOADING,
  CREATE_CATEGORIES,
  GET_CATEGORIES,
  GET_SUB_SUB_CATEGORIES,
  GET_SELLER_PRODUCT_LIST,
  GET_SUB_SUB_CATE_LIST,
  OTP_BOX,
  GET_BUSS_PRO,
  GET_PACKAGE_LIST,
  GET_SELLER_TANSACTION,
  GET_PROFILE,
  GET_SELLER_TICKET_LIST,
  GET_MESSAGE_LISTS,
  GET_INVOICE,
  GET_PRODUCT_DETAILS,
  GET_SELLER_PRODUCT_LIST_PUBLICE,
  GET_TAGS_PRODUCT_LIST_PUBLICE,
  GET_SELLER_PRODUCT_LIST_PUBLICE_BY_CATEGORY,
  GET_SELLER_PROFILE,
  GET_SUB_SUB_CATE_LIST_FIL,
  GET_PAGES,
  GET_EMAIL_QU,
  GET_WHATSAPP_QU,
  PAGE_DETAILS,
  SEARCH_TAGS,
  SEARCH_PRODUCT_PUB,
  GET_FOOTER_DATA_ALL,
  GET_home_ALL_PRO,
  TOP_VIEW_ALL_TYPE,
  SELLER_LIST,
  DESHBOARD_STATE,
  SLIDER_LIST,
  BLOG_LIST,
  BLOG_DETAILS,
  GET_EXPIRE_DATE,
  home_POPS,
  FAQS_LIST,
  COUNTRY_LIST,
  LOADING_1,
  CITY_LIST,
  USER_PRODUCT_SERACH,
  GET_BUSS_PRO_1,
  ADD_CART_ITME,
  USER_PROFILE,
  CREATE_ADDRESS,
  ORDER_DETIALS,
  LOADING_CHECKOUT,
  SELLER_UPDATE_DETAILS,
  SELLER_DATE_REPORTS,
  MY_DIRECT_TEAM,
  GET_SUB_SUB_CATEGORY_LISTS,
  MY_INCOME_LIST,
  USER_INCOME_DATE,
  ALL_TEAM,
  WEEK_DATA,
  DAY_DATA,
  TEAM_COUNT,
  CHALLANGE_DATA,
  GAT_ALL_BRAND,
  ALL_TEAM_EXPORT,
  MY_LAST_THREE_MONTH,
  THEMEMAN,
  ORDER_LIST,
  TOP_THREE,
  USER_PRE_MONTH_COMMISSION,
  LEAD_ACHIVER,
  LEADER_BOARD_C_M,
  ADD_WISHLIST_ITME,
  GET_SUB_SUB_CATEGORIES_NEW,
  ALL_TEAM_ACTIVE,
  AD_SLIDER_LIST,
  MARQUSE_LIST,
} from "../common/constant";
const Token = require("../../Utils2/Auth/Token");

const loading = (data) => {
  return { type: LOADING, payload: data };
};
const loading1 = (data) => {
  return { type: LOADING_1, payload: data };
};
const loadingCheckOut = (data) => {
  return { type: LOADING_CHECKOUT, payload: data };
};
const createcategoriesAction = (data) => {
  return { type: CREATE_CATEGORIES, payload: data };
};
const getcategoriesAction = (data) => {
  return { type: GET_CATEGORIES, payload: data };
};
const getsubsusbcategoriesAction = (data) => {
  return { type: GET_SUB_SUB_CATEGORIES, payload: data };
};
const getsubsusbcategoriesActionNew = (data) => {
  return { type: GET_SUB_SUB_CATEGORIES_NEW, payload: data };
};
const getsubsusbcategoriesfillAction = (data) => {
  return { type: GET_SUB_SUB_CATE_LIST_FIL, payload: data };
};
const getsellerProductListAction = (data) => {
  return { type: GET_SELLER_PRODUCT_LIST, payload: data };
};
const getsellerProductListPublicAction = (data) => {
  return { type: GET_SELLER_PRODUCT_LIST_PUBLICE, payload: data };
};
const gettagsProductListPublicAction = (data) => {
  return { type: GET_TAGS_PRODUCT_LIST_PUBLICE, payload: data };
};
const getsellerProductListPublicbycategoryAction = (data) => {
  return { type: GET_SELLER_PRODUCT_LIST_PUBLICE_BY_CATEGORY, payload: data };
};
const userSearchProductAction = (data) => {
  return { type: USER_PRODUCT_SERACH, payload: data };
};
const getsubsusbcateListAction = (data) => {
  return { type: GET_SUB_SUB_CATE_LIST, payload: data };
};
const otpBoxAction = (data) => {
  return { type: OTP_BOX, payload: data };
};
// const otpBoxForgotAction = (data) => {
//   return { type: OTP_BOX_FORGOT, payload: data };
// };
const getBussnissProfileAction = (data) => {
  return { type: GET_BUSS_PRO, payload: data };
};
const getBussnissProfile1Action = (data) => {
  return { type: GET_BUSS_PRO_1, payload: data };
};
const gettopthreeAction = (data) => {
  return { type: TOP_THREE, payload: data };
};

const getleaderBoardCtMqAction = (data) => {
  return { type: LEADER_BOARD_C_M, payload: data };
};

const getleadAchiverAction = (data) => {
  return { type: LEAD_ACHIVER, payload: data };
};
const getpackageListAction = (data) => {
  return { type: GET_PACKAGE_LIST, payload: data };
};
const getticketListAction = (data) => {
  return { type: GET_SELLER_TICKET_LIST, payload: data };
};
const gettansactionListAction = (data) => {
  return { type: GET_SELLER_TANSACTION, payload: data };
};
const getprofileAction = (data) => {
  return { type: GET_PROFILE, payload: data };
};
const getmessageListAction = (data) => {
  return { type: GET_MESSAGE_LISTS, payload: data };
};
const getinvoiceAction = (data) => {
  return { type: GET_INVOICE, payload: data };
};
const getproductDetailsAction = (data) => {
  return { type: GET_PRODUCT_DETAILS, payload: data };
};
const getSellerDetailsAction = (data) => {
  return { type: GET_SELLER_PROFILE, payload: data };
};
const getPagesAction = (data) => {
  return { type: GET_PAGES, payload: data };
};
const getemailQuAction = (data) => {
  return { type: GET_EMAIL_QU, payload: data };
};
const getWhatsappQuAction = (data) => {
  return { type: GET_WHATSAPP_QU, payload: data };
};
const getpageDetailsAction = (data) => {
  return { type: PAGE_DETAILS, payload: data };
};
const getsearchtagssAction = (data) => {
  return { type: SEARCH_TAGS, payload: data };
};
const getsearchProductPubAction = (data) => {
  return { type: SEARCH_PRODUCT_PUB, payload: data };
};
const getfooterAllDataPubAction = (data) => {
  return { type: GET_FOOTER_DATA_ALL, payload: data };
};
const gethomeAllProAction = (data) => {
  return { type: GET_home_ALL_PRO, payload: data };
};
const topViewAllTypeAction = (data) => {
  return { type: TOP_VIEW_ALL_TYPE, payload: data };
};
const getSellerListPublicAction = (data) => {
  return { type: SELLER_LIST, payload: data };
};
const sellerDashboardStateAction = (data) => {
  return { type: DESHBOARD_STATE, payload: data };
};
const sellerpre_month_commissonAction = (data) => {
  return { type: USER_PRE_MONTH_COMMISSION, payload: data };
};
const sliderListAction = (data) => {
  return { type: SLIDER_LIST, payload: data };
};
const adsliderListAction = (data) => {
  return { type: AD_SLIDER_LIST, payload: data };
};
const marquseListAction = (data) => {
  return { type: MARQUSE_LIST, payload: data };
};
const blogListAction = (data) => {
  return { type: BLOG_LIST, payload: data };
};
const blogDetailsAction = (data) => {
  return { type: BLOG_DETAILS, payload: data };
};
const getExpireDateAction = (data) => {
  return { type: GET_EXPIRE_DATE, payload: data };
};
const homepopAction = (data) => {
  return { type: home_POPS, payload: data };
};
const faqsLsitAction = (data) => {
  return { type: FAQS_LIST, payload: data };
};
const countryLsitAction = (data) => {
  return { type: COUNTRY_LIST, payload: data };
};
const cityLsitAction = (data) => {
  return { type: CITY_LIST, payload: data };
};
const get_all_cart_itmeActione = (data) => {
  return { type: ADD_CART_ITME, payload: data };
};
const get_all_wishlist_itmeActione = (data) => {
  return { type: ADD_WISHLIST_ITME, payload: data };
};
const userprofileActione = (data) => {
  return { type: USER_PROFILE, payload: data };
};
const getAddressActione = (data) => {
  return { type: CREATE_ADDRESS, payload: data };
};
const orderDetailsActione = (data) => {
  return { type: ORDER_DETIALS, payload: data };
};
const orderlistsActione = (data) => {
  return { type: ORDER_LIST, payload: data };
};
const getsellerprofilebysellerActione = (data) => {
  return { type: SELLER_UPDATE_DETAILS, payload: data };
};
const sellerDateWiseReportActione = (data) => {
  return { type: SELLER_DATE_REPORTS, payload: data };
};
const mydirectTeamActione = (data) => {
  return { type: MY_DIRECT_TEAM, payload: data };
};
const myincomeListActione = (data) => {
  return { type: MY_INCOME_LIST, payload: data };
};
const userincomedateActione = (data) => {
  return { type: USER_INCOME_DATE, payload: data };
};
const allteamActione = (data) => {
  return { type: ALL_TEAM, payload: data };
};
const allteamActiveActione = (data) => {
  return { type: ALL_TEAM_ACTIVE, payload: data };
};
const allteamezzportActione = (data) => {
  return { type: ALL_TEAM_EXPORT, payload: data };
};
const weekdataActione = (data) => {
  return { type: WEEK_DATA, payload: data };
};
const daydataActione = (data) => {
  return { type: DAY_DATA, payload: data };
};
const teamCountActione = (data) => {
  return { type: TEAM_COUNT, payload: data };
};
const chalengeDataActione = (data) => {
  return { type: CHALLANGE_DATA, payload: data };
};
const getbrandActione = (data) => {
  return { type: GAT_ALL_BRAND, payload: data };
};
const getmylastthreemonthActione = (data) => {
  return { type: MY_LAST_THREE_MONTH, payload: data };
};
const thememanagemtActione = (data) => {
  return { type: THEMEMAN, payload: data };
};

export const thememangment = (data, history) => {
  return async (dispatch) => {
    try {
      dispatch(thememanagemtActione(data));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getbrandforpro = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getbrandforpro}`, config);

      // console.log(response?.data?.data);

      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        dispatch(getbrandActione(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
        // dispatch(chalengeDataActione(response?.data?.data));
      }

      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getChelenge = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.getChelenge}`, data, config);

      console.log(response?.data?.data);

      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        dispatch(chalengeDataActione(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
        dispatch(chalengeDataActione(response?.data?.data));
      }

      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const createChelenge = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.createChelenge}`, data, config);

      // console.log(response?.data?.data);

      if (response?.data?.success) {
        dispatch(chalengeDataActione(response?.data?.data));
        toast.success(response?.data?.message);
      } else {
        // dispatch(chalengeDataActione(response?.data?.data));
        toast.error(response?.data?.message);
      }
      // dispatch(userincomedateActione(response?.data?.data));

      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const userstatement = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.userstatement}`, data, config);

      console.log(response?.data?.data);
      dispatch(userincomedateActione(response?.data?.data));

      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const userMonthIncome = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.userMonthIncome}`, data, config);

      console.log(response?.data?.data);
      dispatch(myincomeListActione(response?.data?.data));

      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const createAddress = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const response = await api.post(`${URL.createAddress}`, data, config);
      if (response?.status == 200) {
        // dispatch(createAddressAction(response?.data?.data));
        localStorage.setItem(
          "order_Address",
          JSON.stringify(response?.data?.data?._id)
        );
        navigate("/checkout");

        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message, {
          position: "top-right",
          autoClose: 300,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          // progress: undefined,
          // theme: "light",
        });
      }
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // progress: undefined,
        // theme: "light",
      });
    }
  };
};

export const AddCartItme = (data, color, size, history) => {
  return async (dispatch) => {
    try {
      console.log(data);
      if (localStorage.getItem("access_token")) {
        toast.success("Product added", {
          position: "top-right",
          autoClose: 300,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          // progress: undefined,
          // theme: "light",
        });

        const token = await localStorage.getItem("access_token");
        const config = {
          headers: {
            Authorization: "Bearer " + token,
          },
        };
        // dispatch(loading(true));
        const response = await api.post(
          `${URL.addItemCart}`,
          { product_id: data, color: color, size: size },
          config
        );

        dispatch(get_all_cart_itmeActione(response?.data?.data));

        // dispatch(productsAction.additemcart({ productId: e, variations: 0 }));
        return () => {};
      } else {
        console.log("lists");
        const e = data;
        if (e !== undefined) {
          console.log("lists");

          const blogIds = localStorage.getItem("cart_product");
          const blogIdss = blogIds !== null ? blogIds : "[]";
          const arr = JSON.parse(blogIdss);
          const alredyAdd = arr.indexOf(e);
          var lists = arr.filter((x) => {
            return x.product_id == e;
          });
          console.log(lists);
          if (lists?.length == 0) {
            arr.push({ product_id: e, color: color, size: size, item: 1 });
            localStorage.setItem("cart_product", JSON.stringify(arr));
            toast.success("Product added", {
              position: "top-right",
              autoClose: 300,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              // progress: undefined,
              // theme: "light",
            });
            // setTimeout(() => {
            // dispatch(
            //   productsAction.getcartProduct({
            //     data: arr,

            //   })
            // );

            console.log("test");
            const responsedd = await api.post(`${URL.getcartProduct}`, {
              data: arr,
            });
            dispatch(get_all_cart_itmeActione(responsedd?.data?.data));
            console.log(responsedd?.data?.data);
            return () => {};
            // }, 1000);
          } else {
            // arr.push({ product_id: e, variations: null });
            const finalresult = arr;
            toast.error("Product already added", {
              position: "top-right",
              autoClose: 300,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              // progress: undefined,
              // theme: "light",
            });
            localStorage.setItem("cart_product", JSON.stringify(finalresult));

            return () => {};
          }
        } else {
        }
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const moveToCart = (data, history) => {
  return async (dispatch) => {
    try {
      console.log(data);
      if (localStorage.getItem("access_token")) {
        toast.success("Product added", {
          position: "top-right",
          autoClose: 300,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          // progress: undefined,
          // theme: "light",
        });

        const token = await localStorage.getItem("access_token");
        const config = {
          headers: {
            Authorization: "Bearer " + token,
          },
        };
        // dispatch(loading(true));
        const response = await api.post(`${URL.moveToCart}`, data, config);

        // dispatch(get_all_wishlist_itmeActione(response?.data?.data));

        // dispatch(productsAction.additemcart({ productId: e, variations: 0 }));
        return response?.data;
      } else {
        console.log("lists");
        const e = data;
        if (e !== undefined) {
          // console.log("lists");
          // const blogIds = localStorage.getItem("cart_product");
          // const blogIdss = blogIds !== null ? blogIds : "[]";
          // const arr = JSON.parse(blogIdss);
          // const alredyAdd = arr.indexOf(e);
          // var lists = arr.filter((x) => {
          //   return x.product_id == e;
          // });
          // console.log(lists);
          // if (lists?.length == 0) {
          //   arr.push({ product_id: e, color: color, size: size, item: 1 });
          //   localStorage.setItem("cart_product", JSON.stringify(arr));
          //   toast.success("Product added", {
          //     position: "top-right",
          //     autoClose: 300,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     // progress: undefined,
          //     // theme: "light",
          //   });
          //   // setTimeout(() => {
          //   // dispatch(
          //   //   productsAction.getcartProduct({
          //   //     data: arr,
          //   //   })
          //   // );
          //   console.log("test");
          //   const responsedd = await api.post(`${URL.getcartProduct}`, {
          //     data: arr,
          //   });
          //   dispatch(get_all_wishlist_itmeActione(responsedd?.data?.data));
          //   console.log(responsedd?.data?.data);
          //   return () => {};
          //   // }, 1000);
          // } else {
          //   // arr.push({ product_id: e, variations: null });
          //   const finalresult = arr;
          //   toast.error("Product already added", {
          //     position: "top-right",
          //     autoClose: 300,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     // progress: undefined,
          //     // theme: "light",
          //   });
          //   localStorage.setItem("cart_product", JSON.stringify(finalresult));
          //   return () => {};
          // }
        } else {
        }
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      return error?.response?.data;
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const addItemWish = (data, color, size, history) => {
  return async (dispatch) => {
    try {
      console.log(data);
      if (localStorage.getItem("access_token")) {
        toast.success("Product added", {
          position: "top-right",
          autoClose: 300,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          // progress: undefined,
          // theme: "light",
        });

        const token = await localStorage.getItem("access_token");
        const config = {
          headers: {
            Authorization: "Bearer " + token,
          },
        };
        // dispatch(loading(true));
        const response = await api.post(
          `${URL.addItemWish}`,
          { product_id: data, color: color, size: size },
          config
        );

        dispatch(get_all_wishlist_itmeActione(response?.data?.data));

        // dispatch(productsAction.additemcart({ productId: e, variations: 0 }));
        return () => {};
      } else {
        console.log("lists");
        const e = data;
        if (e !== undefined) {
          // console.log("lists");
          // const blogIds = localStorage.getItem("cart_product");
          // const blogIdss = blogIds !== null ? blogIds : "[]";
          // const arr = JSON.parse(blogIdss);
          // const alredyAdd = arr.indexOf(e);
          // var lists = arr.filter((x) => {
          //   return x.product_id == e;
          // });
          // console.log(lists);
          // if (lists?.length == 0) {
          //   arr.push({ product_id: e, color: color, size: size, item: 1 });
          //   localStorage.setItem("cart_product", JSON.stringify(arr));
          //   toast.success("Product added", {
          //     position: "top-right",
          //     autoClose: 300,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     // progress: undefined,
          //     // theme: "light",
          //   });
          //   // setTimeout(() => {
          //   // dispatch(
          //   //   productsAction.getcartProduct({
          //   //     data: arr,
          //   //   })
          //   // );
          //   console.log("test");
          //   const responsedd = await api.post(`${URL.getcartProduct}`, {
          //     data: arr,
          //   });
          //   dispatch(get_all_wishlist_itmeActione(responsedd?.data?.data));
          //   console.log(responsedd?.data?.data);
          //   return () => {};
          //   // }, 1000);
          // } else {
          //   // arr.push({ product_id: e, variations: null });
          //   const finalresult = arr;
          //   toast.error("Product already added", {
          //     position: "top-right",
          //     autoClose: 300,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     // progress: undefined,
          //     // theme: "light",
          //   });
          //   localStorage.setItem("cart_product", JSON.stringify(finalresult));
          //   return () => {};
          // }
        } else {
        }
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const addItemWishFromList = (data, color, size, history) => {
  return async (dispatch) => {
    try {
      console.log(data);
      if (localStorage.getItem("access_token")) {
        // toast.success("Product added", {
        //   position: "top-right",
        //   autoClose: 300,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   // progress: undefined,
        //   // theme: "light",
        // });

        const token = await localStorage.getItem("access_token");
        const config = {
          headers: {
            Authorization: "Bearer " + token,
          },
        };
        // dispatch(loading(true));
        const response = await api.post(
          `${URL.addItemWishFromList}`,
          { product_id: data, color: color, size: size },
          config
        );

        dispatch(get_all_wishlist_itmeActione(response?.data?.data));

        if (response?.data?.success) {
          toast?.success(response?.data?.message);
        }

        // dispatch(productsAction.additemcart({ productId: e, variations: 0 }));
        return () => {};
      } else {
        console.log("lists");

        toast?.error("Please login first");
        // const e = data;
        // if (e !== undefined) {
        //   console.log("lists");

        //   const blogIds = localStorage.getItem("cart_product");
        //   const blogIdss = blogIds !== null ? blogIds : "[]";
        //   const arr = JSON.parse(blogIdss);
        //   const alredyAdd = arr.indexOf(e);
        //   var lists = arr.filter((x) => {
        //     return x.product_id == e;
        //   });
        //   console.log(lists);
        //   if (lists?.length == 0) {
        //     arr.push({ product_id: e, color: color, size: size, item: 1 });
        //     localStorage.setItem("cart_product", JSON.stringify(arr));
        //     toast.success("Product added", {
        //       position: "top-right",
        //       autoClose: 300,
        //       hideProgressBar: false,
        //       closeOnClick: true,
        //       pauseOnHover: true,
        //       draggable: true,
        //       // progress: undefined,
        //       // theme: "light",
        //     });
        //     // setTimeout(() => {
        //     // dispatch(
        //     //   productsAction.getcartProduct({
        //     //     data: arr,

        //     //   })
        //     // );

        //     console.log("test");
        //     const responsedd = await api.post(`${URL.getcartProduct}`, {
        //       data: arr,
        //     });
        //     dispatch(get_all_wishlist_itmeActione(responsedd?.data?.data));
        //     console.log(responsedd?.data?.data);
        //     return () => {};
        //     // }, 1000);
        //   } else {
        //     // arr.push({ product_id: e, variations: null });
        //     const finalresult = arr;
        //     toast.error("Product already added", {
        //       position: "top-right",
        //       autoClose: 300,
        //       hideProgressBar: false,
        //       closeOnClick: true,
        //       pauseOnHover: true,
        //       draggable: true,
        //       // progress: undefined,
        //       // theme: "light",
        //     });
        //     localStorage.setItem("cart_product", JSON.stringify(finalresult));

        //     return () => {};
        //   }
        // } else {
        // }
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const sellerDateWiseReport = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      dispatch(loadingCheckOut(true));
      const response = await api.post(
        `${URL.sellerDateWiseReport}`,
        data,
        config
      );

      dispatch(sellerDateWiseReportActione(response?.data?.data));
      toast.success(response?.data?.message);
      // console.log();
      // history.push("/");
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loadingCheckOut(false));
    } catch (error) {
      dispatch(loadingCheckOut(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const createOrder = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      dispatch(loadingCheckOut(true));

      // const response2 = await api.get(`${URL.checkstocknow}`, config);
      const response = await api.post(`${URL.createOrder}`, data, config);
      if (response?.data?.success) {
        dispatch(get_all_cart_itmeActione(response?.data?.data));
        toast.success(response?.data?.message);
        // console.log();
        history("/");
      } else {
        toast.error(response?.data?.message, {
          autoClose: false, // Don't automatically close the toast
        });
        dispatch(loadingCheckOut(false));
        history("/shop-cart");
        // alert(response2?.data?.message)
      }
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loadingCheckOut(false));
    } catch (error) {
      dispatch(loadingCheckOut(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const sellerDirectTeam = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.sellerDirectTeam}/${data}`, config);

      console.log(response?.data?.data);
      dispatch(mydirectTeamActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getuserBussnisslastthreeMonth2 = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getuserBussnisslastthreeMonth2}`,
        config
      );

      console.log(response?.data?.data);
      dispatch(getmylastthreemonthActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const sellerDirectTeamfilterTeamSale = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.sellerDirectTeamfilterTeamSale}`,
        data,
        config
      );

      console.log(response?.data?.data);
      dispatch(mydirectTeamActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const sellerDirectTeamfilter = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.sellerDirectTeamfilter}`,
        data,
        config
      );

      console.log(response?.data?.data);
      dispatch(mydirectTeamActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const userallteamlistfilter = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      dispatch(allteamActione({}));
      const response = await api.post(
        `${URL.userallteamlistfilter}`,
        data,
        config
      );

      // console.log(response?.data?.data);
      dispatch(allteamActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const userallteamlistexportex = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      // dispatch(allteamActione({}));
      const response = await api.post(
        `${URL.userallteamlistexportex}`,
        data,
        config
      );

      // console.log(response?.data?.data);
      // dispatch(allteamActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      return error?.response?.data;
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const userallteamlistThisMonth = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      dispatch(allteamActione({}));
      const response = await api.post(
        `${URL.userallteamlistThisMonth}`,
        data,
        config
      );

      // console.log(response?.data?.data);
      dispatch(allteamActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const userallteamlistThisMonthPurchase = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      dispatch(allteamActione({}));
      const response = await api.post(
        `${URL.userallteamlistThisMonthPurchase}`,
        data,
        config
      );

      // console.log(response?.data?.data);
      dispatch(allteamActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const userallteamlist = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      dispatch(allteamActione({}));
      const response = await api.post(`${URL.userallteamlist}`, data, config);

      // console.log(response?.data?.data);
      dispatch(allteamActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const userallteamlistActive = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      // dispatch(allteamActiveActione({}));
      const response = await api.post(
        `${URL.userallteamlistActive}`,
        data,
        config
      );

      // console.log(response?.data?.data);
      dispatch(allteamActiveActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsellerprofilebyseller = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getsellerprofilebyseller}`, config);

      console.log(response?.data?.data);
      dispatch(getsellerprofilebysellerActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const weekWiseSale = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.weekWiseSale}`, config);

      console.log(response?.data?.data);

      dispatch(weekdataActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const dayWiseSale = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.dayWiseSale}`, config);

      console.log(response?.data?.data);

      dispatch(daydataActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const myTeamCount = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.myTeamCount}`, config);

      console.log(response?.data?.data);

      dispatch(teamCountActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getUserAddress = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.getUserAddress}`, data, config);
      dispatch(getAddressActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getcartitme = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.getcartitme}`, data, config);
      dispatch(get_all_cart_itmeActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getwishListitme = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.getwishListitme}`, data, config);
      dispatch(get_all_wishlist_itmeActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const removeWishItem = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.removeWishItem}`, data, config);
      dispatch(get_all_wishlist_itmeActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const removeCartItem = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.removeCartItem}`, data, config);
      dispatch(get_all_cart_itmeActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const removeAllCartItem = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.removeAllCartItem}`, data, config);
      dispatch(get_all_cart_itmeActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const removeAllWishItem = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.removeAllWishItem}`, data, config);
      dispatch(get_all_wishlist_itmeActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const removeItemCartCount = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.removeItemCartCount}`,
        data,
        config
      );

      // if (condition) {

      // } else {

      // }

      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(get_all_cart_itmeActione(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const addItemCartCount = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.addItemCartCount}`, data, config);
      dispatch(get_all_cart_itmeActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const afterLoginAddCart = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.afterLoginAddCart}`, data, config);

      console.log(response?.data?.data);
      dispatch(get_all_cart_itmeActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const afterLoginAddWishList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.afterLoginAddWishList}`,
        data,
        config
      );

      console.log(response?.data?.data);
      dispatch(get_all_wishlist_itmeActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getcartProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.getcartProduct}`, data, config);
      dispatch(get_all_cart_itmeActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const addItemCart = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.addItemCart}`, data, config);
      dispatch(get_all_cart_itmeActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getOneUserPerCity = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.getOneUserPerCity}`, data, config);
      dispatch(cityLsitAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getnotificationPubList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getnotificationPubList}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // dispatch(countryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const getOneUserPerCountry = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getOneUserPerCountry}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(countryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getlistfaqs = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const lange = await localStorage.getItem("lang");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getlistfaqs}?language=${lange}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(faqsLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const gethomePop = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.gethomePop}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(homepopAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getBlogListPub = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getBlogListPub}/${data}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(blogListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getExpireDate = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getExpireDate}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getExpireDateAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const sellerDashboardState = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      dispatch(loading(true));
      const response = await api.get(`${URL.sellerDashboardState}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      // dispatch(loading(false));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(sellerDashboardStateAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getSellerListPublic = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getSellerListPublic}`,
        data,
        config
      );
      dispatch(getSellerListPublicAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const createContactqueries = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.createContactqueries}`,
        data,
        config
      );
      // dispatch(topViewAllTypeAction(response?.data?.data));
      toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const topViewnewSeller = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.topViewnewSeller}`, data, config);
      dispatch(topViewAllTypeAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getMarqusesList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getMarqusesList}?forslider=${data?.forslider}`,
        config
      );

      dispatch(marquseListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getAdsliderList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getAdsliderList}?forslider=${data?.forslider}`,
        config
      );

      dispatch(adsliderListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsliderList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getsliderList}?forslider=${data?.forslider}`,
        config
      );

      dispatch(sliderListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getBlogDetails = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.getBlogDetails}`, data, config);
      dispatch(blogDetailsAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const gethomePro = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      dispatch(loading(true));
      const response = await api.post(`${URL.gethomePro}`, data, config);
      dispatch(gethomeAllProAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const footerPagepageListetPu = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.footerPagepageListetPu}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getfooterAllDataPubAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const passwordChange = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.passwordChange}`, data, config);

      if (response?.data?.success) {
        toast.success(response?.data?.message);
        // dispatch(otpBoxAction(true));

        // dispatch(getsearchProductPubAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const sendOtpSeller = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.sendOtpSeller}`, data, config);
      console.log(response);
      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // dispatch(otpBoxAction(true));
        // dispatch(getsearchProductPubAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      // console.log(response?.data);
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const searchProductpub = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.searchProductpub}`, data, config);

      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getsearchProductPubAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const createSellerProducsdft = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.createSellerProduct}`,
        data,
        config
      );

      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const searchtags = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.searchtags}`, data, config);
      dispatch(getsearchtagssAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const pageDetails = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.pageDetails}/${data}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getpageDetailsAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getPages = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getPages}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getPagesAction(response?.data?.data));
      } else {
        dispatch(getPagesAction(response?.data?.data));
        // toast.error(response?.data?.message);
      }
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getEmailenquirySeller = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getEmailenquirySeller}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getemailQuAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsellerWhatsappinquire = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getsellerWhatsappinquire}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getWhatsappQuAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getThirdLavelCategory = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getThirdLavelCategory}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getsubsusbcategoriesActionNew(response?.data?.data));
      } else {
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsubsubCategoryList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getsubsubCategoryList}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getsubsusbcategoriesfillAction(response?.data?.data));
      } else {
        dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsubsubCategoryListfillforsub = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getsubsubCategoryListfillforsub}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getsubsusbcategoriesfillAction(response?.data?.data));
      } else {
        dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsubsubCategoryListfill2 = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getsubsubCategoryListfill2}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getsubsusbcategoriesfillAction(response?.data?.data));
      } else {
        dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const productDetails = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      dispatch(loading1(true));
      const response = await api.get(`${URL.productDetails}/${data}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        setTimeout(() => {
          dispatch(loading1(false));
        }, 300);

        dispatch(getproductDetailsAction(response?.data?.data));
        return response;
      } else {
        dispatch(getsellerProductListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
    } catch (error) {
      dispatch(loading1(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const emailenquirysubmit = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.emailenquirysubmit}`,
        data,
        config
      );
      // dispatch(createcategoriesAction(response?.data?.data));
      toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const whatsappinquiresubmit = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.whatsappinquiresubmit}`,
        data,
        config
      );
      // dispatch(createcategoriesAction(response?.data?.data));
      toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const createcategories = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.createcategories}`, data, config);
      dispatch(createcategoriesAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const productclick = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.productclick}`, data, config);
      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        // toast.error(response?.data?.message);
      }
      // dispatch(getSellerDetailsAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";

      toast.success();
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const createSubscription = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.createSubscription}`,
        data,
        config
      );
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }
      // dispatch(getSellerDetailsAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";

      toast.success();
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsellerprofile = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.getsellerprofile}`, data, config);
      dispatch(getSellerDetailsAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const gettagsProductListPublice = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.gettagsProductListPublice}`,
        data,
        config
      );
      dispatch(gettagsProductListPublicAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsellerProductListPublice = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getsellerProductListPublice}`,
        data,
        config
      );
      dispatch(getsellerProductListPublicAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsellerProductListPublicefilCate = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      dispatch(loading(true));
      const response = await api.post(
        `${URL.getsellerProductListPublicefilCate}`,
        data,
        config
      );
      dispatch(getsellerProductListPublicAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getsellerProductListPublicebymaincategory2BestSelling = (
  data,
  history
) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      dispatch(loading(true));
      getsellerProductListPublicbycategoryAction({});
      const response = await api.post(
        `${URL.getsellerProductListPublicebymaincategory2BestSelling}`,
        data,
        config
      );
      dispatch(
        getsellerProductListPublicbycategoryAction(response?.data?.data)
      );
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsellerProductListPublicebymaincategory2newLaunch = (
  data,
  history
) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      dispatch(loading(true));
      getsellerProductListPublicbycategoryAction({});
      const response = await api.post(
        `${URL.getsellerProductListPublicebymaincategory2newLaunch}`,
        data,
        config
      );
      dispatch(
        getsellerProductListPublicbycategoryAction(response?.data?.data)
      );
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsellerProductgpgProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      dispatch(loading(true));
      getsellerProductListPublicbycategoryAction({});
      if (token) {
        const userToken = await localStorage.getItem("access_user");
        var user = JSON.parse(userToken);
        if (user?.status > 2) {
          const response = await api.post(
            `${URL.getsellerProductgpgProduct}`,
            data,
            config
          );
          dispatch(
            getsellerProductListPublicbycategoryAction(response?.data?.data)
          );
        }
      }

      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsellerProductListPublicebymaincategory2Subcate = (
  data,
  history
) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      dispatch(loading(true));
      getsellerProductListPublicbycategoryAction({});
      const response = await api.post(
        `${URL.getsellerProductListPublicebymaincategory2Subcate}`,
        data,
        config
      );
      dispatch(
        getsellerProductListPublicbycategoryAction(response?.data?.data)
      );
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const getsellerProductListPublicebymaincategory2 = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      dispatch(loading(true));
      getsellerProductListPublicbycategoryAction({});
      const response = await api.post(
        `${URL.getsellerProductListPublicebymaincategory2}`,
        data,
        config
      );
      dispatch(
        getsellerProductListPublicbycategoryAction(response?.data?.data)
      );
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      return error?.response?.data;
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsellerProductListPublicebycategory = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      dispatch(loading(true));
      getsellerProductListPublicbycategoryAction({});
      const response = await api.post(
        `${URL.getsellerProductListPublicebycategory}`,
        data,
        config
      );
      dispatch(
        getsellerProductListPublicbycategoryAction(response?.data?.data)
      );
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));

      return response;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const userSearchProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      dispatch(loading(true));
      const response = await api.post(`${URL.userSearchProduct}`, data, config);
      dispatch(userSearchProductAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));

      // return response;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const createSellerProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.createSellerProduct}`,
        data,
        config
      );

      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const copyProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.copyProduct}`, data, config);

      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updatesellerProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.updatesellerProduct}`,
        data,
        config
      );

      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const searchSellerProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.searchSellerProduct}`,
        data,
        config
      );

      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }
      // dispatch(getsellerProductListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const deleteSellerProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.deleteSellerProduct}`,
        data,
        config
      );
      dispatch(getsellerProductListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsellerProductList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getsellerProductList}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        dispatch(getsellerProductListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getCategory = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getCategory}`, data, config);

      dispatch(getcategoriesAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsubsubcateList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getsubsubcate}`, data, config);

      dispatch(getsubsusbcateListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsubsubcatehome = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getsubsubcatehome}`, data, config);

      dispatch(getsubsusbcategoriesAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const signinSeller = (data, navigate) => {
  return async (dispatch) => {
    try {
      const response = await api.post(`${URL.signinSeller}`, data);
      if (response?.data?.success) {
        // userProfileActione

        // dispatch(userProfileActione(response?.data?.data));

        // dispatch(userprofileActione(response?.data?.data));
        dispatch(userprofileActione(response?.data?.data));

        toast.success(response?.data?.message);
        Token.setToken(response?.data?.api_token);

        const lsValue = JSON.stringify(response?.data?.data);
        console.log(lsValue);
        Token.setUserDetail(lsValue);
        // navigate.push("/home");

        window.location.href = "/";
        // setTimeout(, 3000);
        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      dispatch(loading(false));

      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };
};
export const signinSellereSecurite = (data, navigate) => {
  return async (dispatch) => {
    try {
      const response = await api.post(`${URL.signinSellereSecurite}`, data);
      if (response?.status == 200) {
        // toast.success(response?.data?.message);

        Token.setToken(response?.data?.api_token);
        const lsValue = JSON.stringify(response?.data?.data);
        Token.setUserDetail(lsValue);
        // navigate("/home"),
        window.location.href = "/home";
        // setTimeout(navigate("/home"), 1000);
        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const signupSeller = (data, navigate) => {
  return async (dispatch) => {
    try {
      const response = await api.post(`${URL.signupSeller}`, data);
      if (response?.status == 201) {
        // toast.success(response?.data?.message);

        if (response?.data?.success == true) {
          dispatch(otpBoxAction(true));
          toast.success(response?.data?.message);
        } else {
          toast.error(response?.data?.message);
        }
        // otpBoxAction
        // Token.setToken(response?.data?.api_token);
        // setTimeout(navigate("/home"), 1000);
        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const otpVerify = (data, navigate) => {
  return async (dispatch) => {
    try {
      const response = await api.post(`${URL.otpVerify}`, data);
      if (response?.status == 200) {
        // toast.success(response?.data?.message);

        if (response?.data?.success == true) {
          dispatch(otpBoxAction(false));
          toast.success(response?.data?.message);
        } else {
          toast.error(response?.data?.message);
        }
        // otpBoxAction
        // Token.setToken(response?.data?.api_token);
        navigate.push("/login");
        // setTimeout(navigate.push("/login"), 1000);
        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const otpVerifyseller = (data, navigate) => {
  return async (dispatch) => {
    try {
      const response = await api.post(`${URL.otpVerifyseller}`, data);
      if (response?.status == 200) {
        // toast.success(response?.data?.message);

        if (response?.data?.success == true) {
          dispatch(otpBoxAction(false));
          toast.success(response?.data?.message);
        } else {
          toast.error(response?.data?.message);
        }
        // otpBoxAction
        // Token.setToken(response?.data?.api_token);
        // setTimeout(navigate.push("/login"), 1000);
        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }

      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};

export const createBussnissPro = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(`${URL.createBussnissPro}`, data, config);
      dispatch(getBussnissProfileAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const updateBussLogo = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(`${URL.updateBussLogo}`, data, config);
      dispatch(getBussnissProfileAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const updateBussProfile = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(`${URL.updateBussProfile}`, data, config);
      dispatch(getBussnissProfileAction(response?.data?.data));

      if (response?.data?.success) {
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updateWhatsaapNumber = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(
        `${URL.updateWhatsaapNumber}`,
        data,
        config
      );
      // dispatch(getBussnissProfileAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const verifyWhatsapp = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(`${URL.verifyWhatsapp}`, data, config);
      dispatch(getBussnissProfileAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getBussProfile = (data, history) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.get(`${URL.getBussProfile}`, config);
      dispatch(getBussnissProfileAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const edituserProfileByuser = (data, history) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(
        `${URL.edituserProfileByuser}`,
        data,
        config
      );
      toast.success(response?.data?.message);
      const response2 = await api.get(`${URL.getBussProfile1}`, config);
      dispatch(getBussnissProfile1Action(response2?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const edituserProfileimageByUser = (data, history) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(
        `${URL.edituserProfileimageByUser}`,
        data,
        config
      );
      toast.success(response?.data?.message);
      const response2 = await api.get(`${URL.getBussProfile1}`, config);

      dispatch(getBussnissProfile1Action(response2?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const edituserKycByUser = (data, history) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(`${URL.edituserKycByUser}`, data, config);
      toast.success(response?.data?.message);
      const response2 = await api.get(`${URL.getBussProfile1}`, config);

      dispatch(getBussnissProfile1Action(response2?.data?.data));

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const LeaderBoard = (data, history) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.get(`${URL.LeaderBoard}`, config);
      dispatch(gettopthreeAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const LeaderBoardbyAdmin = (data, history) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.get(`${URL.LeaderBoardbyAdmin}`, config);
      dispatch(getleaderBoardCtMqAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const LedAchiversList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.get(`${URL.LedAchiversList}`, config);
      dispatch(getleadAchiverAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getBussProfile1 = (data, history) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.get(`${URL.getBussProfile1}`, config);
      dispatch(getBussnissProfile1Action(response?.data?.data));
      dispatch(userprofileActione(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getPackageList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getPackageList}/${data}`, config);
      dispatch(getpackageListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const addlicenceUser = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(`${URL.addlicenceUser}`, data, config);
      // dispatch(getBussnissProfileAction(response?.data?.data));

      if (response?.data?.success) {
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));

      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const createPurchase = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(`${URL.createPurchase}`, data, config);
      // dispatch(getBussnissProfileAction(response?.data?.data));

      if (response?.data?.success) {
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getsellerTransaction = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getsellerTransaction}/${data}`,
        config
      );
      dispatch(gettansactionListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getuserdownselllistNew = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getuserdownselllistNew}/${data}`,
        config
      );
      dispatch(orderlistsActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getuserdownselllist = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getuserdownselllist}/${data}`,
        config
      );
      dispatch(orderlistsActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getuserOrder = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getuserOrder}/${data}`, config);
      dispatch(orderlistsActione(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getprofile = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getprofile}`, config);
      dispatch(getprofileAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const userCommissionpreviousmonth = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.userCommissionpreviousmonth}`,
        config
      );
      dispatch(sellerpre_month_commissonAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getuserBussnissMonth = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getuserBussnissMonth}`, config);
      dispatch(sellerDashboardStateAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getuserdeshboardData = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getuserdeshboardData}`, config);
      dispatch(sellerDashboardStateAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getInvoice1 = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getInvoice1}`, config);
      dispatch(getinvoiceAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getInvoice = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getInvoice}`, config);
      dispatch(getinvoiceAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

// createSellerTickets

export const createSellerTickets = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.createSellerTickets}`,
        data,
        config
      );

      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getticketListAction(response?.data?.data));
        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getuserOrderDetailsPub = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getuserOrderDetailsPub}`,
        data,
        config
      );

      if (response?.data?.success) {
        console.log(response?.data?.data);
        // toast.success(response?.data?.message);

        dispatch(orderDetailsActione(response?.data?.data));
        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const getuserOrderDetails = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getuserOrderDetails}`,
        data,
        config
      );

      if (response?.data?.success) {
        console.log(response?.data?.data);
        // toast.success(response?.data?.message);

        dispatch(orderDetailsActione(response?.data?.data));
        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getSellerTicketList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getSellerTicketList}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getticketListAction(response?.data?.data));
      } else {
        dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getmessageSupportList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getmessageSupportList}`,
        data,
        config
      );

      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getmessageListAction(response?.data?.data));
        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const createMessage = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.createMessage}`, data, config);

      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getmessageListAction(response?.data?.data));
        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const deleteacountByseller = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.deleteacountByseller}`,
        data,
        config
      );

      if (response?.data?.success) {
        localStorage.clear();
        navigate.push("/");
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

import React from "react";
import Sidebar from "../Layout/Sidebar";
import HeaderAccount from "../Layout/HeaderAccount";

function Promotion() {
  return (
    <div>
      {" "}
      <Sidebar />
      <main className="main-wrap">
        <HeaderAccount />
        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Promotion</h2>
              {/* <p>Lorem ipsum dolor sit amet.</p> */}
            </div>
            <div></div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <img src="/firstwin.jpeg" alt="promotion" style={{ width: "100%" }} />
            </div>
            <div className="col-md-6">
              <img src="/secondwin.jpeg" alt="promotion" style={{ width: "100%" }}/>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Promotion;

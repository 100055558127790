import React, { useEffect, useState } from "react";
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import AllTeam from "../Component/Account/AllTeam";
import ChallangeMarquis from "../Component/Account/ChallangeMarquis";
import Dashboard from "../Component/Account/Dashboard";
import Genelogy from "../Component/Account/Genelogy";
import Iidcard from "../Component/Account/Iidcard";
import MonthReport from "../Component/Account/MonthReport";
import MyDirectTeam from "../Component/Account/MyDirectTeam";
import MyIncome from "../Component/Account/MyIncome";
import MyStatment from "../Component/Account/MyStatment";
import Order from "../Component/Account/Order";
import Profile from "../Component/Account/Profile";
import RankReport from "../Component/Account/RankReport";
import ScureLogin from "../Component/Account/ScureLogin";
import Ticket from "../Component/Account/Ticket";
import TodayBussniss from "../Component/Account/TodayBussniss";
import Wallet from "../Component/Account/Wallet";
import HomeNewCom from "../Component/HomeComponent/HomeNewCom";
import About from "../Component/WebsiteComponent/About";
import Blogs from "../Component/WebsiteComponent/Blogs";
import BlogsDetails from "../Component/WebsiteComponent/BlogsDetails";
import Brand from "../Component/WebsiteComponent/Brand";
import Checkout from "../Component/WebsiteComponent/Checkout";
import CheckoutAdress from "../Component/WebsiteComponent/CheckoutAdress";
import CompleteKyc from "../Component/WebsiteComponent/CompleteKyc";
import Compliance from "../Component/WebsiteComponent/Compliance";
import Contact from "../Component/WebsiteComponent/Contact";
import Faqs from "../Component/WebsiteComponent/Faqs";
import ForgetPassword from "../Component/WebsiteComponent/ForgetPassword";
// import ForgotPassword from "../Component/WebsiteComponent/ForgetPassword2";
import LastChanse from "../Component/WebsiteComponent/LastChanse";
import LastChanse2 from "../Component/WebsiteComponent/LastChanse2";
import Login from "../Component/WebsiteComponent/Login";
import MainCategory from "../Component/WebsiteComponent/MainCategory";
import MainCategory2 from "../Component/WebsiteComponent/MainCategory2";
// import NewLouch from "../Component/WebsiteComponent/NewLouch";
import NewLounch2 from "../Component/WebsiteComponent/NewLounch2";
import PrivecyPolicy from "../Component/WebsiteComponent/PrivecyPolicy";
import Products from "../Component/WebsiteComponent/Product";
import ProductDetails from "../Component/WebsiteComponent/ProductDetails";
import Register from "../Component/WebsiteComponent/Register";
import Shopcart from "../Component/WebsiteComponent/Shopcart";
import SubCategory from "../Component/WebsiteComponent/SubCategory";
import SubSubCategory from "../Component/WebsiteComponent/SubSubCategory";
import SubSubCategory2 from "../Component/WebsiteComponent/SubSubCategory2";
import Transparency from "../Component/WebsiteComponent/Transparency";

import AuthAdmin from "./AuthAdmin";
import OrderBussniss from "../Component/Account/OrderBussniss";
import Home2 from "../Component/HomeComponent/Home2";
import ProcessPayment from "../Component/HomeComponent/ProcessPayment";
import FaildPayment from "../Component/HomeComponent/FaildPayment";
import SuccessPayment from "../Component/HomeComponent/SuccessPayment";
import GpgProduct from "../Component/WebsiteComponent/GpgProduct";
import ProductDetails2 from "../Component/WebsiteComponent/ProductDetails2";
import ShopCart2 from "../Component/WebsiteComponent/ShopCart2";
import Account from "../Component/Account/Account";
import DashBoard2 from "../Component/Account/DashBoard2";
import Profile2 from "../Component/Account/Profile2";
import Categories from "../Component/WebsiteComponent/Categories";
import SubCategory2 from "../Component/WebsiteComponent/SubCategory2";
import Notification from "../Component/WebsiteComponent/Notification";
import Order2 from "../Component/Account/Order2";
import Wallet2 from "../Component/Account/Wallet2";
import WishList2 from "../Component/WebsiteComponent/WishList2";
import AllTeamApp from "../Component/Account/AllTeamApp";
import SignUplist from "../Component/Account/SignUplist";
import PurchasTeam from "../Component/Account/PurchasTeam";
import Promotion from "../Component/Account/Promotion";
import OrderBussnissApp from "../Component/Account/OrderBussnissApp";
import OrderBussnissAppRecord from "../Component/Account/OrderBussnissAppRecord";
import RankMyJurny from "../Component/Account/RankMyJurny";
import MyIncome2 from "../Component/Account/MyIncome2";
import Invoice from "../Component/Account/Invoice";
import OfferProduct2 from "../Component/WebsiteComponent/OfferProduct2";
import BestSellingProduct2 from "../Component/WebsiteComponent/BestSellingProduct2";
import BusinessTab from "../Component/Account/BusinessTab";
import TeamBoardTab from "../Component/Account/TeamBoardTab";

function AppRoutes() {
  const [first, setfirst] = useState("");
  useEffect(() => {
    // localStorage.setItem("access_token_vendor", access_token);
    const token = localStorage.getItem("theme");

    return () => {};
  }, []);

  function isWebView() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Example for Android WebView (check for "wv")
    if (/wv/.test(userAgent) || /Android.*WebView/.test(userAgent)) {
      return true;
    }

    // Example for iOS WebView (check for "iPhone OS" without "Safari")
    if (/iPhone|iPod|iPad/.test(userAgent) && !/Safari/.test(userAgent)) {
      return true;
    }

    return false; // Assume it's a browser if no WebView signature is detected
  }

  return (
    // < Switch >
    //   < Route exact path="/" component={UserTable} />
    //   < Route exact path="/login" component={Login} />
    // </Switch>

    // <BrowserRouter>
    <Routes>
      <Route path="/" element={<HomeNewCom />} />
      {isWebView() && <Route path="/proccess" element={<ProcessPayment />} />}
      {isWebView() && <Route path="/failed" element={<FaildPayment />} />}
      {isWebView() && <Route path="/success" element={<SuccessPayment />} />}
      {/* <Route path="/main" element={<Home2 />} /> */}
      <Route path="/login" element={<Login />} />
      <Route path="/about" element={<About />} />
      <Route path="/privacy-policy" element={<PrivecyPolicy />} />
      <Route path="/transparency" element={<Transparency />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/faqs" element={<Faqs />} />

      <Route path="/compliance" element={<Compliance />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgot-password" element={<ForgetPassword />} />
      <Route path="/brand" element={<Brand />} />
      <Route path="/blogs" element={<Blogs />} />
      <Route path="/products" element={<Products />} />
      {/* <Route path="/new-launch" element={<NewLouch />} /> */}
      <Route path="/new-launch" element={<NewLounch2 />} />
      {/* <Route path="/last-chance" element={<LastChanse />} /> */}
      <Route path="/last-chance" element={<LastChanse2 />} />
      <Route path="/offer-product" element={<OfferProduct2 />} />
      <Route path="/best-selling-product" element={<BestSellingProduct2 />} />
      <Route path="/gpg-product" element={<GpgProduct />} />
      {/* <Route path="/shop-cart" element={<Shopcart />} /> */}
      <Route path="/shop-cart" element={<ShopCart2 />} />
      <Route path="/wish-list" element={<WishList2 />} />

      <Route path="/blog-details/:id" element={<BlogsDetails />} />
      {/* <Route path="/category-products/:id" element={<MainCategory />} /> */}
      <Route path="/categories" element={<Categories />} />
      <Route path="/notification" element={<Notification />} />
      <Route path="/category-products/:id" element={<MainCategory2 />} />
      {/* <Route path="/sub-category-product/:id" element={<SubCategory />} /> */}
      <Route path="/sub-category-product/:id" element={<SubCategory2 />} />
      {/* <Route path="/category-product/:id" element={<SubSubCategory />} /> */}
      <Route path="/category-product/:id" element={<SubSubCategory2 />} />
      {/* <Route path="/product-details/:id" element={<ProductDetails />} /> */}
      <Route path="/product-details/:id" element={<ProductDetails2 />} />
      <Route path="/63bbf79290327404282cf563rd3/:id" element={<ScureLogin />} />

      {/* auth */}
      <Route path="/checkout-address" element={<CheckoutAdress />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/complete-kyc" element={<CompleteKyc />} />
      <Route path="/invoice/:id" element={<Invoice />} />
      {/* <div className={first == "drak" ? "thememan":""}> */}
      <Route path="/account" element={<Account />} />
      <Route path="/home" element={<DashBoard2 />} />
      <Route path="/home/profile" element={<Profile2 />} />
      <Route path="/home/orders" element={<Order2 />} />
      <Route path="/home/wallet" element={<Wallet2 />} />
      <Route path="/home/genelogy" element={<Genelogy />} />
      <Route path="/home/idcard" element={<Iidcard />} />
      <Route path="/home/my-direct-team" element={<MyDirectTeam />} />
      <Route path="/home/all-team" element={<AllTeam />} />
      {/* <Route path="/home/team-board" element={<AllTeamApp />} /> */}
      <Route path="/home/team-board" element={<TeamBoardTab />} />
      <Route path="/home/sign-up-team" element={<SignUplist />} />
      <Route path="/home/purchase-team" element={<PurchasTeam />} />
      <Route path="/home/my-statement" element={<MyStatment />} />
      <Route path="/home/rank-report" element={<RankReport />} />
      <Route path="/home/my-journey" element={<RankMyJurny />} />
      <Route path="/home/my-income" element={<MyIncome />} />
      <Route path="/home/income" element={<MyIncome2 />} />
      <Route path="/home/monthly-report" element={<MonthReport />} />
      <Route path="/home/ticket" element={<Ticket />} />

      <Route path="/home/promotion" element={<Promotion />} />
      <Route path="/home/today-business" element={<TodayBussniss />} />
      <Route path="/home/challange-marquis" element={<ChallangeMarquis />} />
      <Route path="/home/orderbusiness" element={<OrderBussniss />} />
      {/* <Route path="/home/orderbusinessapp" element={<OrderBussnissApp />} /> */}
      <Route path="/home/orderbusinessapp" element={<BusinessTab />} />
      <Route
        path="/home/business-record"
        element={<OrderBussnissAppRecord />}
      />
      {/* </div> */}

      {/* <Route path="/login" element={<Login />} /> */}
      {/* <Route path="/register" element={<Register />} />
      <Route path="/verify" element={<Verify />} />
      <Route path="/forget-password" element={<ForgetPassword />} />

      <Route path="/" exact element={<AuthAdmin cmp={Home} />} />
      <Route path="/game-play" element={<AuthAdmin cmp={GamePlay} />} />
      <Route path="/my-wallet" element={<AuthAdmin cmp={MyWallet} />} />
      <Route path="/game-rate" element={<AuthAdmin cmp={GameRate} />} />
      <Route path="/contact-us" element={<AuthAdmin cmp={ContactUs} />} />
      <Route path="/add-point" element={<AuthAdmin cmp={AddPoint} />} />
      <Route path="/withdrawal" element={<AuthAdmin cmp={Withdrawal} />} />
      <Route path="/single-game" element={<AuthAdmin cmp={SingleGame} />} />
      <Route path="/jodi" element={<AuthAdmin cmp={Jodi} />} />
      <Route path="/single-pana" element={<AuthAdmin cmp={SinglePana} />} />
      <Route path="/double-pana" element={<AuthAdmin cmp={DoublePana} />} />
      <Route path="/tripal-pana" element={<AuthAdmin cmp={TripalPana} />} />
      <Route path="/half-sigma" element={<AuthAdmin cmp={HalfSigma} />} />
      <Route path="/full-sigma" element={<AuthAdmin cmp={FullSigma} />} />
      <Route path="/bid-history" element={<AuthAdmin cmp={BidHistory} />} />
      <Route path="/win-history" element={<AuthAdmin cmp={WinHistory} />} />
      <Route path="/proccess" element={<AuthAdmin cmp={Proccess} />} />
      <Route path="/success" element={<AuthAdmin cmp={Success} />} />
      <Route path="/failed" element={<AuthAdmin cmp={Failed} />} />
      <Route path="/profile" element={<AuthAdmin cmp={Profile} />} />
      <Route path="/chart" element={<AuthAdmin cmp={ChartList} />} />
      <Route path="/phonePe" element={<AuthAdmin cmp={PhonePe} />} />
      <Route path="/Paytem" element={<AuthAdmin cmp={Paytem} />} />
      <Route path="/googlePay" element={<AuthAdmin cmp={GooglePay} />} />
     
      <Route
        path="/change-password"
        element={<AuthAdmin cmp={ChangePassword} />}
      /> */}
      {/* <Route
        path="/change-password"
        element={<AuthAdmin cmp={ChangePassword} />}
      /> */}
      {/*  <Route path="/order-details/:id" element={<AuthAdmin cmp={Tracking} />} />
      <Route path="/wishlist" element={<Wishlist />} />
      <Route path="/shop-categories" element={<ShopCategories />} />
      <Route path="/shop-cart" element={<ShopCart />} />
      <Route path="/product-details/:id" element={<ProductDetails />} />

      <Route path="/contact-us" element={<ContactUs />} />
      <Route
        path="/choose-shipping-method"
        element={<ChooseShippingMethod />}
      />
      <Route path="/review-your-order" element={<ReviewYourOrder />} />
      <Route path="/choose-payment-method" element={<ChoosePaymentMethod />} />
      <Route path="/buy-now" element={<BuyNowPro />} />
      <Route path="/checkout-complate" element={<CheckoutComplete />} />
      <Route path="/response" element={<Responce />} />
      <Route path="/about-us" element={<About />} />
      <Route path="/products" element={<ProductRight />} />
      <Route path="/searching-product" element={<SearchingProduct />} />
      <Route path="/orders/success" element={<OrderSuccess success={true} />} />
      <Route path="/orders/failed" element={<OrderSuccess success={false} />} />
      <Route path="/order/:id" element={<OrderStatus />} />
      <Route path="/buy-product-order/:id" element={<OrderStatus2 />} />
      <Route path="/loader" element={<Loader />} />
      <Route path="/terms-and-condition" element={<TermsAndCondition />} />
      <Route
        path="/refund-and-cancellation-policy"
        element={<RefundandCancellation />}
      />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />

      <Route
        path="/checkout-details"
        element={<AuthAdmin cmp={CheckoutDetails} />}
      />
      <Route
        path="/checkout-details-address"
        element={<AuthAdmin cmp={CheckoutDetails2} />}
      /> */}

      {/* vandor */}
      {/* <Route path="/vendor/login" element={<AuthVendor cmp={Login} />} />
      <Route path="/vendor" element={<AuthVendor cmp={Vendor} />} />
      <Route
        path="/vendor/categories"
        element={<AuthVendor cmp={CategoriesList} />}
      />
      <Route path="/vendor/brand" element={<AuthVendor cmp={Brannd} />} />
     
      <Route path="/vendor/orders" element={<AuthVendor cmp={OrderList} />} />
      <Route
        path="/vendor/transation"
        element={<AuthVendor cmp={Transation} />}
      /> */}
    </Routes>
    // </BrowserRouter>
  );
}

export default AppRoutes;

import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
// import { allapiAction } from "../../../Redux/common/action";
// import { URL } from "../../../Redux/common/url";
// import Footer from "../Layout/Footer";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import moment from "moment";

import ImageUploading from "react-images-uploading";
import { toast } from "react-toastify";
// import dateFormat, { masks } from "dateformat";

import ReactToPrint from "react-to-print";
import { allapiAction } from "../../Redux/common/action";
import HeaderAccount from "../Layout/HeaderAccount";
import Sidebar from "../Layout/Sidebar";
import { URL } from "../../Redux/common/url";
import { useLocation, useNavigate } from "react-router-dom";
import OrderBussnissApp2 from "./OrderBussnissApp2";
import MyIncome2Tab from "./MyIncome2Tab";
import OrderBussnissAppRecordtab from "./OrderBussnissAppRecordtab";
// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import Header2 from "../../../component/sidebar/Header2";
// import Sidebar from "../../../component/sidebar/Sidebar";

function BusinessTab() {
  const [show, setshow] = useState("orderbusinessapp");

  return (
    <div>
      <Sidebar />

      <main className="main-wrap ">
        <HeaderAccount />

        <section className="content-main">
          <div className="AppPro">
            <div className="CategoryListapp">
              <div class="">
                <div class="d-flex justify-content-around mb-3 cateLi">
                  <button
                    onClick={() => {
                      setshow("orderbusinessapp");
                    }}
                    class={
                      show === "orderbusinessapp"
                        ? "fts tab-btn btn btn-light active"
                        : "tab-btn btn btn-light fts"
                    }
                  >
                    Team sale
                  </button>
                  <button
                    onClick={() => {
                      setshow("income");
                    }}
                    class={
                      show === "income"
                        ? "fts tab-btn btn btn-light active"
                        : "tab-btn btn btn-light fts"
                    }
                  >
                    Income
                  </button>
                  <button
                    onClick={() => {
                      setshow("business-record");
                    }}
                    class={
                      show === "business-record"
                        ? "fts tab-btn btn btn-light active"
                        : "tab-btn btn btn-light fts"
                    }
                  >
                    Business Record
                  </button>
                </div>
              </div>
            </div>
          </div>
          {show === "orderbusinessapp" && <OrderBussnissApp2 />}
          {show === "income" && <MyIncome2Tab />}
          {show === "business-record" && <OrderBussnissAppRecordtab />}
        </section>
      </main>
    </div>
  );
}

export default BusinessTab;

// import { allapiAction } from "@/Redux/common/action";
// import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
// import dynamic from "next/dynamic";

// import Sidebar from "../Layout/Sidebar";
// import { ProSidebarProvider } from "react-pro-sidebar";
// import Header from "../Layout/Header";
// import AreaGrap from "../Graph/AreaGrap";
// import Footer from "../Layout/Footer";
import Cropper from "react-easy-crop";
import { getOrientation } from "get-orientation/browser";
import ImageUploading from "react-images-uploading";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// import AreaGrap from "../../../component/Graph/AreaGrap";
// import Footerr from "../../../component/sidebar/Footerr";
// import Header2 from "../../../component/sidebar/Header2";
// import Sidebar from "../../../component/sidebar/Sidebar";
// import BounceLoader from "react-spinners/BounceLoader";
// import Api from "@/Redux/common/api";
// import moment from "moment";
// import ProgressBar from "../../../component/Home/ProgressBar";
// import Chart from "react-google-charts";
// import { URL } from "@/Redux/common/url";
// import { AsyncPaginate } from "react-select-async-paginate";
import { Button, Modal, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { allapiAction } from "../../Redux/common/action";
import Sidebar from "../Layout/Sidebar";
import HeaderAccount from "../Layout/HeaderAccount";
import { URL } from "../../Redux/common/url";
import imageCompression from "browser-image-compression";
// import ImgDialog from './ImgDialog'
import { getCroppedImg, getRotatedImage } from "./canvasUtils";
import Iidcard from "./Iidcard";
import Iidcard2 from "./Iidcard2";
// import { allapiAction } from "../../../Redux/common/action";

// AreaGrap

// import Cktest from "../../../component/cktetst/Cktest";
// const AreaGrap = dynamic(() => import("../../../component/Graph/AreaGrap"), {
//   ssr: false,
// });
const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
};
function ProfileApp() {
  const dispatch = useDispatch();

  const deshboard = useSelector((state) =>
    state?.allapi?.deshboard_state ? state?.allapi?.deshboard_state : {}
  );
  const team_count = useSelector((state) =>
    state?.allapi?.team_count ? state?.allapi?.team_count : {}
  );
  const week_data = useSelector((state) =>
    state?.allapi?.week_data ? state?.allapi?.week_data : []
  );
  const day_data = useSelector((state) =>
    state?.allapi?.day_data ? state?.allapi?.day_data : []
  );
  // const loader = useSelector((state) =>
  //   state?.allapi?.loader ? state?.allapi?.loader : false
  // );

  const seller_update_details = useSelector((state) =>
    state?.allapi?.seller_update_details
      ? state?.allapi?.seller_update_details
      : {}
  );

  console.log(seller_update_details);

  console.log(deshboard);

  const [loader, setfirst] = useState(true);

  const [lisshowcount, setlisscount] = useState("");
  const [managerKey, setManagerKey] = useState(0);
  // const [userselete, setuserselete] = useState(null);

  //   const route = useRouter();

  useEffect(() => {
    // if (!localStorage.getItem("access_token")) {

    //   route.push("/login");

    // }
    setTimeout(() => {
      setfirst(false);
    }, 1000);
  }, []);

  useEffect(() => {
    // sellerDashboardState
    // dispatch(allapiAction.weekWiseSale({}));
    // dispatch(allapiAction.dayWiseSale({}));
    // dispatch(allapiAction.myTeamCount({}));

    // dispatch(allapiAction.getsellerprofilebyseller({}));
    return () => {};
  }, []);

  const profile = useSelector((state) =>
    state?.allapi?.get_profile ? state?.allapi?.get_profile : {}
  );
  console.log(profile);

  useEffect(() => {
    // dispatch(allapiAction.getuserBussnissMonth({}));
    // dispatch(allapiAction.getuserdeshboardData({}));
    dispatch(allapiAction.getprofile({}));
    // dispatch(allapiAction.sellerDirectTeam({}));

    return () => {};
  }, []);

  // const loadOptionsPalyer = async (search, loadedOptions, page) => {
  //   const response = await fetch(
  //     URL.API_BASE_URL +
  //       `/publicApi/getuserforlinsense?search=${search}&page=${page}&user_id=${profile?.referId}`
  //   );
  //   const responseJSON = await response.json();

  //   const options = responseJSON?.results?.map((data) => {
  //     data.value = data?.id;
  //     data.label = data?.fullName;

  //     return data;
  //   });

  //   return {
  //     options: options,
  //     hasMore: responseJSON.has_more,
  //     additional: {
  //       page: page + 1,
  //     },
  //   };
  // };

  function getMonthList() {
    const startDate = new Date("2021-08-01");
    const currentDate = new Date();

    // Calculate the month one less than the current month
    currentDate.setMonth(currentDate.getMonth() - 1);

    const monthsList = [];

    while (startDate <= currentDate) {
      monthsList.push(
        startDate.toLocaleString("default", { month: "short", year: "numeric" })
      );

      startDate.setMonth(startDate.getMonth() + 1);
    }

    return monthsList;
  }

  // useEffect(() => {
  //   const months = getMonthList();
  //   console.log(months);
  // }, []);
  const options = {
    title: "Company Performance",
    curveType: "function",
    legend: { position: "bottom" },
  };

  const [tab, setTab] = useState("day");
  const proStatus =
    seller_update_details?.status == 1
      ? 0
      : seller_update_details?.status == 2
      ? 25
      : seller_update_details?.status == 3
      ? 50
      : seller_update_details?.status == 4
      ? 75
      : seller_update_details?.status == 5
      ? 100
      : 100;

  const [userselete, setuserselete] = useState(null);
  const [userselete2, setuserselete2] = useState(null);
  const [userselete3, setuserselete3] = useState(null);
  const [userselete4, setuserselete4] = useState(null);
  const loadOptionsPalyer = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/getuserforlinsense?search=${search}&page=${pages?.page}&user_id=${profile?.referId}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?.id;
      data.label = data?.fullName;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };

  const hendletoAssLicence = async (lisshowcount) => {
    // addlicence

    const userLicense =
      lisshowcount == 1
        ? seller_update_details?.Userchaindata?.license1
        : lisshowcount == 2
        ? seller_update_details?.Userchaindata?.license2
        : lisshowcount == 3
        ? seller_update_details?.Userchaindata?.license3
        : seller_update_details?.Userchaindata?.license4;

    if (!lisshowcount) {
      toast.error("please select license");
    } else if (!userLicense) {
      toast.error("already used");
    } else if (!userselete) {
      toast.error("please select user");
    } else {
      const donedata = await dispatch(
        allapiAction.addlicenceUser({
          userIdFrom: seller_update_details?._id,
          userIdTo: userselete?._id,
          licence: userLicense,
          licenceno: lisshowcount,
        })
      );

      if (donedata?.success) {
        dispatch(allapiAction.getsellerprofilebyseller({}));
      }

      // setstatusLicence(false);
    }
  };
  const hendletoAssLicence2 = async (lisshowcount) => {
    // addlicence

    const userLicense =
      lisshowcount == 1
        ? seller_update_details?.Userchaindata?.license1
        : lisshowcount == 2
        ? seller_update_details?.Userchaindata?.license2
        : lisshowcount == 3
        ? seller_update_details?.Userchaindata?.license3
        : seller_update_details?.Userchaindata?.license4;

    if (!lisshowcount) {
      toast.error("please select license");
    } else if (!userLicense) {
      toast.error("already used");
    } else if (!userselete2) {
      toast.error("please select user");
    } else {
      const donedata = await dispatch(
        allapiAction.addlicenceUser({
          userIdFrom: seller_update_details?._id,
          userIdTo: userselete2?._id,
          licence: userLicense,
          licenceno: lisshowcount,
        })
      );
      if (donedata?.success) {
        dispatch(allapiAction.getsellerprofilebyseller({}));
      }
      // setstatusLicence(false);
    }
  };
  const hendletoAssLicence3 = async (lisshowcount) => {
    // addlicence

    const userLicense =
      lisshowcount == 1
        ? seller_update_details?.Userchaindata?.license1
        : lisshowcount == 2
        ? seller_update_details?.Userchaindata?.license2
        : lisshowcount == 3
        ? seller_update_details?.Userchaindata?.license3
        : seller_update_details?.Userchaindata?.license4;

    if (!lisshowcount) {
      toast.error("please select license");
    } else if (!userLicense) {
      toast.error("already used");
    } else if (!userselete3) {
      toast.error("please select user");
    } else {
      const donedata = await dispatch(
        allapiAction.addlicenceUser({
          userIdFrom: seller_update_details?._id,
          userIdTo: userselete3?._id,
          licence: userLicense,
          licenceno: lisshowcount,
        })
      );

      // console.log(donedata);
      if (donedata?.success) {
        dispatch(allapiAction.getsellerprofilebyseller({}));
      }
      // setstatusLicence(false);
    }
  };
  const hendletoAssLicence4 = async (lisshowcount) => {
    // addlicence

    const userLicense =
      lisshowcount == 1
        ? seller_update_details?.Userchaindata?.license1
        : lisshowcount == 2
        ? seller_update_details?.Userchaindata?.license2
        : lisshowcount == 3
        ? seller_update_details?.Userchaindata?.license3
        : seller_update_details?.Userchaindata?.license4;

    if (!lisshowcount) {
      toast.error("please select license");
    } else if (!userLicense) {
      toast.error("already used");
    } else if (!userselete4) {
      toast.error("please select user");
    } else {
      const donedata = await dispatch(
        allapiAction.addlicenceUser({
          userIdFrom: seller_update_details?._id,
          userIdTo: userselete4?._id,
          licence: userLicense,
          licenceno: lisshowcount,
        })
      );
      if (donedata?.success) {
        dispatch(allapiAction.getsellerprofilebyseller({}));
      }
      // setstatusLicence(false);
    }
  };

  const [userDetailsEdit, setUserDetailsEdit] = useState({});
  const [userKycEdit, setUserKycEdit] = useState({});

  const [images, setImages] = React.useState([]);
  const maxNumber = 1;

  const onChange =async (imageList, addUpdateIndex) => {
    // data for submit
     if (imageList.length > 0) {
      const options = {
        maxSizeMB: 1, // Max 1MB
        maxWidthOrHeight: 800, // Max width/height
        useWebWorker: true, // Faster processing
      };
      const compressedFile = await imageCompression(imageList[0]?.file, options);
      imageList[0].newfile = compressedFile;
    }
    setImages(imageList);
  };

  const [images1, setImages1] = React.useState([]);

  const onChange1 = async(imageList, addUpdateIndex) => {
    // data for submit
     if (imageList.length > 0) {
      const options = {
        maxSizeMB: 1, // Max 1MB
        maxWidthOrHeight: 800, // Max width/height
        useWebWorker: true, // Faster processing
      };
      const compressedFile = await imageCompression(imageList[0]?.file, options);
      imageList[0].newfile = compressedFile;
    }
    setImages1(imageList);
  };
  const [images2, setImages2] = React.useState([]);

  const onChange2 =async (imageList, addUpdateIndex) => {
    // data for submit
      if (imageList.length > 0) {
       const options = {
         maxSizeMB: 1, // Max 1MB
         maxWidthOrHeight: 800, // Max width/height
         useWebWorker: true, // Faster processing
       };
       const compressedFile = await imageCompression(imageList[0]?.file, options);
       imageList[0].newfile = compressedFile;
     }
    setImages2(imageList);
  };
  const [imagesPro, setImagesPro] = React.useState([]);

  const onChangePro =async (imageList, addUpdateIndex) => {
    // data for submit
     if (imageList.length > 0) {
      const options = {
        maxSizeMB: 1, // Max 1MB
        maxWidthOrHeight: 800, // Max width/height
        useWebWorker: true, // Faster processing
      };
      const compressedFile = await imageCompression(imageList[0]?.file, options);
      imageList[0].newfile = compressedFile;
    }
    setImagesPro(imageList);
  };
  const [images3, setImages3] = React.useState([]);

  const onChange3 = async(imageList, addUpdateIndex) => {
    // data for submit
   if (imageList.length > 0) {
    const options = {
      maxSizeMB: 1, // Max 1MB
      maxWidthOrHeight: 800, // Max width/height
      useWebWorker: true, // Faster processing
    };
    const compressedFile = await imageCompression(imageList[0]?.file, options);
    imageList[0].newfile = compressedFile;
  }
    setImages3(imageList);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [show4, setShow4] = useState(false);

  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);

  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const get_buss_pro_1 = useSelector((state) =>
    state?.allapi?.get_buss_pro_1 ? state?.allapi?.get_buss_pro_1 : {}
  );
  console.log(get_buss_pro_1);
  console.log(get_buss_pro_1?.profile_image);
  const hendleToprofileEdit = (e) => {
    const { name, value } = e?.target;

    setUserDetailsEdit({ ...userDetailsEdit, [name]: value });
  };
  const hendleToprofileEdit1 = (e) => {
    const { name, value } = e?.target;

    setUserKycEdit({ ...userKycEdit, [name]: value });
  };
  const hendleToprofileEditKyc = (e) => {
    const { name, value } = e?.target;

    setUserKycEdit({ ...userKycEdit, [name]: value });
  };
  useEffect(() => {
    setUserDetailsEdit(get_buss_pro_1);
    setUserKycEdit(get_buss_pro_1?.kyc_id);

    // setImages
    if (get_buss_pro_1?.profile_image) {
      setImagesPro([
        { data_url: URL.API_BASE_URL + get_buss_pro_1?.profile_image },
      ]);
    }
    if (get_buss_pro_1?.kyc_id?.bankpassbook) {
      setImages([
        { data_url: URL.API_BASE_URL + get_buss_pro_1?.kyc_id?.bankpassbook },
      ]);
    }
    if (get_buss_pro_1?.kyc_id?.aadharfrontImage) {
      setImages1([
        {
          data_url: URL.API_BASE_URL + get_buss_pro_1?.kyc_id?.aadharfrontImage,
        },
      ]);
    }
    if (get_buss_pro_1?.kyc_id?.aadharbacktImage) {
      setImages2([
        {
          data_url: URL.API_BASE_URL + get_buss_pro_1?.kyc_id?.aadharbacktImage,
        },
      ]);
    }
    if (get_buss_pro_1?.kyc_id?.pancardImage) {
      setImages3([
        { data_url: URL.API_BASE_URL + get_buss_pro_1?.kyc_id?.pancardImage },
      ]);
    }
  }, [get_buss_pro_1]);

  const hendleToupdate = () => {
    // edituserProfileByuser
    dispatch(allapiAction.edituserProfileByuser(userDetailsEdit));
    setShow(false);
    setShow4(false);
  };

  async function validateIFSC(ifsc) {
    const apiUrl = `https://ifsc.razorpay.com/${ifsc}`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      if (response.ok) {
        // Check if the bank returned from API matches the expected bank
        // if (data.BANK.toLowerCase() == userKycEdit?.bank_name) {
        //   return true; // IFSC is valid and matches the expected bank
        // } else {
        //   return false; // IFSC is valid but does not match the expected bank
        // }
        return true;
      } else {
        return false; // Error in API response
      }
    } catch (error) {
      console.error("Error:", error);
      return false; // Error during API call
    }
  }

  const hendleToupdate1 = async () => {
    // edituserProfileByuser

    if (!userKycEdit?.ifsc) {
      toast.error("please enter ifsc code ");
      return;
    }
    const resp = await validateIFSC(userKycEdit?.ifsc);
    if (!resp) {
      toast.error("invalid ifsc code and bank name");
      return;
    }
    if (!userKycEdit?.bank_name) {
      toast.error("please enter bank name ");
      return;
    }
    if (!userKycEdit?.acc_no) {
      toast.error("please enter account number");
      return;
    }

    if (!userKycEdit?.aadhar_card) {
      toast.error("please enter aadhar card number");
      return;
    }

    if (!validateAadhar(userKycEdit?.aadhar_card)) {
      // console.log("Valid Aadhar number.");
      toast.error("Invalid Aadhar number.");
      return;
    }

    // data.append("acc_no", userKycEdit?.acc_no);
    // data.append("ifsc", userKycEdit?.ifsc);
    // data.append("_id", userKycEdit?._id);
    if (images1?.length == 0) {
      // roledata?.role_name

      toast.error("please select aadhar front Image");
      return;
      // data.append("aadharfrontImage", images1[0]?.file);
    }
    if (images2?.length == 0) {
      // roledata?.role_name
      toast.error("please select Aadhar Back Image");
      return;
      // data.append("aadharbacktImage", images2[0]?.file);
    }

    if (!userKycEdit?.pan_card) {
      toast.error("please enter pan card number");
      return;
    }
    if (!validatePanCard(userKycEdit?.pan_card)) {
      toast.error("invalid pancard");
      return;
    }
    if (images3?.length == 0) {
      // roledata?.role_name
      toast.error("please select pan card Image");
      return;
      // data.append("pancardImage", images3[0]?.file);
    }
    const data = new FormData();
    data.append("pan_card", userKycEdit?.pan_card);
    data.append("aadhar_card", userKycEdit?.aadhar_card);
    // data.append("acc_no", userKycEdit?.acc_no);
    // data.append("ifsc", userKycEdit?.ifsc);
    // data.append("_id", userKycEdit?._id);
    if (images1[0]?.file) {
      // roledata?.role_name
      data.append("aadharfrontImage",images1[0]?.newfile ,images1[0]?.file?.name);
      // data.append("aadharfrontImage", images1[0]?.file);
    }
    if (images2[0]?.file) {
      // roledata?.role_name
      // data.append("aadharbacktImage", images2[0]?.file);
      data.append("aadharbacktImage",images2[0]?.newfile ,images2[0]?.file?.name);
    }
    if (images3[0]?.file) {
      // roledata?.role_name
      // data.append("pancardImage", images3[0]?.file);
      data.append("pancardImage",images3[0]?.newfile ,images3[0]?.file?.name);
    }

    // const data = new FormData();
    data.append("branch_name", userKycEdit?.branch_name);
    data.append("bank_name", userKycEdit?.bank_name);
    data.append("acc_no", userKycEdit?.acc_no);
    data.append("ifsc", userKycEdit?.ifsc);
    data.append("_id", userKycEdit?._id);
    if (images?.length == 0) {
      toast.error("please select bank passbook");
      return;
    }
    if (images[0]?.file) {
      // roledata?.role_name
      // data.append("bankpassbook", images[0]?.file);
      data.append("bankpassbook",images[0]?.newfile ,images[0]?.file?.name);
    }
    const response = await dispatch(allapiAction.edituserKycByUser(data));
    if (response?.success) {
      dispatch(allapiAction.getprofile({}));
      setShow1(false);
    }
  };
  const hendleToupdatepro = async () => {
    // edituserProfileByuser

    if (!imagesPro[0]?.file) {
      toast.error("please select profile");
      return;
    }
    const data = new FormData();
    // data.append("branch_name", userKycEdit?.branch_name);
    // data.append("bank_name", userKycEdit?.bank_name);
    // data.append("acc_no", userKycEdit?.acc_no);
    // data.append("ifsc", userKycEdit?.ifsc);
    // data.append("_id", userKycEdit?._id);
    if (imagesPro[0]?.file) {
      // roledata?.role_name
      // data.append("profile_image", imagesPro[0]?.file);
      data.append("profile_image",imagesPro[0]?.newfile ,imagesPro[0]?.file?.name);
    }
    await dispatch(allapiAction.edituserProfileimageByUser(data));
    dispatch(allapiAction.getprofile({}));
    setShow5(false);
  };

  function validatePanCard(panNumber) {
    // Regular expression to match PAN card pattern
    var upperPanNumber = panNumber.toUpperCase();
    var panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;

    // Check if the provided panNumber matches the pattern
    return panRegex.test(upperPanNumber);
  }
  function validateAadhar(aadhar) {
    // Aadhar number format: 12 digits
    var aadharRegex = /^\d{12}$/;

    if (aadharRegex.test(aadhar)) {
      return true; // Valid Aadhar number
    } else {
      return false; // Invalid Aadhar number
    }
  }
  const hendleToupdate2 = async () => {
    // edituserProfileByuser
    console.log("userKycEdit?.pan_card");
    console.log(userKycEdit?.pan_card);
    if (!validatePanCard(userKycEdit?.pan_card)) {
      toast.error("invalid pancard");
      return;
    }

    if (!validateAadhar(userKycEdit?.aadhar_card)) {
      // console.log("Valid Aadhar number.");
      toast.error("Invalid Aadhar number.");
      return;
    }

    // data.append("acc_no", userKycEdit?.acc_no);
    // data.append("ifsc", userKycEdit?.ifsc);
    // data.append("_id", userKycEdit?._id);
    if (images1?.length == 0) {
      // roledata?.role_name

      toast.error("please select aadhar front Image");
      return;
      // data.append("aadharfrontImage", images1[0]?.file);
    }
    if (images2?.length == 0) {
      // roledata?.role_name
      toast.error("please select Aadhar Back Image");
      return;
      // data.append("aadharbacktImage", images2[0]?.file);
    }
    if (images3?.length == 0) {
      // roledata?.role_name
      toast.error("please select pan card Image");
      return;
      // data.append("pancardImage", images3[0]?.file);
    }
    const data = new FormData();
    data.append("pan_card", userKycEdit?.pan_card);
    data.append("aadhar_card", userKycEdit?.aadhar_card);
    // data.append("acc_no", userKycEdit?.acc_no);
    // data.append("ifsc", userKycEdit?.ifsc);
    // data.append("_id", userKycEdit?._id);
    if (images1[0]?.file) {
      // roledata?.role_name
      // data.append("aadharfrontImage", images1[0]?.file);
      data.append("aadharfrontImage",images1[0]?.newfile ,images1[0]?.file?.name);
    }
    if (images2[0]?.file) {
      // roledata?.role_name
      // data.append("aadharbacktImage", images2[0]?.file);
      data.append("aadharbacktImage",images2[0]?.newfile ,images2[0]?.file?.name);
    }
    if (images3[0]?.file) {
      // roledata?.role_name
      // data.append("pancardImage", images3[0]?.file);
      data.append("pancardImage",images3[0]?.newfile ,images3[0]?.file?.name);
    }

    await dispatch(allapiAction.edituserKycByUser(data));
    dispatch(allapiAction.getprofile({}));
    setShow2(false);
  };
  const hendleToupdate3 = async () => {
    // edituserProfileByuser
    const data = new FormData();
    data.append("naddress", userKycEdit?.naddress);
    data.append("nname", userKycEdit?.nname);
    data.append("nmobile", userKycEdit?.nmobile);
    data.append("nrealtion", userKycEdit?.nrealtion);
    data.append("_id", userKycEdit?._id);

    await dispatch(allapiAction.edituserKycByUser(data));
    dispatch(allapiAction.getprofile({}));
    setShow3(false);
  };

  const [editTab, seteditTab] = useState("contact");

  const [imageSrc, setImageSrc] = React.useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  console.log(imageSrc);
  console.log(croppedImage);
  console.log(croppedAreaPixels);
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      );
      console.log(croppedImage);

      const base64Parts = croppedImage.split(",");
      console.log(base64Parts);
      if (base64Parts.length !== 2 || !base64Parts[1]) {
        console.error("Invalid base64 string format");
        return;
      }

      const base64Data = base64Parts[1];

      try {
        const decodedData = atob(base64Data);
        const byteNumbers = new Array(decodedData.length);
        for (let i = 0; i < decodedData.length; i++) {
          byteNumbers[i] = decodedData.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // Create Blob from byteArray (example)
        const blob = new Blob([byteArray], { type: "image/jpeg" });

        // Now you can proceed to upload 'blob' via FormData or other means
      } catch (error) {
        console.error("Base64 decoding error:", error);
      }
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  };

  const onClose = () => {
    setCroppedImage(null);
  };
  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }
  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      try {
        // apply rotation if needed
        const orientation = await getOrientation(file);
        const rotation = ORIENTATION_TO_ANGLE[orientation];
        if (rotation) {
          imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
        }
      } catch (e) {
        console.warn("failed to detect the orientation");
      }

      setImageSrc(imageDataUrl);
    }
  };

  const [changePasswordBoxShow, setchangePasswordBoxShow] = useState(false);

  const handleClosechangePasswordBoxShow = () =>
    setchangePasswordBoxShow(false);
  const handleShowchangePasswordBoxShow = () => setchangePasswordBoxShow(true);

  const [PassData, setPassData] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });

  const onChangePassword = (e) => {
    const { name, value } = e.target;
    setPassData({ ...PassData, [name]: value });
  };

  const hendleResetPass = async () => {
    if (!PassData?.password) {
      toast.error("please enter old password");
    } else if (!PassData?.newPassword) {
      toast.error("please enter new password");
    } else if (PassData?.newPassword !== PassData?.confirmPassword) {
      toast.error("confirm password not match");
    } else {
      const response = await dispatch(allapiAction.passwordChange(PassData));

      if (response?.success) {
        setchangePasswordBoxShow(false);
      }

      return () => {};
    }
  };

  return (
    <div className="AppPro">
      {/* {loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <BounceLoader color="#0D6EFD" />
        </div>
      ) : ( */}
      <div>
        {/*  password change  */}
        <Modal
          show={changePasswordBoxShow}
          onHide={handleClosechangePasswordBoxShow}
        >
          <Modal.Header closeButton>
            <Modal.Title>Change Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="card mx-auto ">
              {/* {!showOtpBox ? ( */}
              <div className="card-body">
                {/* <h4 className="card-title mb-4">Feature Image</h4> */}

                <div className="mb-3">
                  <label for="title" className="form-label">
                    Old Password
                  </label>
                  <input
                    name="password"
                    className="form-control"
                    placeholder="Enter Old Password"
                    type="text"
                    onChange={(e) => {
                      onChangePassword(e);
                    }}
                  />
                </div>

                <div className="mb-3">
                  <label for="teg" className="form-label">
                    New Password
                  </label>
                  <input
                    name="newPassword"
                    className="form-control"
                    placeholder="Enter New Password"
                    type="text"
                    onChange={(e) => {
                      onChangePassword(e);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label for="teg" className="form-label">
                    Confirm Password
                  </label>
                  <input
                    name="confirmPassword"
                    className="form-control"
                    placeholder="Enter Confirm Password"
                    type="text"
                    onChange={(e) => {
                      onChangePassword(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>

          {/* {!showOtpBox ? ( */}
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleClosechangePasswordBoxShow}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                hendleResetPass();
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
          {/* ) : (
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => {
                  submitChangeVerify2();
                }}
              >
                Verify
              </Button>
            </Modal.Footer>
          )} */}
        </Modal>
        {/* address */}

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Update Address</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="card mx-auto ">
              <div className="card-body">
                <div className="mb-3">
                  <label for="address1" className="form-label">
                    Address
                  </label>
                  <input
                    name="address1"
                    className="form-control"
                    placeholder="Enter Address 1"
                    type="text"
                    value={userDetailsEdit?.address1}
                    onChange={(e) => {
                      hendleToprofileEdit(e);
                    }}
                  />
                </div>
                {/* <div className="mb-3">
                  <label for="address2" className="form-label">
                    Address 2
                  </label>
                  <input
                    name="address2"
                    className="form-control"
                    placeholder="Enter Address 2"
                    type="text"
                    value={userDetailsEdit?.address2}
                    onChange={(e) => {
                      hendleToprofileEdit(e);
                    }}
                  />
                </div> */}
                <div className="mb-3">
                  <label for="city" className="form-label">
                    City
                  </label>
                  <input
                    name="city"
                    className="form-control"
                    placeholder="Enter city"
                    type="text"
                    value={userDetailsEdit?.city}
                    onChange={(e) => {
                      hendleToprofileEdit(e);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label for="state" className="form-label">
                    State
                  </label>
                  <input
                    name="state"
                    className="form-control"
                    placeholder="Enter state"
                    type="text"
                    value={userDetailsEdit?.state}
                    onChange={(e) => {
                      hendleToprofileEdit(e);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label for="zip" className="form-label">
                    zip
                  </label>
                  <input
                    name="zip"
                    className="form-control"
                    placeholder="Enter zip"
                    value={userDetailsEdit?.zip}
                    type="text"
                    onChange={(e) => {
                      hendleToprofileEdit(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              className="ms-2"
              onClick={() => {
                hendleToupdate();
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        {/* contact */}
        <Modal show={show4} onHide={handleClose4}>
          <Modal.Header closeButton>
            <Modal.Title>Update Contact</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="card mx-auto ">
              <div className="card-body">
                <div className="mb-3">
                  <label for="father_name" className="form-label">
                    {/* Father name */}
                  </label>
                  <select
                    name="guardian"
                    className="form-control"
                    onChange={(e) => {
                      hendleToprofileEdit(e);
                    }}
                    value={userDetailsEdit?.guardian}
                  >
                    <option>Select </option>
                    <option value={"S/O"}>S/O</option>
                    <option value={"D/O"}>D/O</option>
                    <option value={"W/O"}>W/O</option>
                  </select>
                </div>
                <div className="mb-3">
                  {userDetailsEdit?.guardian == "W/O" ? (
                    <label for="father_name" className="form-label">
                      Husband name
                    </label>
                  ) : (
                    <label for="father_name" className="form-label">
                      Father name
                    </label>
                  )}
                  <input
                    name="father_name"
                    className="form-control"
                    placeholder="Enter father name"
                    type="text"
                    value={userDetailsEdit?.father_name}
                    onChange={(e) => {
                      hendleToprofileEdit(e);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label for="father_name" className="form-label">
                    Gender
                  </label>
                  <select
                    className="form-control"
                    name="gender"
                    onChange={(e) => {
                      hendleToprofileEdit(e);
                    }}
                    value={userDetailsEdit?.gender}
                  >
                    <option>Select </option>
                    <option value={"male"}>Male</option>
                    <option value={"female"}>Female</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label for="father_name" className="form-label">
                    Marital Status
                  </label>
                  <select
                    className="form-control"
                    name="marital"
                    onChange={(e) => {
                      hendleToprofileEdit(e);
                    }}
                    value={userDetailsEdit?.marital}
                  >
                    <option>Select </option>
                    <option value={"SINGLE"}>SINGLE</option>
                    <option value={"MARRIED"}>MARRIED</option>
                    {/* <option value={"DIVORCED"}>DIVORCED</option>
                    <option value={"OTHER"}>OTHER</option> */}
                  </select>
                </div>
                <div className="mb-3">
                  <label for="father_name" className="form-label">
                    Profession
                  </label>
                  <input
                    name="professional"
                    onChange={(e) => {
                      hendleToprofileEdit(e);
                    }}
                    value={userDetailsEdit?.professional}
                    className="form-control"
                  />
                </div>
                {/* <div className="mb-3">
                  <label for="address2" className="form-label">
                    Address 2
                  </label>
                  <input
                    name="address2"
                    className="form-control"
                    placeholder="Enter Address 2"
                    type="text"
                    value={userDetailsEdit?.address2}
                    onChange={(e) => {
                      hendleToprofileEdit(e);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label for="city" className="form-label">
                    City
                  </label>
                  <input
                    name="city"
                    className="form-control"
                    placeholder="Enter city"
                    type="text"
                    value={userDetailsEdit?.city}
                    onChange={(e) => {
                      hendleToprofileEdit(e);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label for="state" className="form-label">
                    State
                  </label>
                  <input
                    name="state"
                    className="form-control"
                    placeholder="Enter state"
                    type="text"
                    value={userDetailsEdit?.state}
                    onChange={(e) => {
                      hendleToprofileEdit(e);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label for="zip" className="form-label">
                    zip
                  </label>
                  <input
                    name="zip"
                    className="form-control"
                    placeholder="Enter zip"
                    value={userDetailsEdit?.zip}
                    type="text"
                    onChange={(e) => {
                      hendleToprofileEdit(e);
                    }}
                  />
                </div> */}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose4}>
              Cancel
            </Button>
            <Button
              variant="primary"
              className="ms-2"
              onClick={() => {
                hendleToupdate();
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        {/* bank */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Update Bank Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="card mx-auto ">
              <div className="card-body">
                {/* <h4 className="card-title mb-4">Feature Image</h4> */}

                <div className="mb-3">
                  <label for="product_name" className="form-label">
                    Bank passbook
                  </label>
                  <ImageUploading
                    // multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                    acceptType={["png", "jpeg", "jpg", "webp"]}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className="upload__image-wrapper">
                        <button
                          className="btn btn-primary btn-sm rounded"
                          style={isDragging ? { color: "red" } : undefined}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          Click or Drop here
                        </button>
                        &nbsp;
                        {/* <button
                          className="btn btn-primary btn-sm rounded"
                          onClick={onImageRemoveAll}
                        >
                          Remove all images
                        </button> */}
                        {imageList.map((image, index) => (
                          <div key={index} className="image-item">
                            <img src={image["data_url"]} alt="" width="100" />
                            <div className="image-item__btn-wrapper">
                              <button
                                className="btn btn-primary btn-sm rounded mb-2"
                                onClick={() => onImageUpdate(index)}
                              >
                                Update
                              </button>
                              <button
                                className="btn btn-primary btn-sm rounded"
                                onClick={() => onImageRemove(index)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </ImageUploading>
                </div>
                <div className="mb-3">
                  <label for="title" className="form-label">
                    IFSC
                  </label>
                  <input
                    name="ifsc"
                    className="form-control"
                    placeholder="Enter ifsc"
                    type="text"
                    value={userKycEdit?.ifsc}
                    onChange={(e) => {
                      hendleToprofileEdit1(e);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label for="bank_name" className="form-label">
                    bank name
                  </label>
                  <input
                    name="bank_name"
                    className="form-control"
                    placeholder="Enter bank_name"
                    type="text"
                    value={userKycEdit?.bank_name}
                    onChange={(e) => {
                      hendleToprofileEdit1(e);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label for="title" className="form-label">
                    branch name
                  </label>
                  <input
                    name="branch_name"
                    className="form-control"
                    placeholder="Enter branch_name"
                    type="text"
                    value={userKycEdit?.branch_name}
                    onChange={(e) => {
                      hendleToprofileEdit1(e);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label for="acc_no" className="form-label">
                    Account no
                  </label>
                  <input
                    name="acc_no"
                    className="form-control"
                    placeholder="Enter Account No."
                    type="text"
                    value={userKycEdit?.acc_no}
                    onChange={(e) => {
                      hendleToprofileEdit1(e);
                    }}
                  />
                </div>

                {/* <div className="mb-3">
                  <label for="teg" className="form-label">
                    Product Tag
                  </label>
                  <input
                    name="tegs"
                    className="form-control"
                    placeholder="Enter tag"
                    type="text"
                    onChange={(e) => {
                      hendleChangerole(e);
                    }}
                  />
                </div> */}
              </div>
            </div>
            <div className="card mx-auto ">
              <div className="card-body">
                {/* <h4 className="card-title mb-4">Feature Image</h4> */}

                <div className="mb-3">
                  <label for="title" className="form-label">
                    Aadhar Card No.
                  </label>
                  <input
                    name="aadhar_card"
                    className="form-control"
                    placeholder="Enter aadhar card"
                    type="text"
                    value={userKycEdit?.aadhar_card}
                    onChange={(e) => {
                      hendleToprofileEdit1(e);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label for="product_name" className="form-label">
                    Addhar Front Photo
                  </label>
                  <ImageUploading
                    // multiple
                    value={images1}
                    onChange={onChange1}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                    acceptType={["png", "jpeg", "jpg", "webp"]}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className="upload__image-wrapper">
                        <button
                          className="btn btn-primary btn-sm rounded"
                          style={isDragging ? { color: "red" } : undefined}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          Click or Drop here
                        </button>
                        &nbsp;
                        {/* <button
                          className="btn btn-primary btn-sm rounded"
                          onClick={onImageRemoveAll}
                        >
                          Remove all images
                        </button> */}
                        {imageList.map((image, index) => (
                          <div key={index} className="image-item">
                            <img src={image["data_url"]} alt="" width="100" />
                            <div className="image-item__btn-wrapper">
                              <button
                                className="btn btn-primary btn-sm rounded mb-2"
                                onClick={() => onImageUpdate(index)}
                              >
                                Update
                              </button>
                              <button
                                className="btn btn-primary btn-sm rounded"
                                onClick={() => onImageRemove(index)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </ImageUploading>
                </div>
                <div className="mb-3">
                  <label for="product_name" className="form-label">
                    Aadhar back Photo
                  </label>
                  <ImageUploading
                    // multiple
                    value={images2}
                    onChange={onChange2}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                    acceptType={["png", "jpeg", "jpg", "webp"]}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className="upload__image-wrapper">
                        <button
                          className="btn btn-primary btn-sm rounded"
                          style={isDragging ? { color: "red" } : undefined}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          Click or Drop here
                        </button>
                        &nbsp;
                        {/* <button
                          className="btn btn-primary btn-sm rounded"
                          onClick={onImageRemoveAll}
                        >
                          Remove all images
                        </button> */}
                        {imageList.map((image, index) => (
                          <div key={index} className="image-item">
                            <img src={image["data_url"]} alt="" width="100" />
                            <div className="image-item__btn-wrapper">
                              <button
                                className="btn btn-primary btn-sm rounded mb-2"
                                onClick={() => onImageUpdate(index)}
                              >
                                Update
                              </button>
                              <button
                                className="btn btn-primary btn-sm rounded"
                                onClick={() => onImageRemove(index)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </ImageUploading>
                </div>
                <div className="mb-3">
                  <label for="pan_card" className="form-label">
                    Pan card
                  </label>
                  <input
                    name="pan_card"
                    className="form-control"
                    placeholder="Enter pan_card"
                    type="text"
                    value={userKycEdit?.pan_card}
                    onChange={(e) => {
                      hendleToprofileEdit1(e);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label for="product_name" className="form-label">
                    Pan Card Photo
                  </label>
                  <ImageUploading
                    // multiple
                    value={images3}
                    onChange={onChange3}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                    acceptType={["png", "jpeg", "jpg", "webp"]}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className="upload__image-wrapper">
                        <button
                          className="btn btn-primary btn-sm rounded"
                          style={isDragging ? { color: "red" } : undefined}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          Click or Drop here
                        </button>
                        &nbsp;
                        {/* <button
                          className="btn btn-primary btn-sm rounded"
                          onClick={onImageRemoveAll}
                        >
                          Remove all images
                        </button> */}
                        {imageList.map((image, index) => (
                          <div key={index} className="image-item">
                            <img src={image["data_url"]} alt="" width="100" />
                            <div className="image-item__btn-wrapper">
                              <button
                                className="btn btn-primary btn-sm rounded mb-2"
                                onClick={() => onImageUpdate(index)}
                              >
                                Update
                              </button>
                              <button
                                className="btn btn-primary btn-sm rounded"
                                onClick={() => onImageRemove(index)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </ImageUploading>
                </div>

                {/* <div className="mb-3">
                  <label for="teg" className="form-label">
                    Product Tag
                  </label>
                  <input
                    name="tegs"
                    className="form-control"
                    placeholder="Enter tag"
                    type="text"
                    onChange={(e) => {
                      hendleChangerole(e);
                    }}
                  />
                </div> */}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Cancel
            </Button>
            <Button
              variant="primary"
              className="ms-2"
              onClick={() => {
                hendleToupdate1();
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        {/* kyc */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Update KYC</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="card mx-auto ">
              <div className="card-body">
                {/* <h4 className="card-title mb-4">Feature Image</h4> */}

                <div className="mb-3">
                  <label for="title" className="form-label">
                    Aadhar Card No.
                  </label>
                  <input
                    name="aadhar_card"
                    className="form-control"
                    placeholder="Enter aadhar card"
                    type="text"
                    value={userKycEdit?.aadhar_card}
                    onChange={(e) => {
                      hendleToprofileEdit1(e);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label for="product_name" className="form-label">
                    Addhar Front Photo
                  </label>
                  <ImageUploading
                    // multiple
                    value={images1}
                    onChange={onChange1}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                    acceptType={["png", "jpeg", "jpg", "webp"]}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className="upload__image-wrapper">
                        <button
                          className="btn btn-primary btn-sm rounded"
                          style={isDragging ? { color: "red" } : undefined}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          Click or Drop here
                        </button>
                        &nbsp;
                        {/* <button
                          className="btn btn-primary btn-sm rounded"
                          onClick={onImageRemoveAll}
                        >
                          Remove all images
                        </button> */}
                        {imageList.map((image, index) => (
                          <div key={index} className="image-item">
                            <img src={image["data_url"]} alt="" width="100" />
                            <div className="image-item__btn-wrapper">
                              <button
                                className="btn btn-primary btn-sm rounded mb-2"
                                onClick={() => onImageUpdate(index)}
                              >
                                Update
                              </button>
                              <button
                                className="btn btn-primary btn-sm rounded"
                                onClick={() => onImageRemove(index)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </ImageUploading>
                </div>
                <div className="mb-3">
                  <label for="product_name" className="form-label">
                    Aadhar back Photo
                  </label>
                  <ImageUploading
                    // multiple
                    value={images2}
                    onChange={onChange2}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                    acceptType={["png", "jpeg", "jpg", "webp"]}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className="upload__image-wrapper">
                        <button
                          className="btn btn-primary btn-sm rounded"
                          style={isDragging ? { color: "red" } : undefined}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          Click or Drop here
                        </button>
                        &nbsp;
                        {/* <button
                          className="btn btn-primary btn-sm rounded"
                          onClick={onImageRemoveAll}
                        >
                          Remove all images
                        </button> */}
                        {imageList.map((image, index) => (
                          <div key={index} className="image-item">
                            <img src={image["data_url"]} alt="" width="100" />
                            <div className="image-item__btn-wrapper">
                              <button
                                className="btn btn-primary btn-sm rounded mb-2"
                                onClick={() => onImageUpdate(index)}
                              >
                                Update
                              </button>
                              <button
                                className="btn btn-primary btn-sm rounded"
                                onClick={() => onImageRemove(index)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </ImageUploading>
                </div>
                <div className="mb-3">
                  <label for="pan_card" className="form-label">
                    Pan card
                  </label>
                  <input
                    name="pan_card"
                    className="form-control"
                    placeholder="Enter pan_card"
                    type="text"
                    value={userKycEdit?.pan_card}
                    onChange={(e) => {
                      hendleToprofileEdit1(e);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label for="product_name" className="form-label">
                    Pan Card Photo
                  </label>
                  <ImageUploading
                    // multiple
                    value={images3}
                    onChange={onChange3}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                    acceptType={["png", "jpeg", "jpg", "webp"]}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className="upload__image-wrapper">
                        <button
                          className="btn btn-primary btn-sm rounded"
                          style={isDragging ? { color: "red" } : undefined}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          Click or Drop here
                        </button>
                        &nbsp;
                        {/* <button
                          className="btn btn-primary btn-sm rounded"
                          onClick={onImageRemoveAll}
                        >
                          Remove all images
                        </button> */}
                        {imageList.map((image, index) => (
                          <div key={index} className="image-item">
                            <img src={image["data_url"]} alt="" width="100" />
                            <div className="image-item__btn-wrapper">
                              <button
                                className="btn btn-primary btn-sm rounded mb-2"
                                onClick={() => onImageUpdate(index)}
                              >
                                Update
                              </button>
                              <button
                                className="btn btn-primary btn-sm rounded"
                                onClick={() => onImageRemove(index)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </ImageUploading>
                </div>

                {/* <div className="mb-3">
                  <label for="teg" className="form-label">
                    Product Tag
                  </label>
                  <input
                    name="tegs"
                    className="form-control"
                    placeholder="Enter tag"
                    type="text"
                    onChange={(e) => {
                      hendleChangerole(e);
                    }}
                  />
                </div> */}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Cancel
            </Button>
            <Button
              variant="primary"
              className="ms-2"
              onClick={() => {
                hendleToupdate2();
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        {/* nominee */}
        <Modal show={show3} onHide={handleClose3}>
          <Modal.Header closeButton>
            <Modal.Title>Update Nominee</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="card mx-auto ">
              <div className="card-body">
                {/* <h4 className="card-title mb-4">Feature Image</h4> */}

                <div className="mb-3">
                  <label for="nname" className="form-label">
                    Nominee name{" "}
                  </label>
                  <input
                    name="nname"
                    className="form-control"
                    placeholder="Enter nominee name"
                    type="text"
                    value={userKycEdit?.nname}
                    onChange={(e) => {
                      hendleToprofileEdit1(e);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label for="nmobile" className="form-label">
                    Nominee mobile
                  </label>
                  <input
                    name="nmobile"
                    className="form-control"
                    placeholder="Enter nominee mobile"
                    type="text"
                    value={userKycEdit?.nmobile}
                    onChange={(e) => {
                      hendleToprofileEdit1(e);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label for="naddress" className="form-label">
                    Nominee address
                  </label>
                  <input
                    name="naddress"
                    className="form-control"
                    placeholder="Enter nominee address"
                    type="text"
                    value={userKycEdit?.naddress}
                    onChange={(e) => {
                      hendleToprofileEdit1(e);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label for="naddress" className="form-label">
                    Nominee realtion
                  </label>

                  <select
                    name="nrealtion"
                    className="form-control"
                    value={userKycEdit?.nrealtion}
                    onChange={(e) => {
                      hendleToprofileEdit1(e);
                    }}
                  >
                    <option value={""}>select</option>
                    <option value={"father"}>Father</option>
                    <option value={"mother"}>Mother</option>
                    <option value={"other"}>Other</option>
                  </select>
                  {/* <input
                    name="naddress"
                    className="form-control"
                    placeholder="Enter nominee address"
                    type="text"
                    value={userKycEdit?.naddress}
                    onChange={(e) => {
                      hendleToprofileEdit1(e);
                    }}
                  /> */}
                </div>
                {/* <div className="mb-3">
                  <label for="naddress" className="form-label">
                    Nominee realtion
                  </label>

                  <select
                    name="nrealtion"
                    className="form-control"
                    value={userKycEdit?.nrealtion}
                    onChange={(e) => {
                      hendleToprofileEdit1(e);
                    }}
                  >
                    <option value={""}>select</option>
                    <option value={"father"}>Father</option>
                    <option value={"mother"}>Mother</option>
                  </select>
                
                </div> */}

                {/* <div className="mb-3">
                  <label for="teg" className="form-label">
                    Product Tag
                  </label>
                  <input
                    name="tegs"
                    className="form-control"
                    placeholder="Enter tag"
                    type="text"
                    onChange={(e) => {
                      hendleChangerole(e);
                    }}
                  />
                </div> */}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Cancel
            </Button>
            <Button
              variant="primary"
              className="ms-2"
              onClick={() => {
                hendleToupdate3();
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        {/* profile */}
        <Modal show={show5} onHide={handleClose5}>
          <Modal.Header closeButton>
            <Modal.Title>Update Profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="card mx-auto ">
              <div className="card-body">
                {/* <h4 className="card-title mb-4">Feature Image</h4> */}

                {/* {imageSrc ? (
                  <div className="mb-3">
                    
                    <div className="cropContainer">
                      <Cropper
                        image={imageSrc}
                        crop={crop}
                        rotation={rotation}
                        zoom={zoom}
                        aspect={4 / 3}
                        onCropChange={setCrop}
                        onRotationChange={setRotation}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                      />
                    </div>
                    <div></div>
                    <Button
                      onClick={showCroppedImage}
                      variant="contained"
                      color="primary"
                      className={"cropButton"}
                    >
                      Show Result
                    </Button>

                    <img src={croppedImage} alt="" style={{ width: "100%" }} />
                  </div>
                ) : (
                  <input type="file" onChange={onFileChange} accept="image/*" />
                )} */}
                <div className="mb-3">
                  <label for="product_name" className="form-label">
                    Profile
                  </label>
                  <ImageUploading
                    // multiple
                    value={imagesPro}
                    onChange={onChangePro}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                    acceptType={["png", "jpeg", "jpg", "webp"]}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className="upload__image-wrapper">
                        <button
                          className="btn btn-primary btn-sm rounded"
                          style={isDragging ? { color: "red" } : undefined}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          Click or Drop here
                        </button>
                        &nbsp;
                        {imageList.map((image, index) => (
                          <div key={index} className="image-item">
                            <img src={image["data_url"]} alt="" width="100" />
                            <div className="image-item__btn-wrapper">
                              <button
                                className="btn btn-primary btn-sm rounded mb-2"
                                onClick={() => onImageUpdate(index)}
                              >
                                Update
                              </button>
                              <button
                                className="btn btn-primary btn-sm rounded"
                                onClick={() => onImageRemove(index)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </ImageUploading>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose5}>
              Cancel
            </Button>
            <Button
              variant="primary"
              className="ms-2"
              onClick={() => {
                hendleToupdatepro();
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        <Sidebar />

        <main className="main-wrap pt">
          <HeaderAccount />

          <section className="content-main">
            <div className="content-header">
              <div>
                <h2 className="content-title card-title">Profile</h2>
              </div>
            </div>

            <div class="container-fluid" id="puan">
              <div>
                <div class="puan">
                <div className="cardProFile">
                <div class="row">
                    <div class="col-4 mt-2">
                      <div id="avatarvsm">
                        <img
                          src={
                            get_buss_pro_1?.profile_image
                              ? URL?.API_BASE_URL +
                                get_buss_pro_1?.profile_image
                              : "/avatar-2.png"
                          }
                          onError={(e) => {
                          e.target.src = "/avatar-2.png";
                        }}
                        />
                        <button
                          className="proeditbtn"
                          onClick={() => {
                            handleShow5();
                          }}
                        >
                          <i
                            class="fa fa-pencil-square-o"
                            aria-hidden="true"
                          ></i>
                          {/* Edit */}
                        </button>
                      </div>
                    </div>
                    <div class="col-8 mt-4">
                      <p>
                        <strong> Name </strong> :-{" "}
                        <strong style={{ marginLeft: "15px" }}>
                          {profile?.fullName}{" "}
                        </strong>{" "}
                      </p>
                      <p>
                        <strong style={{ width: "90px" }}> Rank </strong>:-{" "}
                        <strong style={{ marginLeft: "19px" }}>
                          {" "}
                          {profile?.status == 0
                            ? "FASHION CONSULTANT"
                            : profile?.status == 1
                            ? "FRANCHISE"
                            : profile?.status == 2
                            ? "BARON"
                            : profile?.status == 3
                            ? "COUNT"
                            : profile?.status == 4
                            ? "MARQUISE"
                            : profile?.status == 5
                            ? "DUKE"
                            : profile?.status == 5
                            ? "ARCH DUKE"
                            : " "}{" "}
                        </strong>
                      </p>
                      <p>
                        <strong> FCID </strong> :-{" "}
                        <strong style={{ marginLeft: "23px" }}>
                          {profile?.referId}{" "}
                        </strong>{" "}
                      </p>

                      <p>
                        <strong>Joining</strong> :
                        <strong style={{ marginLeft: "15px" }}>
                          {moment(get_buss_pro_1?.createdAt).format(
                            "DD MMM yyyy"
                          )}{" "}
                        </strong>
                      </p>
                      <p>
                        <strong>E-Kyc</strong> :
                        <strong style={{ marginLeft: "27px" }}>
                          {get_buss_pro_1?.kycStatus}{" "}
                        </strong>
                      </p>
                    </div>
                   
                  </div>
                </div>

                  <div className="cardProFile1">


                  
                  <div class="row">
                  <div class="col-sm-3 ">
                      <p>
                        <strong style={{ width: "50px" }}>
                          Sponsor Details
                        </strong>{" "}
                        : -
                      </p>
                      <p>
                        <strong style={{ width: "50px" }}>Id</strong> :
                        <span style={{ marginLeft: "10px" }}>
                          {get_buss_pro_1?.referByuserId?.referId}{" "}
                        </span>
                      </p>
                      <p>
                        <strong style={{ width: "50px" }}>Name</strong> :
                        <span style={{ marginLeft: "10px" }}>
                          {get_buss_pro_1?.referByuserId?.fullName}{" "}
                        </span>
                      </p>
                    </div>
                   
                  </div>
</div>

                </div>
              </div>
            </div>

            <div class="container mt-5 prm ram">
              {/* <ul class="nav nav-tabs">
                <li class="nav-item">
                  <a
                    class={
                      editTab == "contact" ? "nav-link active" : "nav-link"
                    }
                    onClick={() => {
                      seteditTab("contact");
                    }}
                    aria-current="page"
                    href="#"
                  >
                    Contact
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class={
                      editTab == "address" ? "nav-link active" : "nav-link"
                    }
                    onClick={() => {
                      seteditTab("address");
                    }}
                    href="#"
                  >
                    Address
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class={editTab == "bank" ? "nav-link active" : "nav-link"}
                    onClick={() => {
                      seteditTab("bank");
                    }}
                    href="#"
                  >
                    KYC
                  </a>
                </li>
                 
                <li class="nav-item">
                  <a
                    class={
                      editTab == "nominee" ? "nav-link active" : "nav-link"
                    }
                    onClick={() => {
                      seteditTab("nominee");
                    }}
                    href="#"
                  >
                    Nominee
                  </a>
                </li>
              </ul> */}

              <div className="ListBox">
                <div
                  className="row"
                  onClick={() => {
                    seteditTab(editTab == "contact" ? "": "contact");
                  }}
                >
                  <div className="col-10">
                    <h4 style={{ color: "#000000" }}>Contact</h4>
                  </div>
                  <div className="col-2">
                    {editTab == "contact" ? (
                      <i class="fa fa-arrow-up" aria-hidden="true"></i>
                    ) : (
                      <i class="fa fa-arrow-down" aria-hidden="true"></i>
                    )}
                  </div>
                </div>
              </div>
              {editTab == "contact" && (
                <div className="contecttest p-2">
                  <div className="card-body apptbs">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            {" "}
                            <p
                              style={{
                                display: "inline-block",
                                width: "110px",
                                marginRight: "10px",
                              }}
                            >
                              Mobile:
                            </p>
                          </td>
                          <td>
                            <h4
                              style={{
                                display: "inline-block",
                                // width: "110px",
                                // marginRight: "10px",
                              }}
                            >
                              {get_buss_pro_1?.phone}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <p
                              style={{
                                display: "inline-block",
                                width: "110px",
                                marginRight: "10px",
                              }}
                            >
                              Father Name:
                            </p>
                          </td>
                          <td>
                            <h4
                              style={{
                                display: "inline-block",
                                // width: "110px",
                                // marginRight: "10px",
                              }}
                            >
                              {get_buss_pro_1?.father_name}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <p
                              style={{
                                display: "inline-block",
                                width: "110px",
                                marginRight: "10px",
                              }}
                            >
                              Marital Status:
                            </p>
                          </td>
                          <td>
                            <h4
                              style={{
                                display: "inline-block",
                                // width: "110px",
                                // marginRight: "10px",
                              }}
                            >
                              {get_buss_pro_1?.marital}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <p
                              style={{
                                display: "inline-block",
                                width: "110px",
                                marginRight: "10px",
                              }}
                            >
                              Email:
                            </p>
                          </td>
                          <td>
                            <h4
                              style={{
                                display: "inline-block",
                                // width: "110px",
                                // marginRight: "10px",
                              }}
                            >
                              {get_buss_pro_1?.email}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <p
                              style={{
                                display: "inline-block",
                                width: "110px",
                                marginRight: "10px",
                              }}
                            >
                              gender:
                            </p>
                          </td>
                          <td>
                            <h4
                              style={{
                                display: "inline-block",
                                // width: "110px",
                                // marginRight: "10px",
                              }}
                            >
                              {get_buss_pro_1?.gender}
                            </h4>
                          </td>
                        </tr>
                       
                      </tbody>
                    </table>
                  </div>

                  <button
                    class="save"
                    onClick={() => {
                      handleShow4();
                    }}
                  >
                    {" "}
                    Update
                  </button>
                  <button
                    class="save"
                    onClick={() => {
                      handleShowchangePasswordBoxShow();
                    }}
                  >
                    {" "}
                    Change Password
                  </button>
                </div>
              )}
              <div className="ListBox">
                <div
                  className="row"
                  onClick={() => {
                    seteditTab(editTab == "address" ?"": "address");
                  }}
                >
                  <div className="col-10">
                    <h4 style={{ color: "#000000" }}>Address</h4>
                  </div>
                  <div className="col-2">
                    {editTab == "address" ? (
                      <i class="fa fa-arrow-up" aria-hidden="true"></i>
                    ) : (
                      <i class="fa fa-arrow-down" aria-hidden="true"></i>
                    )}
                  </div>
                </div>
              </div>
              {editTab == "address" && (
                <div className="contecttest p-2">
                  <div>
                    <p
                      style={{
                        display: "inline-block",
                        width: "110px",
                        // marginRight: "10px",
                      }}
                    >
                      Address :
                    </p>
                    <h4
                      style={{
                        display: "inline-block",
                        // width: "110px",
                        // marginRight: "10px",
                      }}
                    >
                      {get_buss_pro_1?.address1}
                    </h4>
                  </div>

                  <div>
                    <p
                      style={{
                        display: "inline-block",
                        width: "110px",
                        // marginRight: "10px",
                      }}
                    >
                      City:
                    </p>
                    <h4
                      style={{
                        display: "inline-block",
                        // width: "110px",
                        // marginRight: "10px",
                      }}
                    >
                      {get_buss_pro_1?.city}
                    </h4>
                  </div>
                  <div>
                    <p
                      style={{
                        display: "inline-block",
                        width: "110px",
                        // marginRight: "10px",
                      }}
                    >
                      Pincode :
                    </p>
                    <h4
                      style={{
                        display: "inline-block",
                        // width: "110px",
                        // marginRight: "10px",
                      }}
                    >
                      {get_buss_pro_1?.zip}
                    </h4>
                  </div>

                  <div>
                    <p
                      style={{
                        display: "inline-block",
                        width: "110px",
                        // marginRight: "10px",
                      }}
                    >
                      State :
                    </p>
                    <h4
                      style={{
                        display: "inline-block",
                        // width: "110px",
                        // marginRight: "10px",
                      }}
                    >
                      {get_buss_pro_1?.state}
                    </h4>
                  </div>
                  <button
                    class="save"
                    onClick={(e) => {
                      handleShow(e);
                    }}
                  >
                    {" "}
                    Update
                  </button>
                </div>
              )}

              <div className="ListBox">
                <div
                  className="row"
                  onClick={() => {
                    seteditTab(editTab == "nominee" ? "":"nominee" );
                  }}
                >
                  <div className="col-10">
                    <h4 style={{ color: "#000000" }}>Nominee</h4>
                  </div>
                  <div className="col-2">
                    {editTab == "nominee" ? (
                      <i class="fa fa-arrow-up" aria-hidden="true"></i>
                    ) : (
                      <i class="fa fa-arrow-down" aria-hidden="true"></i>
                    )}
                  </div>
                </div>
              </div>
              {editTab == "nominee" && (
                <div className="contecttest p-2">
                  <div>
                    <p
                      style={{
                        display: "inline-block",
                        width: "110px",
                        marginRight: "10px",
                      }}
                    >
                      Nominee Name:
                    </p>
                    <h4  style={{
                        display: "inline-block",
                        // width: "110px",
                        // marginRight: "10px",
                      }}>{get_buss_pro_1?.kyc_id?.nname}</h4>
                  </div>

                  <div>
                    <p
                      style={{
                        display: "inline-block",
                        width: "110px",
                        marginRight: "10px",
                      }}
                    >
                      Address :
                    </p>
                    <h4  style={{
                        display: "inline-block",
                        // width: "110px",
                        // marginRight: "10px",
                      }}>{get_buss_pro_1?.kyc_id?.naddress}</h4>
                  </div>
                  <div>
                    <p
                      style={{
                        display: "inline-block",
                        width: "110px",
                        marginRight: "10px",
                      }}
                    >
                      Relation :
                    </p>
                    <h4  style={{
                        display: "inline-block",
                        // width: "110px",
                        // marginRight: "10px",
                      }}>{get_buss_pro_1?.kyc_id?.nrealtion}</h4>
                  </div>
                  <div>
                    <p
                      style={{
                        display: "inline-block",
                        width: "110px",
                        marginRight: "10px",
                      }}
                    >
                      mobile :
                    </p>
                    <h4  style={{
                        display: "inline-block",
                        // width: "110px",
                        // marginRight: "10px",
                      }}>{get_buss_pro_1?.kyc_id?.nmobile}</h4>
                  </div>

                  <button
                    class="save"
                    onClick={() => {
                      handleShow3();
                    }}
                  >
                    {" "}
                    Update
                  </button>
                </div>
              )}
              <div className="ListBox">
                <div
                  className="row"
                  onClick={() => {
                    seteditTab(editTab == "bank" ? "": "bank");
                  }}
                >
                  <div className="col-10">
                    <h4 style={{ color: "#000000" }}>KYC</h4>
                  </div>
                  <div className="col-2">
                    {editTab == "bank" ? (
                      <i class="fa fa-arrow-up" aria-hidden="true"></i>
                    ) : (
                      <i class="fa fa-arrow-down" aria-hidden="true"></i>
                    )}
                  </div>
                </div>
              </div>
              {editTab == "kyc" && (
                <div className="contecttest p-2">
                  <div class="row">
                    <div class="col-xl-4 col-sm-4 col-12 ">
                      <div>
                        <p
                          style={{
                            display: "inline-block",
                            // width: "110px",
                            marginRight: "10px",
                          }}
                        >
                          Aadhar Card Number :
                        </p>
                        <h4  style={{
                        display: "inline-block",
                        // width: "110px",
                        // marginRight: "10px",
                      }}> {get_buss_pro_1?.kyc_id?.aadhar_card}</h4>
                      </div>
                    </div>
                  </div>

                  <p
                    style={{
                      display: "inline-block",
                      // width: "110px",
                      marginRight: "10px",
                    }}
                  >
                    Aadhar Card Photo * :
                  </p>
                  <div class="row mt-4">
                    <div class="col-xl-4 col-sm-4 col-12 ">
                      <div class="pixDiv">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            height: "140px",
                          }}
                        >
                          <img
                            id="img-responsive postImage"
                            class="img-responsive postImage"
                            src={
                              get_buss_pro_1?.kyc_id?.aadharfrontImage
                                ? URL.API_BASE_URL +
                                  get_buss_pro_1?.kyc_id?.aadharfrontImage
                                : "/missing.png"
                            }
                            alt="Loading"
                            style={{ width: "100%", height: "80%" }}
                          />
                        </div>
                        <div class="pixDocumentTitle">Aadhar Card front</div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-sm-4 col-12 ">
                      <div class="pixDiv">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            height: "140px",
                          }}
                        >
                          <img
                            id="img-responsive postImage"
                            class="img-responsive postImage"
                            src={
                              get_buss_pro_1?.kyc_id?.aadharbacktImage
                                ? URL.API_BASE_URL +
                                  get_buss_pro_1?.kyc_id?.aadharbacktImage
                                : "/missing.png"
                            }
                            alt="Loading"
                            style={{ width: "100%", height: "80%" }}
                          />
                        </div>
                        <div class="pixDocumentTitle">Aadhar Card Back</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-sm-4 col-12 ">
                      <div>
                        <p
                          style={{
                            display: "inline-block",
                            width: "110px",
                            marginRight: "10px",
                          }}
                        >
                          PAN Card Number :
                        </p>
                        <span> {get_buss_pro_1?.kyc_id?.pan_card}</span>
                      </div>
                    </div>
                  </div>

                  <h4
                    style={{
                      display: "inline-block",
                      width: "110px",
                      marginRight: "10px",
                    }}
                  >
                    PAN Card Photo * :
                  </h4>
                  <div class="row mt-4">
                    <div class="col-xl-4 col-sm-4 col-12 ">
                      <div class="pixDiv">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            height: "140px",
                          }}
                        >
                          <img
                            id="img-responsive postImage"
                            class="img-responsive postImage"
                            src={
                              get_buss_pro_1?.kyc_id?.pancardImage
                                ? URL.API_BASE_URL +
                                  get_buss_pro_1?.kyc_id?.pancardImage
                                : "/missing.png"
                            }
                            alt="Loading"
                            style={{ width: "100%", height: "80%" }}
                          />
                        </div>
                        <div class="pixDocumentTitle">Pan card Cards</div>
                      </div>
                    </div>
                  </div>

                  <button
                    class="save"
                    onClick={() => {
                      handleShow2();
                    }}
                  >
                    Update
                  </button>
                </div>
              )}
              {/* <div className="ListBox">
                <div className="row">
                  <div className="col-10">
                    <h6>Bank</h6>
                  </div>
                  <div className="col-2">
                  {editTab == "bank" ? 
                  <i class="fa fa-arrow-up" aria-hidden="true"></i>:
                    <i class="fa fa-arrow-down" aria-hidden="true"></i>  }
                  </div>
                </div>
              </div> */}
              {editTab == "bank" && (
                <div className="contecttest p-2">
                  <div>
                    <p
                      style={{
                        display: "inline-block",
                        width: "220px",
                        marginRight: "10px",
                      }}
                    >
                      Account Holder Name :
                    </p>
                    <h4  style={{
                        display: "inline-block",
                        // width: "110px",
                        // marginRight: "10px",
                      }}>{get_buss_pro_1?.kyc_id?.father_name}</h4>
                  </div>
                  <div>
                    <p
                      style={{
                        display: "inline-block",
                        width: "220px",
                        marginRight: "10px",
                      }}
                    >
                      IFSC Code :
                    </p>
                    <h4  style={{
                        display: "inline-block",
                        // width: "110px",
                        // marginRight: "10px",
                      }}>{get_buss_pro_1?.kyc_id?.ifsc}</h4>
                  </div>
                  <div>
                    <p
                      style={{
                        display: "inline-block",
                        width: "220px",
                        marginRight: "10px",
                      }}
                    >
                      Bank Name :
                    </p>
                    <h4  style={{
                        display: "inline-block",
                        // width: "110px",
                        // marginRight: "10px",
                      }}>{get_buss_pro_1?.kyc_id?.bank_name}</h4>
                  </div>
                  <div>
                    <p
                      style={{
                        display: "inline-block",
                        width: "220px",
                        marginRight: "10px",
                      }}
                    >
                      Branch Name :
                    </p>
                    <h4  style={{
                        display: "inline-block",
                        // width: "110px",
                        // marginRight: "10px",
                      }}>{get_buss_pro_1?.kyc_id?.branch_name}</h4>
                  </div>
                  <div>
                    <p
                      style={{
                        display: "inline-block",
                        width: "220px",
                        marginRight: "10px",
                      }}
                    >
                      Account Number :
                    </p>
                    <h4  style={{
                        display: "inline-block",
                        // width: "110px",
                        // marginRight: "10px",
                      }}>{get_buss_pro_1?.kyc_id?.acc_no}</h4>
                  </div>

                  <h2>Bank passbook * :</h2>
                  <div class="row mt-4">
                    <div class="col-xl-4 col-sm-4 col-12 ">
                      <div class="pixDiv">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            height: "140px",
                          }}
                        >
                          <img
                            id="img-responsive postImage"
                            class="img-responsive postImage"
                            src={
                              get_buss_pro_1?.kyc_id?.bankpassbook
                                ? URL.API_BASE_URL +
                                  get_buss_pro_1?.kyc_id?.bankpassbook
                                : "/missing.png"
                            }
                            alt="Loading"
                            style={{ width: "100%", height: "80%" }}
                          />
                        </div>
                        <div class="pixDocumentTitle">bank passbook</div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-4 col-sm-4 col-12 ">
                      <div>
                        <p
                          style={{
                            display: "inline-block",
                            // width: "110px",
                            marginRight: "10px",
                          }}
                        >
                          Aadhar Card Number :
                        </p>
                        <h4  style={{
                        display: "inline-block",
                        // width: "110px",
                        // marginRight: "10px",
                      }}> {get_buss_pro_1?.kyc_id?.aadhar_card}</h4>
                      </div>
                    </div>
                  </div>

                  <h4
                    style={{
                      display: "inline-block",
                      // width: "110px",
                      marginRight: "10px",
                    }}
                  >
                    Aadhar Card Photo * :
                  </h4>
                  <div class="row mt-4">
                    <div class="col-xl-4 col-sm-4 col-12 ">
                      <div class="pixDiv">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            height: "140px",
                          }}
                        >
                          <img
                            id="img-responsive postImage"
                            class="img-responsive postImage"
                            src={
                              get_buss_pro_1?.kyc_id?.aadharfrontImage
                                ? URL.API_BASE_URL +
                                  get_buss_pro_1?.kyc_id?.aadharfrontImage
                                : "/missing.png"
                            }
                            alt="Loading"
                            style={{ width: "100%", height: "80%" }}
                          />
                        </div>
                        <div class="pixDocumentTitle">Aadhar Card front</div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-sm-4 col-12 ">
                      <div class="pixDiv">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            height: "140px",
                          }}
                        >
                          <img
                            id="img-responsive postImage"
                            class="img-responsive postImage"
                            src={
                              get_buss_pro_1?.kyc_id?.aadharbacktImage
                                ? URL.API_BASE_URL +
                                  get_buss_pro_1?.kyc_id?.aadharbacktImage
                                : "/missing.png"
                            }
                            alt="Loading"
                            style={{ width: "100%", height: "80%" }}
                          />
                        </div>
                        <div class="pixDocumentTitle">Aadhar Card Back</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-sm-4 col-12 ">
                      <div>
                        <p
                          style={{
                            display: "inline-block",
                            width: "110px",
                            marginRight: "10px",
                          }}
                        >
                          PAN Card Number :
                        </p>
                        <h4  style={{
                        display: "inline-block",
                        // width: "110px",
                        // marginRight: "10px",
                      }}> {get_buss_pro_1?.kyc_id?.pan_card}</h4>
                      </div>
                    </div>
                  </div>

                  <h4
                    style={{
                      display: "inline-block",
                      width: "110px",
                      marginRight: "10px",
                    }}
                  >
                    PAN Card Photo * :
                  </h4>
                  <div class="row mt-4">
                    <div class="col-xl-4 col-sm-4 col-12 ">
                      <div class="pixDiv">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            height: "140px",
                          }}
                        >
                          <img
                            id="img-responsive postImage"
                            class="img-responsive postImage"
                            src={
                              get_buss_pro_1?.kyc_id?.pancardImage
                                ? URL.API_BASE_URL +
                                  get_buss_pro_1?.kyc_id?.pancardImage
                                : "/missing.png"
                            }
                            alt="Loading"
                            style={{ width: "100%", height: "80%" }}
                          />
                        </div>
                        <div class="pixDocumentTitle">Pan card Cards</div>
                      </div>
                    </div>
                  </div>
                  {profile?.kycStatus !== "Approved" && (
                    <button
                      class="save"
                      onClick={() => {
                        handleShow1();
                      }}
                    >
                      {" "}
                      Update
                    </button>
                  )}
                </div>
              )}


              <div className="ListBox">
                <div
                  className="row"
                  onClick={() => {
                    seteditTab(editTab == "idcard" ? "": "idcard");
                  }}
                >
                  <div className="col-10">
                    <h4 style={{ color: "#000000" }}>Id Card </h4>
                  </div>
                  <div className="col-2">
                    {editTab == "idcard" ? (
                      <i class="fa fa-arrow-up" aria-hidden="true"></i>
                    ) : (
                      <i class="fa fa-arrow-down" aria-hidden="true"></i>
                    )}
                  </div>
                </div>
              </div>
              {editTab == "idcard" && (
                <div className="contecttest p-2">
                <Iidcard2 />
                </div>
              )}
            </div>
            <div class="container"></div>

           
          </section>

          {/* <Footerr /> */}
        </main>
      </div>
    </div>
  );
}

export default ProfileApp;

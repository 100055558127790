import logo from "./logo.svg";
import "./App.css";
import AppRoutes from "./Routes/Routes";
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "./Redux/common/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-phone-number-input/style.css";
import "react-spring-bottom-sheet/dist/style.css";
import "aos/dist/aos.css";
import AOS from "aos";
import { allapiAction } from "./Redux/common/action";
// const ReloadOnScreenChange = () => {
//   const location = useLocation();

//   useEffect(() => {
//     window.location.reload();
//   }, [location.pathname]);

//   return null;
// };

function App() {
  const location = useLocation();
  console.log(location);
  console.log(location.pathname);
  const pathParts = location.pathname.split("/"); // This will create ["", "home", "profile"]

  // Find the index of "home" in the pathParts array
  const homeIndex = pathParts.indexOf("home");
  console.log(pathParts);
  console.log(homeIndex);
  useEffect(() => {
    AOS.init();
  }, []);

  const theme_man = useSelector((state) =>
    state?.allapi?.theme_man ? state?.allapi?.theme_man : ""
  );
  // fd9b57
  const [first, setfirst] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    // localStorage.setItem("access_token_vendor", access_token);
    const token = localStorage.getItem("theme");
    dispatch(allapiAction.thememangment(token));
    // setfirst(token);
    return () => {};
  }, []);
  return (
    <div className={homeIndex >= 0 && theme_man == "drak" ? "thememan" : ""}>
      {/* <Home /> */}
      {/* <div className={first == "drak" ? "thememan":""}> */}

      <AppRoutes />

      {/* <ReloadOnScreenChange/> */}

      <ToastContainer theme="colored" />
      {/* </Provider> */}
    </div>
  );
}

export default App;

import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
// import { allapiAction } from "../../../Redux/common/action";
// import { URL } from "../../../Redux/common/url";
// import Footer from "../Layout/Footer";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import moment from "moment";

import ImageUploading from "react-images-uploading";
import { toast } from "react-toastify";
// import dateFormat, { masks } from "dateformat";

import ReactToPrint from "react-to-print";
// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import Header2 from "../../../component/sidebar/Header2";
// import Sidebar from "../../../component/sidebar/Sidebar";
// import { ExportToExcel } from "../../../component/ExportToExcel";
import { allapiAction } from "../../Redux/common/action";
import { ExportToExcel } from "../ExportToExcel";
import Sidebar from "../Layout/Sidebar";
import HeaderAccount from "../Layout/HeaderAccount";
import Chart from "react-google-charts";
import { Link, useLocation, useNavigate } from "react-router-dom";
function AllTeamApp2() {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // getsellerProductList

  const get_seller_product_list = useSelector((state) =>
    state?.allapi?.user_all_team ? state?.allapi?.user_all_team : {}
  );
  const get_invoice = useSelector((state) =>
    state?.allapi?.get_invoice ? state?.allapi?.get_invoice : {}
  );
  const order_details = useSelector((state) =>
    state?.allapi?.order_details ? state?.allapi?.order_details : {}
  );
  const user_all_team_active = useSelector((state) =>
    state?.allapi?.user_all_team_active
      ? state?.allapi?.user_all_team_active
      : {}
  );

  console.log(user_all_team_active);

  const all_sub_sub_categ_List = useSelector((state) =>
    state?.allapi?.all_sub_sub_categ_List
      ? state?.allapi?.all_sub_sub_categ_List
      : []
  );
  const get_footer_all_data = useSelector((state) =>
    state?.allapi?.get_footer_all_data ? state?.allapi?.get_footer_all_data : {}
  );

  useEffect(() => {
    // dispatch(allapiAction.sellerDirectTeam(1));
    dispatch(allapiAction.userallteamlist({ pageNumber: 1 }));
    dispatch(allapiAction.userallteamlistActive({ pageNumber: 1 }));
    // dispatch(allapiAction.getInvoice({}));
    // dispatch(allapiAction.footerPagepageListetPu({}));

    return () => {};
  }, []);

  const get_buss_pro = useSelector((state) =>
    state?.allapi?.get_buss_pro ? state?.allapi?.get_buss_pro : {}
  );
  const get_buss_pro_1 = useSelector((state) =>
    state?.allapi?.get_buss_pro_1 ? state?.allapi?.get_buss_pro_1 : {}
  );

  console.log(get_buss_pro_1);

  const gendletoexportdata = async () => {
    // userallteamlistexportex

    const statusdata = statuskey
      ? { status: statuskey, pageNumber: 1 }
      : { pageNumber: 1, status: "" };
    const response = await dispatch(
      allapiAction.userallteamlistexportex(statusdata)
    );

    console.log(response);

    if (response?.success) {
      const finaldata = response?.data?.map((data) => {
        const userrank =
          data?.status == 0
            ? "FASHION CONSULTANT"
            : data?.status == 1
            ? "FRANCHISE"
            : data?.status == 2
            ? "BARON"
            : data?.status == 3
            ? "COUNT"
            : data?.status == 4
            ? "MARQUISE"
            : data?.status == 5
            ? "DUKE"
            : data?.status == 6
            ? "ARCH DUKE"
            : "";

        const fn = {
          Name: data?.fullName,
          UserId: data?.referId,
          Sponsorid: data?.referBy,
          Rank: userrank,
          RegDate: moment(data?.createdAt).format("DD MMM yyyy"),
        };
        return fn;
      });

      console.log(finaldata);

      ExportToExcel(finaldata, get_buss_pro_1?.referId + " All Teams");
    } else {
    }
  };
  // useEffect(() => {
  //   dispatch(allapiAction.getsubsubcate({}));
  //   return () => {};
  // }, []);

  // const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const headers = [
    { name: "Route Name", field: "routeName", sortable: true },
    { name: "From", field: "from", sortable: true },
    { name: "To", field: "to", sortable: false },
    { name: "Action", field: "action", sortable: false },
  ];

  const comments = get_seller_product_list?.docs
    ? get_seller_product_list?.docs
    : [];

  const pageCount = get_seller_product_list?.totalPages
    ? get_seller_product_list?.totalPages
    : 0;

  const commentsData = useMemo(() => {
    let computedComments = comments;

    // if (search) {
    //   computedComments = computedComments.filter(
    //     (comment) =>
    //       comment.message.toLowerCase().includes(search.toLowerCase()) ||
    //       comment.title.toLowerCase().includes(search.toLowerCase())
    //   );
    // }

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * itemsPerPage,
      (currentPage - 1) * itemsPerPage + itemsPerPage
    );
  }, [comments, currentPage, search, sorting, itemsPerPage]);

  // userallteamlistfilter

  const [key, setkey] = useState("");
  const [statuskey, setstatuskey] = useState("");
  const [page, setpageNo] = useState(1);

  const hendlefilterData = (e) => {
    setstatuskey(e);
    // if (e) {

    const obj = { pageNumber: page, status: e };
    if (key) {
      obj.key = key;
    }

    dispatch(allapiAction.userallteamlistfilter(obj));
    //   dispatch(allapiAction.getsellerProductList(1));
    return () => {};
    // } else {
    //   dispatch(allapiAction.userallteamlist({ pageNumber: page }));
    //   //   dispatch(allapiAction.getsellerProductList(1));
    //   return () => {};
    // }
  };

  const handlePageClick = (event) => {
    // const newOffset = event.selected % (comments.length / 2);
    // setCurrentPage(newOffset + 1);
    // setItemOffset(newOffset * itemsPerPage);

    const data = event?.selected + 1;

    setpageNo(data);
    const obj = {
      pageNumber: data,
    };

    if (statuskey) {
      obj.status = statuskey;
    }
    if (key) {
      obj.key = key;
    }
    // if (!statuskey) {
    //   // dispatch(ShoetoggelAction.getusers(data))
    //   //   dispatch(allapiAction.sellerDirectTeam(data));
    //   dispatch(allapiAction.userallteamlist({ }));
    //   //   dispatch(allapiAction.getsellerProductList(1));
    //   return () => {};
    // } else {
    // dispatch(
    //   ShoetoggelAction.searchUser({ pageNumber: data, key: userSearch })
    // );
    dispatch(allapiAction.userallteamlistfilter(obj));
    return () => {};
    // }
  };

  // image uploder
  const [images, setImages] = React.useState([]);
  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit

    setImages(imageList);
  };

  const [images1, setImages1] = React.useState([]);
  const maxNumber1 = 4;

  const onChange1 = (imageList, addUpdateIndex) => {
    // data for submit

    setImages1(imageList);
  };

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const [selectedOption, setSelectedOption] = useState(null);

  const [subList, setsubList] = useState([]);

  useEffect(() => {
    const dropList = all_sub_sub_categ_List?.map((data, i) => {
      data.value = data?._id;
      data.label = data?.name;

      return data;
    });

    setsubList(dropList);
  }, [all_sub_sub_categ_List]);

  const [roledata, setroledata] = useState({ title: "", tegs: "" });

  const hendleChangerole = (e) => {
    const { name, value } = e.target;
    setroledata({ ...roledata, [name]: value });
  };

  const hendleSearch = (e) => {
    // searchSellerProduct
    setSearch(e);
    setkey(e);

    setpageNo(1);
    const obj = {
      pageNumber: 1,
    };

    if (statuskey) {
      obj.status = statuskey;
    }
    // if (key) {
    obj.key = e;
    // }
    // dispatch(allapiAction.searchSellerProduct(e));
    dispatch(allapiAction.userallteamlistfilter(obj));
    // dispatch(allapiAction.searchSellerProduct({ key: e, page: 1 }));
    return () => {};
  };

  const submitProduct = () => {
    try {
      // const {data: auth} = await login(values.role_name, values.tegs)
      // saveAuth(auth)
      // const {data: user} = await getUserByToken(auth.api_token)
      // dispatch(adminAction.createRoles(values))
      const data = new FormData();

      // data.append("title", imageEditfor);

      // images

      if (images?.length == 0) {
        toast.error("please select feature Image");
      } else if (images1?.length == 0) {
        toast.error("please select  Image");
      } else if (!roledata?.title) {
        toast.error("please enter prodcat name");
      } else if (!roledata?.tegs) {
        toast.error("please enter product teg");
      } else if (!selectedOption) {
        toast.error("please select category ");
      } else {
        // setLoading(true);

        data.append("title", roledata?.title);
        data.append("tegs", roledata?.tegs);
        data.append("categoryName", "test cate");
        data.append("categoryId", selectedOption?._id);
        // data.append('sellerId', selectedOption1?._id)
        data.append("featureImage", images[0]?.file);
        images1?.forEach((file) => {
          data.append("images", file?.file);
        });

        dispatch(allapiAction.createSellerProduct(data));
        setShow(false);
        // setLoading(false);
      }

      // setCurrentUser(user)
    } catch (error) {
      // saveAuth(undefined)
      // setStatus('The login details are incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  };

  // deleteSellerProduct

  const [showDelelt, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);

  const [productID, setproductID] = useState("");

  const hendleModalShow = (e) => {
    setShowDelete(true);
    setproductID(e);

    dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const hendleDelete = () => {
    // deleteProduct
    setShowDelete(false);
    dispatch(allapiAction.deleteSellerProduct({ productId: productID }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const componentRef = useRef();

  const hendleShowOrder = (e) => {
    // getuserOrderDetails

    dispatch(allapiAction.getuserOrderDetails({ _id: e }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  // get_invoice

  const shippingCharge = order_details?.sub_total > 10000 ? 699 : 199;
  console.log(order_details?.orderproductsList);

  const totalSubtotalPricegstdd = order_details?.orderproductsList?.reduce(
    (total, obj) => {
      return total + (obj.sub_total * obj?.gst) / 100;
    },
    0
  );
  // return total +(obj.subtotalprice * obj?.gst) / 100 ;
  // console.log(shippingCharge)
  // console.log(totalSubtotalPricegstdd)

  const totalSubtotalPricegst = Math.floor(totalSubtotalPricegstdd);

  async function getStatus(status) {
    switch (status) {
      case 0:
        return "FASHION CONSULTANT";
      case 1:
        return "FRANCHISE";
      case 2:
        return "BARON";
      case 3:
        return "COUNT";
      case 4:
        return "MARQUISE";
      case 5:
        return "DUKE";
      case 6:
        return "ARCH DUKE";
      default:
        return "";
    }
  }

  const data = [
    ["Status", "Users"],
    [
      "Active",
      user_all_team_active?.ActiveUser ? user_all_team_active?.ActiveUser : 0,
    ],
    [
      "Inactive",
      user_all_team_active?.InactiveUser
        ? user_all_team_active?.InactiveUser
        : 0,
    ],
    // ["Commute", 2],
    // ["Watch TV", 2],
    // ["Sleep", 7],
  ];

  const optionsv = {
    title: "My All Team",
  };

  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  console.log(location.pathname);
  const pathParts = location.pathname.split("/");

  console.log(pathParts);
  return (
    <div>
     
          
          <div className="content-header">
            <div>
              {/* <h2
                className="content-title card-title"
                style={{ fontSize: "14px" }}
              >
                My All Team
              </h2> */}
              {/* <p>Lorem ipsum dolor sit amet.</p> */}
            </div>

            <div>
              {/* <a href="#" className="btn btn-light rounded font-md">Export</a>
                      <a href="#" className="btn btn-light rounded font-md">Import</a> */}
              {/* <a href="#" className="btn btn-primary btn-sm rounded">
              Create new
            </a> */}

              <Button variant="primary" onClick={gendletoexportdata}>
                Export
              </Button>
            </div>
          </div>

          <div
            style={{
              height: "250px",
              position: "relative",
              marginLeft: "45px",
            }}
          >
            <Chart
              chartType="PieChart"
              data={data}
              options={optionsv}
              width={"100%"}
              height={"250px"}
            />
            <div
              style={{
                position: "absolute",
                bottom: "20%",
                left: "-10px",
                // transform: "translate(-50%,-50%)",
              }}
            >
              <p style={{ fontSize: "12px", color: "#000" }}>
                Active Team :<br /> <b>{user_all_team_active?.ActiveUser}</b>
              </p>
            </div>
            <div
              style={{
                position: "absolute",
                bottom: "20%",
                right: "65px",
                // transform: "translate(-50%,-50%)",
              }}
            >
              <p style={{ fontSize: "12px", color: "#000" }}>
                Inactive Team :<br />{" "}
                <b>{user_all_team_active?.InactiveUser}</b>
              </p>
            </div>
          </div>

          <div className="card mb-4">
            <header className="card-header1">
              <div className="row align-items-center">
                <div className="col-12 col-check flex-grow-0">
                  <div className="form-check ms-2">
                    {/* <input className="form-check-input" type="checkbox" value="" /> */}
                  </div>
                </div>
                <div className="col-6   ">
                  <select
                    className="form-control"
                    onChange={(e) => hendlefilterData(e.target.value)}
                    value={statuskey}
                  >
                    <option value="">All </option>
                    <option value={"0"}>Fc</option>
                    <option value={"1"}>Fr</option>
                    <option value={"2"}>Br</option>
                    <option value={"3"}>Ct</option>
                    <option value={"4"}>Mq</option>
                    <option value={"5"}>Duke</option>
                  </select>
                  {/* <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect
                        opacity='0.5'
                        x='17.0365'
                        y='15.1223'
                        width='8.15546'
                        height='2'
                        rx='1'
                        transform='rotate(45 17.0365 15.1223)'
                        fill='currentColor'
                      />
                      <path
                        d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                        fill='currentColor'
                      />
                    </svg>
                  </span> */}
                  {/* <!--end::Svg Icon-->  */}
                  {/* <input
                    type="text"
                    data-kt-permissions-table-filter="search"
                    className="form-control form-control-solid w-250px ps-15"
                    placeholder="Search Product"
                    onChange={(e) => {
                      hendleSearch(e.target.value);
                    }}
                  /> */}
                  {/* <select className="form-select">
                                  <option selected>All category</option>
                                  <option>Electronics</option>
                                  <option>Clothes</option>
                                  <option>Automobile</option>
                              </select> */}
                </div>
                <div className="col-6 ">
                  <input
                    type="text"
                    placeholder="Search ..."
                    className="form-control"
                    onChange={(e) => {
                      hendleSearch(e.target.value);
                    }}
                  />
                </div>
                <div className="col-md-2 col-6">
                  {/* <select className="form-select">
                  <option selected>Status</option>
                  <option>Active</option>
                  <option>Disabled</option>
                  <option>Show all</option>
                </select> */}
                </div>
              </div>
            </header>

            <div className="card-body">
              <table>
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>User Id</th>
                    <th>User Name</th>
                    {/* <th>Phone No</th> */}
                    <th> Rank</th>
                    <th>Reg Date</th>
                    <th>Sponsor id</th>
                  </tr>
                </thead>
                <tbody>
                  {commentsData &&
                    commentsData.map((data, i) => {
                      const nextMonthStart = moment(data?.createdAt)
                        .add(1, "month")
                        .toDate();
                      const formetCahnge =
                        moment(nextMonthStart).format("DD MMM yyyy");
                      console.log(data);

                      const userrank =
                        data?.status == 0
                          ? "FASHION CONSULTANT"
                          : data?.status == 1
                          ? "FRANCHISE"
                          : data?.status == 2
                          ? "BARON"
                          : data?.status == 3
                          ? "COUNT"
                          : data?.status == 4
                          ? "MARQUISE"
                          : data?.status == 5
                          ? "DUKE"
                          : data?.status == 6
                          ? "ARCH DUKE"
                          : "";

                      console.log(userrank);

                      return (
                        <tr key={i}>
                          <td>{(page - 1) * 10 + i + 1}</td>
                          <td>{data?.referId}</td>
                          <td>{data?.fullName}</td>
                          {/* <td>{data?.phone}</td> */}
                          <td>{userrank}</td>
                          {/* <td>{data?.status}</td> */}
                          <td>
                            {moment(data?.createdAt).format("DD MMM yyyy")}
                          </td>
                          <td>{data?.referBy}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>

              {get_seller_product_list?.totalDocs == "0" && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "400px",
                  }}
                >
                  <h3>Record Not found</h3>
                </div>
              )}

              {/*    <article className="itemlist">
              <div className="row align-items-center">
                <div className="col col-check flex-grow-0">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4 col-8 flex-grow-1 col-name">
                  <a className="itemside" href="#">
                    <div className="left">
                      <img
                        src="assets/imgs/items/3.jpg"
                        className="img-sm img-thumbnail"
                        alt="Item"
                      />
                    </div>
                    <div className="info">
                      <h6 className="mb-0">Gortons Beer Battered Fish Fillets</h6>
                    </div>
                  </a>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-price">
                  <span>$76.99</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-status">
                  <span className="badge rounded-pill alert-warning">
                    Archived
                  </span>
                </div>
                <div className="col-lg-1 col-sm-2 col-4 col-date">
                  <span>02.11.2021</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                  <a href="#" className="btn btn-sm font-sm rounded btn-brand">
                    {" "}
                    <i className="material-icons md-edit"></i> Edit{" "}
                  </a>
                  <a href="#" className="btn btn-sm font-sm btn-light rounded">
                    {" "}
                    <i className="material-icons md-delete_forever"></i> Delete{" "}
                  </a>
                </div>
              </div>
            </article>

            <article className="itemlist">
              <div className="row align-items-center">
                <div className="col col-check flex-grow-0">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4 col-8 flex-grow-1 col-name">
                  <a className="itemside" href="#">
                    <div className="left">
                      <img
                        src="assets/imgs/items/4.jpg"
                        className="img-sm img-thumbnail"
                        alt="Item"
                      />
                    </div>
                    <div className="info">
                      <h6 className="mb-0">
                        Foster Farms Takeout Crispy Classic Buffalo
                      </h6>
                    </div>
                  </a>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-price">
                  <span>$18.00</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-status">
                  <span className="badge rounded-pill alert-success">Active</span>
                </div>
                <div className="col-lg-1 col-sm-2 col-4 col-date">
                  <span>02.11.2021</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                  <a href="#" className="btn btn-sm font-sm rounded btn-brand">
                    {" "}
                    <i className="material-icons md-edit"></i> Edit{" "}
                  </a>
                  <a href="#" className="btn btn-sm font-sm btn-light rounded">
                    {" "}
                    <i className="material-icons md-delete_forever"></i> Delete{" "}
                  </a>
                </div>
              </div>
            </article>

            <article className="itemlist">
              <div className="row align-items-center">
                <div className="col col-check flex-grow-0">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4 col-8 flex-grow-1 col-name">
                  <a className="itemside" href="#">
                    <div className="left">
                      <img
                        src="assets/imgs/items/3.jpg"
                        className="img-sm img-thumbnail"
                        alt="Item"
                      />
                    </div>
                    <div className="info">
                      <h6 className="mb-0">
                        Blue Diamond Almonds Lightly Salted
                      </h6>
                    </div>
                  </a>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-price">
                  <span>$76.99</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-status">
                  <span className="badge rounded-pill alert-danger">
                    Disabled
                  </span>
                </div>
                <div className="col-lg-1 col-sm-2 col-4 col-date">
                  <span>02.11.2021</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                  <a href="#" className="btn btn-sm font-sm rounded btn-brand">
                    {" "}
                    <i className="material-icons md-edit"></i> Edit{" "}
                  </a>
                  <a href="#" className="btn btn-sm font-sm btn-light rounded">
                    {" "}
                    <i className="material-icons md-delete_forever"></i> Delete{" "}
                  </a>
                </div>
              </div>
            </article>

            <article className="itemlist">
              <div className="row align-items-center">
                <div className="col col-check flex-grow-0">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4 col-8 flex-grow-1 col-name">
                  <a className="itemside" href="#">
                    <div className="left">
                      <img
                        src="assets/imgs/items/5.jpg"
                        className="img-sm img-thumbnail"
                        alt="Item"
                      />
                    </div>
                    <div className="info">
                      <h6 className="mb-0">
                        Chobani Complete Vanilla Greek Yogurt
                      </h6>
                    </div>
                  </a>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-price">
                  <span>$18.00</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-status">
                  <span className="badge rounded-pill alert-warning">
                    Archived
                  </span>
                </div>
                <div className="col-lg-1 col-sm-2 col-4 col-date">
                  <span>02.11.2021</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                  <a href="#" className="btn btn-sm font-sm rounded btn-brand">
                    {" "}
                    <i className="material-icons md-edit"></i> Edit{" "}
                  </a>
                  <a href="#" className="btn btn-sm font-sm btn-light rounded">
                    {" "}
                    <i className="material-icons md-delete_forever"></i> Delete{" "}
                  </a>
                </div>
              </div>
            </article>

            <article className="itemlist">
              <div className="row align-items-center">
                <div className="col col-check flex-grow-0">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4 col-8 flex-grow-1 col-name">
                  <a className="itemside" href="#">
                    <div className="left">
                      <img
                        src="assets/imgs/items/6.jpg"
                        className="img-sm img-thumbnail"
                        alt="Item"
                      />
                    </div>
                    <div className="info">
                      <h6 className="mb-0">Canada Dry Ginger Ale 2 L Bottle</h6>
                    </div>
                  </a>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-price">
                  <span>$76.99</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-status">
                  <span className="badge rounded-pill alert-success">Active</span>
                </div>
                <div className="col-lg-1 col-sm-2 col-4 col-date">
                  <span>02.11.2021</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                  <a href="#" className="btn btn-sm font-sm rounded btn-brand">
                    {" "}
                    <i className="material-icons md-edit"></i> Edit{" "}
                  </a>
                  <a href="#" className="btn btn-sm font-sm btn-light rounded">
                    {" "}
                    <i className="material-icons md-delete_forever"></i> Delete{" "}
                  </a>
                </div>
              </div>
            </article>

            <article className="itemlist">
              <div className="row align-items-center">
                <div className="col col-check flex-grow-0">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4 col-8 flex-grow-1 col-name">
                  <a className="itemside" href="#">
                    <div className="left">
                      <img
                        src="assets/imgs/items/4.jpg"
                        className="img-sm img-thumbnail"
                        alt="Item"
                      />
                    </div>
                    <div className="info">
                      <h6 className="mb-0">Gortons Beer Battered Fish Fillets</h6>
                    </div>
                  </a>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-price">
                  <span>$18.00</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-status">
                  <span className="badge rounded-pill alert-success">Active</span>
                </div>
                <div className="col-lg-1 col-sm-2 col-4 col-date">
                  <span>02.11.2021</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                  <a href="#" className="btn btn-sm font-sm rounded btn-brand">
                    {" "}
                    <i className="material-icons md-edit"></i> Edit{" "}
                  </a>
                  <a href="#" className="btn btn-sm font-sm btn-light rounded">
                    {" "}
                    <i className="material-icons md-delete_forever"></i> Delete{" "}
                  </a>
                </div>
              </div>
            </article>

            <article className="itemlist">
              <div className="row align-items-center">
                <div className="col col-check flex-grow-0">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4 col-8 flex-grow-1 col-name">
                  <a className="itemside" href="#">
                    <div className="left">
                      <img
                        src="assets/imgs/items/3.jpg"
                        className="img-sm img-thumbnail"
                        alt="Item"
                      />
                    </div>
                    <div className="info">
                      <h6 className="mb-0">Gortons Beer Battered Fish Fillets</h6>
                    </div>
                  </a>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-price">
                  <span>$76.99</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-status">
                  <span className="badge rounded-pill alert-success">Active</span>
                </div>
                <div className="col-lg-1 col-sm-2 col-4 col-date">
                  <span>02.11.2021</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                  <a href="#" className="btn btn-sm font-sm rounded btn-brand">
                    {" "}
                    <i className="material-icons md-edit"></i> Edit{" "}
                  </a>
                  <a href="#" className="btn btn-sm font-sm btn-light rounded">
                    {" "}
                    <i className="material-icons md-delete_forever"></i> Delete{" "}
                  </a>
                </div>
              </div>
            </article>

            <article className="itemlist">
              <div className="row align-items-center">
                <div className="col col-check flex-grow-0">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-4 col-8 flex-grow-1 col-name">
                  <a className="itemside" href="#">
                    <div className="left">
                      <img
                        src="assets/imgs/items/4.jpg"
                        className="img-sm img-thumbnail"
                        alt="Item"
                      />
                    </div>
                    <div className="info">
                      <h6 className="mb-0">Haagen-Dazs Caramel Cone Ice</h6>
                    </div>
                  </a>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-price">
                  <span>$180.99</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-status">
                  <span className="badge rounded-pill alert-success">Active</span>
                </div>
                <div className="col-lg-1 col-sm-2 col-4 col-date">
                  <span>02.11.2021</span>
                </div>
                <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                  <a href="#" className="btn btn-sm font-sm rounded btn-brand">
                    {" "}
                    <i className="material-icons md-edit"></i> Edit{" "}
                  </a>
                  <a href="#" className="btn btn-sm font-sm btn-light rounded">
                    {" "}
                    <i className="material-icons md-delete_forever"></i> Delete{" "}
                  </a>
                </div>
              </div>
            </article> */}
            </div>
          </div>

          <div className="pagination-area mt-30 mb-50">
            <ReactPaginate
              breakLabel="..."
              nextLabel=" >"
              className="dataTables_paginate paging_simple_numbers category"
              onPageChange={handlePageClick}
              pageRangeDisplayed={2}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
            />
            {/* <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-start">
              <li className="page-item active">
                <a className="page-link" href="#">
                  01
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  02
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  03
                </a>
              </li>
              <li className="page-item">
                <a className="page-link dot" href="#">
                  ...
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  16
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  <i className="material-icons md-chevron_right"></i>
                </a>
              </li>
            </ul>
          </nav> */}
          </div>
          
        </div>
  );
}

export default AllTeamApp2;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { URL } from "../../Redux/common/url";
import { allapiAction } from "../../Redux/common/action";
function AdSlider() {
  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  const ad_slider_list = useSelector((state) =>
    state?.allapi?.ad_slider_list ? state?.allapi?.ad_slider_list : []
  );
  const dispatch = useDispatch();
   useEffect(() => {
      dispatch(allapiAction.getAdsliderList({}));
      // dispatch(allapiAction.getOneUserPerCountry());
      return () => {};
    }, []);
  return (
    <div>
      {
        <Slider {...settings}>
          {ad_slider_list &&
            ad_slider_list?.map((data, i) => {
              return (
                <div key={i}>
                  <img
                    src={URL?.API_BASE_URL + data?.image}
                    alt="img"
                    style={{ width: "100%" }}
                  />
                </div>
              );
            })}
        </Slider>
      }
    </div>
  );
}

export default AdSlider;

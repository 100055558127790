import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FooterMain from "../Layout/FooterMain";
import HeaderMain from "../Layout/HeaderMain";
import { useDispatch, useSelector } from "react-redux";
import { URL } from "../../Redux/common/url";
import moment from "moment";
import { allapiAction } from "../../Redux/common/action";
function Account() {
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const profile = useSelector((state) =>
    state?.allapi?.get_profile ? state?.allapi?.get_profile : {}
  );
  const seller_update_details = useSelector((state) =>
    state?.allapi?.seller_update_details
      ? state?.allapi?.seller_update_details
      : {}
  );

  const proStatus =
    seller_update_details?.status == 1
      ? 0
      : seller_update_details?.status == 2
      ? 25
      : seller_update_details?.status == 3
      ? 50
      : seller_update_details?.status == 4
      ? 75
      : seller_update_details?.status == 5
      ? 100
      : 100;
  const get_buss_pro_1 = useSelector((state) =>
    state?.allapi?.get_buss_pro_1 ? state?.allapi?.get_buss_pro_1 : {}
  );
  const [value, setValue] = useState("");
  console.log(profile);
  console.log(get_buss_pro_1);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setValue(localStorage.getItem("access_token"));
      if (localStorage.getItem("access_token")) {
        dispatch(allapiAction.getsellerprofilebyseller({}));
        // 1
        // dispatch(allapiAction.getBussProfile1({}));
      }
    } else {
      setValue("");
    }
  }, []);
  // useEffect(() => {

  //   return () => {};
  // }, []);
  const [box1, setbox1] = useState(false);
  const [box2, setbox2] = useState(false);
  const [box3, setbox3] = useState(false);
  const [box4, setbox4] = useState(false);
  const [box5, setbox5] = useState(false);

  const logout = async () => {
    if (typeof window !== "undefined") {
      await localStorage.clear();
    }
    window.location.href = "/login";
    // route.push("/login");
  };
  return (
    <div className="AppPro">
      {/* <HeaderMain /> */}
      <main className="main">
        <div class="testNewd"></div>
        <div class="container-fluid pt">
          <div id="puan">
            <div class="puan">
              <div class="row ">
                <div class="col-3 ">
                  <div class="position-relative">
                    <div
                      id={
                        get_buss_pro_1?.kyc_id?.pan_card
                          ? "avatarnew3"
                          : get_buss_pro_1?.kyc_id?.aadhar_card
                          ? "avatarnew2"
                          : get_buss_pro_1?.kyc_id?.acc_no
                          ? "avatarnew1"
                          : value
                          ? "avatarnew"
                          : "avatarnew4"
                      }
                      style={{ margin: "auto" }}
                    >
                      <img
                        src={
                          get_buss_pro_1?.profile_image
                            ? URL?.API_BASE_URL + get_buss_pro_1?.profile_image
                            : "/avatar-2.png"
                        }
                        onError={(e) => {
                          e.target.src = "/avatar-2.png";
                        }}
                      />
                    </div>
                    {value && (
                      <p
                        style={{
                          fontSize: "12px",
                          position: "absolute",
                          bottom: "-70px",
                          left: "35%",
                        }}
                      >
                        {get_buss_pro_1?.kyc_id?.pan_card
                          ? "100%"
                          : get_buss_pro_1?.kyc_id?.aadhar_card
                          ? "90%"
                          : get_buss_pro_1?.kyc_id?.acc_no
                          ? "70%"
                          : "50%"}
                      </p>
                    )}
                  </div>
                </div>
                <div class="col-9">
                  <p>
                    {/* <strong> Name </strong> :-{" "} */}
                    <strong>{get_buss_pro_1?.fullName} </strong>{" "}
                  </p>
                  <p>
                    {/* <strong style={{ width: "90px" }}> Rank </strong>:-{" "} */}
                    {value && (
                      <Link to={"/home/my-journey"}>
                        <span>
                          {" "}
                          {get_buss_pro_1?.status == 0
                            ? "FASHION CONSULTANT"
                            : get_buss_pro_1?.status == 1
                            ? "FRANCHISE"
                            : get_buss_pro_1?.status == 2
                            ? "BARON"
                            : get_buss_pro_1?.status == 3
                            ? "COUNT"
                            : get_buss_pro_1?.status == 4
                            ? "MARQUISE"
                            : get_buss_pro_1?.status == 5
                            ? "DUKE"
                            : get_buss_pro_1?.status == 5
                            ? "ARCH DUKE"
                            : " "}{" "}
                        </span>{" "}
                        {"  "}
                        <img src="/assets/imgs/man aero.png" alt="" />
                      </Link>
                    )}
                  </p>

                  {!value && (
                    <div>
                      <Link
                        to={"/login"}
                        className="btn btn-outline-danger ms-3 mt-2"
                      >
                        Signin
                      </Link>
                      <Link to={"/register"} className="btn btn-info ms-3 mt-2">
                        Signup
                      </Link>
                    </div>
                  )}
                  {/* <p>
                              <strong style={{ width: "50px" }}>Sponsor</strong> :
                              <span style={{ marginLeft: "10px" }}>
                                {get_buss_pro_1?.referByuserId?.fullName}{" "}
                              </span>
                            </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="container-fluid ">
          <div id="puan">
            <div class="puan">
              <div class="row">
                <div class="col-12 mt-4">
                  <div id="profile_content">
                    <div
                      class="col-md-12 top3seller-shows"
                      style={{ marginTop: "15px" }}
                    >
                      <div class="row">
                        <div class="col-md-12">
                          <h3 class="block-title">
                            <span>Rank</span>
                          </h3>
                          <div
                            class="widget widget-categories "
                            style={{ paddingBottom: "25px" }}
                          >
                            <div className="profile_ul1">
                              <div class="container mb-5 mt-5 star_overflow">
                                <div class="progress">
                                  <div
                                    class="progress-bar range"
                                    role="progressbar"
                                    style={{ width: proStatus + "%" }}
                                    aria-valuenow={proStatus}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                                <div class="range_star d-flex justify-content-between">
                                  <img
                                    src={
                                      seller_update_details?.status >= 1
                                        ? "/assets/imgs/startyellow.jpg"
                                        : "/assets/imgs/startbrown.png"
                                    }
                                    class="rounded-circle"
                                    alt="star_icon"
                                  />
                                  <img
                                    src={
                                      seller_update_details?.status >= 2
                                        ? "/assets/imgs/startyellow.jpg"
                                        : "/assets/imgs/startbrown.png"
                                    }
                                    class="rounded-circle"
                                    alt="star_icon"
                                  />
                                  <img
                                    src={
                                      seller_update_details?.status >= 3
                                        ? "/assets/imgs/startyellow.jpg"
                                        : "/assets/imgs/startbrown.png"
                                    }
                                    class="rounded-circle"
                                    alt="star_icon"
                                  />
                                  <img
                                    src={
                                      seller_update_details?.status >= 4
                                        ? "/assets/imgs/startyellow.jpg"
                                        : "/assets/imgs/startbrown.png"
                                    }
                                    class="rounded-circle"
                                    alt="star_icon"
                                  />
                                  <img
                                    src={
                                      seller_update_details?.status >= 5
                                        ? "/assets/imgs/startyellow.jpg"
                                        : "/assets/imgs/startbrown.png"
                                    }
                                    class="rounded-circle"
                                    alt="star_icon"
                                  />
                                </div>
                                <div class="d-flex justify-content-between text-secondary">
                                  <small class="text-center star_p">
                                    <b>Fr</b>
                                    <br />
                                    {seller_update_details?.Userchaindata
                                      ?.fr_date &&
                                      moment(
                                        seller_update_details?.Userchaindata
                                          ?.fr_date
                                      ).format("MMM DD, yyyy hh:mm:ss A")}
                                    
                                  </small>
                                  <small class="text-center star_p">
                                    <b>Br</b>
                                    <br />
                                    {seller_update_details?.Userchaindata
                                      ?.br_date &&
                                      moment(
                                        seller_update_details?.Userchaindata
                                          ?.br_date
                                      ).format("MMM DD, yyyy hh:mm:ss A")}
                                  </small>
                                  <small class="text-center star_p">
                                    <b>Ct</b>
                                    <br />
                                    {seller_update_details?.Userchaindata
                                      ?.ct_date &&
                                      moment(
                                        seller_update_details?.Userchaindata
                                          ?.ct_date
                                      ).format("MMM DD, yyyy hh:mm:ss A")}
                                  </small>
                                  <small class="text-center star_p">
                                    <b>Mq</b>
                                    <br />
                                    {seller_update_details?.Userchaindata
                                      ?.mq_date &&
                                      moment(
                                        seller_update_details?.Userchaindata
                                          ?.mq_date
                                      ).format("MMM DD, yyyy hh:mm:ss A")}
                                  </small>
                                  <small class="text-center star_p">
                                    <b>Duke</b> <br />
                                    {seller_update_details?.Userchaindata
                                      ?.duke_date &&
                                      moment(
                                        seller_update_details?.Userchaindata
                                          ?.duke_date
                                      ).format("MMM DD, yyyy hh:mm:ss A")}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div class="container mt-5 pt-4">
          <div class="row mt-5">
            <div class="col-12 d-flex justify-content-right">
              <div class="">
                <h1 class="fw-bold">
                  Hey <br />
                  There!
                </h1>
              </div>
              <div class="ms-auto ">
                <a href="#" class="d-flex justify-content-end border-5">
                  <img
                    class="w-25 border-5 mt-1  "
                    src="assets/imgs/log in.png"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div class="container">
          <div class="row">
            <div class="col-12 d-flex align-items-center">
              <a
                class="text-decoration-none"
                href=""
                data-bs-toggle="modal"
                data-bs-target="#loginModal"
              >
                <h6 class="fw-bold main-text text-danger text-nowrap">
                  Signup or Login →
                </h6>
              </a>
              <hr class="w-100 main-hr" />
            </div>
          </div>
        </div> */}
        <div class="container ">
          <div class=" mb-5 pb-4">
            <hr />
            <Link
              class="text-decoration-none text-dark "
              to={value ? "/home/profile" : "/login"}
            >
              <div class="d-flex justify-content-between cardProFilevd">
                <div>
                  <h6 class="fw-bold">Profile Settings</h6>
                  <p>Login to change your profile settings</p>
                </div>
                <div>
                  <img
                    class="  mt-2"
                    src="assets/imgs/icons8-account.gif"
                    alt=""
                    style={{ width: "30px" }}
                  />
                </div>
              </div>
            </Link>
            <hr />
            <Link
              class="text-decoration-none text-dark"
              to={value ? "/home" : "/login"}
            >
              <div class="d-flex justify-content-between cardProFilevd">
                <div>
                  <h6 class="fw-bold">Dashboard</h6>
                  <p>Check your orders status (track, return, cancel etc)</p>
                </div>
                <div>
                  <img
                    class=" mt-3"
                    src="assets/imgs/dashboadicon.gif"
                    alt=""
                    style={{ width: "35px" }}
                  />
                </div>
              </div>
            </Link>
            <hr />
            <Link
              class="text-decoration-none text-dark"
              to={value ? "/home/orders" : "/login"}
            >
              <div class="d-flex justify-content-between cardProFilevd">
                <div>
                  <h6 class="fw-bold">Orders</h6>
                  <p>Check your orders status (track, return, cancel etc)</p>
                </div>
                <div>
                  <img
                    class="  mt-3"
                    src="assets/imgs/icons8-order.gif"
                    alt=""
                    style={{ width: "30px" }}
                  />
                </div>
              </div>
            </Link>
            <hr />

            {/* <a class="text-decoration-none text-dark" href="">
            <div class="d-flex justify-content-between ">
              <div>
                <h6 class="fw-bold">Coupons</h6>
                <p>browse coupons to get discount on Nykaa</p>
              </div>
              <div>
                <img
                  class="w-50 mt-3"
                  src="assets/imgs/icons8-coupon-50.png"
                  alt=""
                />
              </div>
            </div>
          </a>
          <hr /> */}

            <Link
              class="text-decoration-none text-dark"
              to={value ? "/home/wallet" : "/login"}
            >
              <div class="d-flex justify-content-between cardProFilevd">
                <div>
                  <h6 class="fw-bold">Wallet</h6>
                  <p>Login to check your wallet balance</p>
                </div>
                <div>
                  <img
                    class="  mt-3"
                    src="assets/imgs/icons8-wallet.gif"
                    alt=""
                    style={{ width: "30px" }}
                  />
                </div>
              </div>
            </Link>
            <hr />

            {/* <a class="text-decoration-none text-dark" href="">
            <div class="d-flex justify-content-between">
              <div>
                <h6 class="fw-bold">Nykaa Prive</h6>
                <p>Shop for ₹2000 to become a member</p>
              </div>
              <div>
                <img
                  class="w-50 mt-3"
                  src="assets/imgs/icons8-fairytale-50.png"
                  alt=""
                />
              </div>
            </div>
          </a>
          <hr /> */}

            {/* <a class="text-decoration-none text-dark" href="">
            <div class="d-flex justify-content-between">
              <div>
                <h6 class="fw-bold">Beauty Portfolio</h6>
                <p>Personalise your Nykaa App experience</p>
              </div>
              <div>
                <img
                  class="w-50 mt-3"
                  src="assets/imgs/icons8-portfolio-50.png"
                  alt=""
                />
              </div>
            </div>
          </a>
          <hr /> */}

            <Link
              class="text-decoration-none text-dark"
              to={value ? "/wish-list" : "/login"}
            >
              <div class="d-flex justify-content-between cardProFilevd">
                <div>
                  <h6 class="fw-bold">Wishlist</h6>
                  <p>Login too view items in Wishlist </p>
                </div>
                <div>
                  <img
                    class="  mt-3"
                    src="assets/imgs/icons8-heart-50.png"
                    alt=""
                    style={{ width: "30px" }}
                  />
                </div>
              </div>
            </Link>
            <hr />

            {/* <a class="text-decoration-none text-dark" href="">
            <div class="d-flex justify-content-between">
              <div>
                <h6 class="fw-bold">Help Center</h6>
                <p>FAQ, Live Chat, Customer Support</p>
              </div>
              <div>
                <img
                  class="w-50 mt-3"
                  src="assets/imgs/icons8-help.gif"
                  alt=""
                />
              </div>
            </div>
          </a>
          <hr /> */}

            {/* <a class="text-decoration-none text-dark" href="">
            <div class="d-flex justify-content-between">
              <div>
                <h6 class="fw-bold">Addresses</h6>
                <p>Manage your saved addressses</p>
              </div>
              <div>
                <img
                  class="w-50 mt-3"
                  src="assets/imgs/icons8-address.gif"
                  alt=""
                />
              </div>
            </div>
          </a>
          <hr /> */}

            {/* <a class="text-decoration-none text-dark" href="">
            <div class="d-flex justify-content-between">
              <div>
                <h6 class="fw-bold">Payment Methods</h6>
                <p>Manage your saved payment methods</p>
              </div>
              <div>
                <img
                  class="w-50 mt-3"
                  src="assets/imgs/icons8-atm-card-50.png"
                  alt=""
                />
              </div>
            </div>
          </a>
          <hr /> */}
          </div>
        </div>

        <div className="container">
          {/* <div className="row  text-center">
            <div className="col-3">
              <div>
                <div></div>
                <Link to={"/about"}>About</Link>
              </div>
            </div>
            <div className="col-3">
              <div>
                <div></div>
                <Link to={"/compliance"}>Compliance</Link>
              </div>
            </div>
            <div className="col-3">
              <div>
                <div></div>
                <Link to={"/faqs"}>Faq</Link>
              </div>
            </div>
            <div className="col-3">
              <div>
                <div></div>
                <Link to={"/contact"}>Contact Us</Link>
              </div>
            </div>
          </div> */}
          <div className="row p-3 text-black">
            <div className="col-11">
              <h6>CORPORATE INFO</h6>
              {box1 && (
                <div style={{ paddingLeft: "10px" }}>
                  <Link
                    className="p-1  "
                    style={{
                      color: "slategray",

                      display: "block",
                    }}
                    to="/about"
                  >
                    About Us
                  </Link>

                  <Link
                    className="p-1  "
                    style={{
                      color: "slategray",

                      display: "block",
                    }}
                    to="/compliance"
                  >
                    Compliance
                  </Link>
                </div>
              )}
            </div>
            <div className="col-1">
              {" "}
              <img
                class="read-more-btn4"
                src="/img/icons8-down-arrow-24.png"
                alt=""
                data-expanded="false"
                onClick={() => {
                  setbox1(box1 ? false : true);
                }}
              />
            </div>
          </div>
          <div className="row  p-3">
            <div className="col-11">
              <h6>HELP</h6>
              {box2 && (
                <div style={{ paddingLeft: "10px" }}>
                  <Link
                    className="p-1  "
                    style={{
                      color: "slategray",

                      display: "block",
                    }}
                    to="/faqs"
                  >
                    Faqs
                  </Link>

                  {/* <br /> */}
                  <Link
                    className="p-1  "
                    style={{
                      color: "slategray",

                      display: "block",
                    }}
                    to="/contact"
                  >
                    Contact Us
                  </Link>
                  {/* <br /> */}
                  <Link
                    className="p-1  "
                    style={{
                      color: "slategray",

                      display: "block",
                    }}
                    to="/transparency"
                  >
                    Transparency
                  </Link>
                </div>
              )}
            </div>
            <div className="col-1">
              {" "}
              <img
                class="read-more-btn4"
                src="/img/icons8-down-arrow-24.png"
                alt=""
                data-expanded="false"
                onClick={() => {
                  setbox2(box2 ? false : true);
                }}
              />
            </div>
          </div>
          <div className="row  p-3">
            <div className="col-11">
              <h6>COMPANY POLICY</h6>
              {box3 && (
                <div style={{ paddingLeft: "10px" }}>
                  <a
                    className="p-1  "
                    style={{
                      color: "slategray",

                      display: "block",
                    }}
                    href="/CancellationPolicy.pdf"
                    target="_blank"
                  >
                    Cancellation Policy
                  </a>

                  {/* <br /> */}
                  <a
                    className="p-1  "
                    style={{
                      color: "slategray",

                      display: "block",
                    }}
                    href="/termsandconditions.pdf"
                    target="_blank"
                  >
                    Terms & Conditions
                  </a>

                  {/* <br /> */}
                  <Link
                    className="p-1  "
                    style={{
                      color: "slategray",

                      display: "block",
                    }}
                    to="/privacy-policy"
                  >
                    Privacy Policy
                  </Link>
                  {/* <br />
                  <Link to="/transparency">Transparency</Link> */}
                </div>
              )}
            </div>
            <div className="col-1">
              {" "}
              <img
                class="read-more-btn4"
                src="/img/icons8-down-arrow-24.png"
                alt=""
                data-expanded="false"
                onClick={() => {
                  setbox3(box3 ? false : true);
                }}
              />
            </div>
          </div>
          <div className="row  p-3">
            <div className="col-11">
              <h6>My Blog</h6>
              {box4 && (
                <div style={{ paddingLeft: "10px" }}>
                  {/* <br /> */}
                  <Link
                    className="p-1  "
                    style={{
                      color: "slategray",

                      display: "block",
                    }}
                    to="/blogs"
                  >
                    Blogs
                  </Link>
                  {/* <br />
                  <Link to="/transparency">Transparency</Link> */}
                </div>
              )}
            </div>
            <div className="col-1">
              {" "}
              <img
                class="read-more-btn4"
                src="/img/icons8-down-arrow-24.png"
                alt=""
                data-expanded="false"
                onClick={() => {
                  setbox4(box4 ? false : true);
                }}
              />
            </div>
          </div>

          {value && (
            <div className="p-4">
              <button
                onClick={logout}
                className="btnm btn-danger w-100 text-white align-self-center text-center d-block p-2"
              >
                {" "}
                Log out
              </button>
            </div>
          )}
        </div>
      </main>

      <FooterMain />
    </div>
  );
}

export default Account;

// import { allapiAction } from "@/Redux/common/action";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import Button from "react-bootstrap/Button";
import { allapiAction } from "../../Redux/common/action";
import Sidebar from "../Layout/Sidebar";
// import Header2 from "../../../../component/sidebar/Header2";
import HeaderAccount from "../Layout/HeaderAccount";
import { URL } from "../../Redux/common/url";
// import Sidebar from "../../../../component/sidebar/Sidebar";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Iidcard2 from "./Iidcard2";
function Iidcard() {
  const dispatch = useDispatch();
  const componentRef = useRef();
  const profile = useSelector((state) =>
    state?.allapi?.get_profile ? state?.allapi?.get_profile : {}
  );
  console.log(profile);

  useEffect(() => {
    dispatch(allapiAction.getprofile({}));

    return () => {};
  }, []);
  const generatePDF = () => {
    const invoice = componentRef.current;

    html2canvas(invoice, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

      const pdfBlob = pdf.output("blob");

      // Open PDF in a new tab using window.URL.createObjectURL
      const pdfURL = window.URL.createObjectURL(pdfBlob);
      window.open(pdfURL, "_blank");
      // pdf.save("invoice.pdf");
    });
  };

  const handleDownloadPdf = async () => {
    const element = componentRef.current; // The content to convert to PDF
    const canvas = await html2canvas(element); // Capture HTML as a canvas
    const imgData = canvas.toDataURL("image/png"); // Convert canvas to image

    const pdf = new jsPDF("p", "mm", "a4"); // Create a PDF instance
    const pdfWidth = pdf.internal.pageSize.getWidth(); // PDF width
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width; // Maintain aspect ratio

    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight); // Add image to PDF
    pdf.save("download.pdf"); // Download the PDF
  };
  return (
    <div>
      <Sidebar />
      <main className="main-wrap">
        <HeaderAccount />
        <Iidcard2 />
      </main>
    </div>
  );
}

export default Iidcard;

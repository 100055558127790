import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
// import { allapiAction } from "../../../Redux/common/action";
// import { URL } from "../../../Redux/common/url";
// import Footer from "../Layout/Footer";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import moment from "moment";

import ImageUploading from "react-images-uploading";
import { toast } from "react-toastify";
// import dateFormat, { masks } from "dateformat";

import ReactToPrint from "react-to-print";
import { allapiAction } from "../../Redux/common/action";
import HeaderAccount from "../Layout/HeaderAccount";
import Sidebar from "../Layout/Sidebar";
import { URL } from "../../Redux/common/url";
import { useLocation, useNavigate } from "react-router-dom";
// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import Header2 from "../../../component/sidebar/Header2";
// import Sidebar from "../../../component/sidebar/Sidebar";

function OrderBussnissAppRecordtab() {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // getsellerProductList

  const get_seller_product_list = useSelector((state) =>
    state?.allapi?.order_list_user ? state?.allapi?.order_list_user : {}
  );

  const get_last_three_month = useSelector((state) =>
    state?.allapi?.get_last_three_month
      ? state?.allapi?.get_last_three_month
      : []
  );

  get_last_three_month?.reverse();

  const get_invoice = useSelector((state) =>
    state?.allapi?.get_invoice ? state?.allapi?.get_invoice : {}
  );
  const order_details = useSelector((state) =>
    state?.allapi?.order_details ? state?.allapi?.order_details : {}
  );

  const all_sub_sub_categ_List = useSelector((state) =>
    state?.allapi?.all_sub_sub_categ_List
      ? state?.allapi?.all_sub_sub_categ_List
      : []
  );
  const get_footer_all_data = useSelector((state) =>
    state?.allapi?.get_footer_all_data ? state?.allapi?.get_footer_all_data : {}
  );

  useEffect(() => {
    // dispatch(allapiAction.getuserdownselllistNew(1));
    // dispatch(allapiAction.getInvoice({}));
    dispatch(allapiAction.getuserBussnisslastthreeMonth2({}));
    // dispatch(allapiAction.footerPagepageListetPu({}));

    return () => {};
  }, []);

  // useEffect(() => {
  //   dispatch(allapiAction.getsubsubcate({}));
  //   return () => {};
  // }, []);

  // const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const headers = [
    { name: "Route Name", field: "routeName", sortable: true },
    { name: "From", field: "from", sortable: true },
    { name: "To", field: "to", sortable: false },
    { name: "Action", field: "action", sortable: false },
  ];

  const comments = get_seller_product_list?.docs
    ? get_seller_product_list?.docs
    : [];

  const pageCount = get_seller_product_list?.totalPages
    ? get_seller_product_list?.totalPages
    : 0;

  const commentsData = useMemo(() => {
    let computedComments = comments;

    // if (search) {
    //   computedComments = computedComments.filter(
    //     (comment) =>
    //       comment.message.toLowerCase().includes(search.toLowerCase()) ||
    //       comment.title.toLowerCase().includes(search.toLowerCase())
    //   );
    // }

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * itemsPerPage,
      (currentPage - 1) * itemsPerPage + itemsPerPage
    );
  }, [comments, currentPage, search, sorting, itemsPerPage]);

  // useEffect(() => {
  //   const endOffset = itemOffset + itemsPerPage;

  //   setPageCount(Math.ceil(get_seller_product_list.totalDocs / itemsPerPage));
  // }, [itemOffset, itemsPerPage, comments]);

  // const handlePageClick = (event: any) => {
  //   const newOffset = event.selected % (comments.length / 2)
  //   setCurrentPage(newOffset + 1)
  //   setItemOffset(newOffset * itemsPerPage)
  // }

  const [page, setpageNo] = useState(1);

  const handlePageClick = (event) => {
    // const newOffset = event.selected % (comments.length / 2);
    // setCurrentPage(newOffset + 1);
    // setItemOffset(newOffset * itemsPerPage);

    const data = event?.selected + 1;

    setpageNo(data);

    if (!search) {
      // dispatch(ShoetoggelAction.getusers(data))
      dispatch(allapiAction.getuserdownselllistNew(data));
      //   dispatch(allapiAction.getsellerProductList(1));
      return () => {};
    } else {
      // dispatch(
      //   ShoetoggelAction.searchUser({ pageNumber: data, key: userSearch })
      // );
      dispatch(allapiAction.searchSellerProduct({ key: search, page: data }));
      return () => {};
    }
  };

  // image uploder
  const [images, setImages] = React.useState([]);
  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit

    setImages(imageList);
  };

  const [images1, setImages1] = React.useState([]);
  const maxNumber1 = 4;

  const onChange1 = (imageList, addUpdateIndex) => {
    // data for submit

    setImages1(imageList);
  };

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const [selectedOption, setSelectedOption] = useState(null);

  const [subList, setsubList] = useState([]);

  useEffect(() => {
    const dropList = all_sub_sub_categ_List?.map((data, i) => {
      data.value = data?._id;
      data.label = data?.name;

      return data;
    });

    setsubList(dropList);
  }, [all_sub_sub_categ_List]);

  const [roledata, setroledata] = useState({ title: "", tegs: "" });

  const hendleChangerole = (e) => {
    const { name, value } = e.target;
    setroledata({ ...roledata, [name]: value });
  };

  const hendleSearch = (e) => {
    // searchSellerProduct
    setSearch(e);
    // dispatch(allapiAction.searchSellerProduct(e));
    dispatch(allapiAction.searchSellerProduct({ key: e, page: 1 }));
    return () => {};
  };

  const submitProduct = () => {
    try {
      // const {data: auth} = await login(values.role_name, values.tegs)
      // saveAuth(auth)
      // const {data: user} = await getUserByToken(auth.api_token)
      // dispatch(adminAction.createRoles(values))
      const data = new FormData();

      // data.append("title", imageEditfor);

      // images

      if (images?.length == 0) {
        toast.error("please select feature Image");
      } else if (images1?.length == 0) {
        toast.error("please select  Image");
      } else if (!roledata?.title) {
        toast.error("please enter prodcat name");
      } else if (!roledata?.tegs) {
        toast.error("please enter product teg");
      } else if (!selectedOption) {
        toast.error("please select category ");
      } else {
        // setLoading(true);

        data.append("title", roledata?.title);
        data.append("tegs", roledata?.tegs);
        data.append("categoryName", "test cate");
        data.append("categoryId", selectedOption?._id);
        // data.append('sellerId', selectedOption1?._id)
        data.append("featureImage", images[0]?.file);
        images1?.forEach((file) => {
          data.append("images", file?.file);
        });

        dispatch(allapiAction.createSellerProduct(data));
        setShow(false);
        // setLoading(false);
      }

      // setCurrentUser(user)
    } catch (error) {
      // saveAuth(undefined)
      // setStatus('The login details are incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  };

  // deleteSellerProduct

  const [showDelelt, setShowDelete] = useState(false);
  const [showDelelt1, setShowDelete1] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);

  const [productID, setproductID] = useState("");

  const hendleModalShow = (e) => {
    setShowDelete(true);
    setproductID(e);

    dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const hendleDelete = () => {
    // deleteProduct
    setShowDelete(false);
    dispatch(allapiAction.deleteSellerProduct({ productId: productID }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const componentRef = useRef();

  const hendleShowOrder = (e) => {
    // getuserOrderDetails

    dispatch(allapiAction.getuserOrderDetails({ _id: e }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  // get_invoice

  console.log(order_details);

  const shippingCharge = order_details?.sub_total > 10000 ? 699 : 199;
  console.log(order_details?.orderproductsList);

  const totalSubtotalPricegstdd = order_details?.orderproductsList?.reduce(
    (total, obj) => {
      return total + (obj.sub_total * obj?.gst) / 100;
    },
    0
  );
  // return total +(obj.subtotalprice * obj?.gst) / 100 ;
  // console.log(shippingCharge)
  // console.log(totalSubtotalPricegstdd)

  const totalSubtotalPricegst = totalSubtotalPricegstdd;
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  console.log(location.pathname);
  const pathParts = location.pathname.split("/");

  console.log(pathParts);
  return (
    <div>
      <div class="ant-card-head">
        <div
          class="ant-card-head-title ml-5"
          style={{ fontWeight: "bold", fontSize: "14px" }}
        >
          Business Records
        </div>
        <hr />
      </div>
      <div class="ant-card-body">
        <div>
          {get_last_three_month &&
            get_last_three_month?.map((data, i) => {
              return (
                <div
                  style={{
                    padding: "0px 10px",
                    borderBottom: "2px dotted",
                    height: "80px",
                    textAlign: "center",
                    width: "300px",
                    margin: "0 auto",
                  }}
                  className={i % 2 == 0 ? "evenclm" : "oddclm"}
                >
                  <h4>
                    {data?.endDate && moment(data?.endDate).format("MMM yyyy")}{" "}
                    - cycle {data?.cycle}
                  </h4>
                  <h5>
                    {data?.startDate &&
                      moment(data?.startDate).format("DD MMM yyyy")}{" "}
                    -{" "}
                    {data?.endDate &&
                      moment(data?.endDate).format("DD MMM yyyy")}{" "}
                  </h5>
                  <h6> ₹ {data?.totalAmount}</h6>
                </div>
              );
            })}
        </div>
        {/* <div style={{ overflow: "auto" }}>
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    {get_last_three_month &&
                      get_last_three_month?.map((data, i) => {
                        console.log(data);
                        return (
                          <th style={{ borderLeft: "2px dotted" }}>
                            {data?.endDate &&
                              moment(data?.endDate).format("MMM yyyy")}{" "}
                            -cycle {data?.cycle}
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {get_last_three_month &&
                      get_last_three_month?.map((data, i) => {
                        console.log(data);
                        return (
                          <td style={{ borderLeft: "2px dotted" }}>
                            ₹ {data?.totalAmount}
                          </td>
                        );
                      })}
                  </tr>
                </tbody>
              </table>
            </div> */}
      </div>
    </div>
  );
}

export default OrderBussnissAppRecordtab;

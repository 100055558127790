import React, { useEffect, useMemo, useRef, useState, memo } from "react";
// import Header2 from "../../../component/sidebar/Header2";
// import Sidebar from "../../../component/sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
// import { allapiAction } from "@/Redux/common/action";
import moment from "moment";
import { allapiAction } from "../../Redux/common/action";
import Sidebar from "../Layout/Sidebar";
import HeaderAccount from "../Layout/HeaderAccount";
import { Link } from "react-router-dom";
function RankMyJurny() {
  const dispatch = useDispatch();

  const seller_update_details = useSelector((state) =>
    state?.allapi?.seller_update_details
      ? state?.allapi?.seller_update_details
      : {}
  );

  console.log(seller_update_details);

  useEffect(() => {
    dispatch(allapiAction.getsellerprofilebyseller({}));

    return () => {};
  }, []);

  //    useEffect(() => {
  //       if (typeof window !== "undefined") {
  //         setValue(localStorage.getItem("access_token"));
  //         if (localStorage.getItem("access_token")) {
  //           dispatch(allapiAction.getsellerprofilebyseller({}));
  //           // 1
  //           // dispatch(allapiAction.getBussProfile1({}));
  //         }
  //       } else {
  //         setValue("");
  //       }
  //     }, []);
  const [box1, setbox1] = useState(false);
  const [box2, setbox2] = useState(false);
  const [box3, setbox3] = useState(false);
  const [box4, setbox4] = useState(false);
  const [box5, setbox5] = useState(false);
  const [box6, setbox6] = useState(false);

  return (
    <div>
      <Sidebar />

      <main className="main-wrap AppPro">
        <HeaderAccount />

        <section className="content-main">
          {/* <div className="content-header">
            <div>
              <h2 className="content-title card-title">Rank Upgrade Report</h2>
            </div>
            <div></div>
          </div> */}
          <div class="myjourney container mb-4">
            <div class="row mt-4">
              <div class="d-flex gap-2">
                <div>
                  <Link to="/account">
                    <img src="/img/icons8-left-arrow-24.png" alt="" />{" "}
                  </Link>
                </div>
                <h6 class="mt-1 fw-bold">My Journey</h6>
              </div>
            </div>

            <div class="row mt-4">
              {seller_update_details?.status >= 0 && (
                <div class="d-flex justify-content-between mt-4">
                  <div>
                    <img
                      class="border border-2 rounded-4 p-2"
                      src="/img/sliver.jpg"
                      alt=""
                      width={42}
                    />
                  </div>

                  <div class="bg-info-color d-flex justify-content-between ms-3 w-100 rounded-3 p-2">
                    <div>
                      <h6 class="mt-1 fw-bold">
                        <small>FC</small>
                      </h6>
                      <span
                        class="more-content5"
                        style={
                          box6 ? { display: "block" } : { display: "none" }
                        }
                      >
                        <p>
                          <small>
                            Achieved on{" "}
                            {seller_update_details?.createdAt &&
                              moment(seller_update_details?.createdAt).format(
                                "DD-MM-YYYY hh:mm A"
                              )}
                          </small>
                          <br />
                          {/* <small>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Magnam, voluptatem sit!
                          </small> */}
                        </p>
                      </span>
                    </div>
                    <div>
                      <img
                        class="read-more-btn4"
                        src="/img/icons8-down-arrow-24.png"
                        alt=""
                        data-expanded="false"
                        onClick={() => {
                          setbox6(box6 ? false : true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              {(seller_update_details?.status > 4 ||
                seller_update_details?.status > 3 ||
                seller_update_details?.status > 2 ||
                seller_update_details?.status > 1 ||
                seller_update_details?.status > 0) && (
                <div class="d-flex justify-content-between  mt-4">
                  <div>
                    <img
                      class="border border-2 rounded-4 p-2"
                      src="/img/Medal.png"
                      alt=""
                    />
                  </div>

                  <div class="bg-primary-color d-flex justify-content-between ms-3 w-100 rounded-3 p-2">
                    <div>
                      <h6 class="mt-1 fw-bold">
                        <small>FR</small>
                      </h6>
                      <span
                        class="more-content"
                        style={
                          box1 ? { display: "block" } : { display: "none" }
                        }
                      >
                        <p>
                          <small>
                            Achieved on{" "}
                            {seller_update_details?.Userchaindata?.fr_date &&
                              moment(
                                seller_update_details?.Userchaindata?.fr_date
                              ).format("DD-MM-YYYY hh:mm A")}
                          </small>
                          <br />
                          {/* <small>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Magnam, voluptatem sit!
                          </small> */}
                        </p>
                      </span>
                    </div>
                    <div>
                      <img
                        class="read-more-btn"
                        src="/img/icons8-down-arrow-24.png"
                        alt=""
                        data-expanded="true"
                        onClick={() => {
                          setbox1(box1 ? false : true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}

              {(seller_update_details?.status > 4 ||
                seller_update_details?.status > 3 ||
                seller_update_details?.status > 2 ||
                seller_update_details?.status > 1) && (
                <div class="d-flex justify-content-between mt-4">
                  <div>
                    <img
                      class="border border-2 rounded-4 p-2"
                      src="/img/Champion medal.png"
                      alt=""
                    />
                  </div>

                  <div class="bg-secondary-color d-flex justify-content-between ms-3 w-100 rounded-3 p-2">
                    <div>
                      <h6 class="mt-1 fw-bold">
                        <small>BR</small>
                      </h6>
                      <span
                        class="more-content1"
                        style={
                          box2 ? { display: "block" } : { display: "none" }
                        }
                      >
                        <p>
                          <small>
                            Achieved on{" "}
                            {seller_update_details?.Userchaindata?.br_date &&
                              moment(
                                seller_update_details?.Userchaindata?.br_date
                              ).format("DD-MM-YYYY hh:mm A")}
                          </small>
                          <br />
                          {/* <small>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Magnam, voluptatem sit!
                          </small> */}
                        </p>
                      </span>
                    </div>
                    <div>
                      <img
                        class="read-more-btn1"
                        src="/img/icons8-down-arrow-24.png"
                        alt=""
                        data-expanded="true"
                        onClick={() => {
                          setbox2(box2 ? false : true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              {(seller_update_details?.status > 4 ||
                seller_update_details?.status > 3 ||
                seller_update_details?.status > 2) && (
                <div class="d-flex justify-content-between mt-4">
                  <div>
                    <img
                      class="border border-2 rounded-4 p-2"
                      src="/img/champion-medal.png"
                      alt=""
                    />
                  </div>

                  <div class="bg-success-color d-flex justify-content-between ms-3 w-100 rounded-3 p-2">
                    <div>
                      <h6 class="mt-1 fw-bold">
                        <small>COHEAD</small>
                        {/* Cohead */}
                      </h6>
                      <span
                        class="more-content2"
                        style={
                          box3 ? { display: "block" } : { display: "none" }
                        }
                      >
                        <p>
                          <small>
                            Achieved on{" "}
                            {seller_update_details?.Userchaindata?.ct_date &&
                              moment(
                                seller_update_details?.Userchaindata?.ct_date
                              ).format("DD-MM-YYYY hh:mm A")}
                          </small>
                          <br />
                          {/* <small>Achieved on Date and time</small> */}
                          <small>
                            You are now eligible to become a monarch by
                            fulfilling the following conditions:{" "}
                          </small>
                          <br />
                          <ul>
                            <li> Develop 3 Cohead who have been sponsored by you</li>
                            <li> Submit the challenge form</li>
                            <li> You have to do 15.5 lakh business volume through your team within 4 month.</li>
                          </ul>
                          {/* <small>
                            Develop 3 Cohead who have been sponsored by you
                          </small>
                          <br />
                          <small>Submit the challenge form</small>
                          <br />
                          <small>
                            You have to do 15.5 lakh business volume through
                            your team within 4 month.
                          </small> */}
                        </p>
                      </span>
                    </div>
                    <div>
                      <img
                        class="read-more-btn2"
                        src="/img/icons8-down-arrow-24.png"
                        alt=""
                        data-expanded="false"
                        onClick={() => {
                          setbox3(box3 ? false : true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}

              {(seller_update_details?.status > 4 ||
                seller_update_details?.status > 3) && (
                <div class="d-flex justify-content-between mt-4">
                  <div>
                    <img
                      class="border border-2 rounded-4 p-2"
                      src="/img/icons8-influencer-24.png"
                      alt=""
                    />
                  </div>

                  <div class="bg-danger-color d-flex justify-content-between ms-3 w-100 rounded-3 p-2">
                    <div>
                      <h6 class="mt-1 fw-bold">
                        <small>MONARCH</small>
                        {/* Monarch */}
                      </h6>
                      <span
                        class="more-content3"
                        style={
                          box4 ? { display: "block" } : { display: "none" }
                        }
                      >
                        <p>
                          <small>
                            Achieved on{" "}
                            {seller_update_details?.Userchaindata?.mq_date &&
                              moment(
                                seller_update_details?.Userchaindata?.mq_date
                              ).format("DD-MM-YYYY hh:mm A")}
                          </small>
                          <br />
                          {/* <small>Achieved on Date and time</small> */}
                          <small>
                            You are now eligible to become a Cambridge by
                            fulfilling the following conditions:{" "}
                          </small>
                          <ul>
                            <li> Develop 3 Monarch who have been sponsored by you</li>
                            <li> Submit the challenge form</li>
                            <li> You have to do 1.4 Cr business volume through your team within 4 month.</li>
                          </ul>
                          {/* <small>
                            Develop 3 Monarch who have been sponsored by you
                            Submit the challenge form You have to do 1.4 Cr
                            business volume through your team within 4 month.
                          </small> */}
                        </p>
                      </span>
                    </div>
                    <div>
                      <img
                        class="read-more-btn3"
                        src="/img/icons8-down-arrow-24.png"
                        alt=""
                        data-expanded="false"
                        onClick={() => {
                          setbox4(box4 ? false : true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}

              {seller_update_details?.status > 4 && (
                <div class="d-flex justify-content-between mt-4">
                  <div>
                    <img
                      class="border border-2 rounded-4 p-2"
                      src="/img/icons8-lock-24.png"
                      alt=""
                    />
                  </div>

                  <div class="bg-warning-color d-flex justify-content-between ms-3 w-100 rounded-3 p-2">
                    <div>
                      <h6 class="mt-1 fw-bold">
                        <small>CAMBRIDG </small>
                        {/* Cambridg */}
                      </h6>
                      <span
                        class="more-content4"
                        style={
                          box5 ? { display: "block" } : { display: "none" }
                        }
                      >
                        <p>
                          <small>
                            Achieved on{" "}
                            {seller_update_details?.Userchaindata?.duke_date &&
                              moment(
                                seller_update_details?.Userchaindata?.duke_date
                              ).format("DD-MM-YYYY hh:mm A")}
                          </small>
                          <br />
                          {/* <small>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Magnam, voluptatem sit!
                          </small> */}
                        </p>
                      </span>
                    </div>
                    <div>
                      <img
                        class="read-more-btn4"
                        src="/img/icons8-down-arrow-24.png"
                        alt=""
                        data-expanded="false"
                        onClick={() => {
                          setbox5(box5 ? false : true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}

              {/* <div class="d-flex justify-content-between mt-4">
                <div>
                  <img
                    class="border border-2 rounded-4 p-2"
                    src="/img/icons8-lock-24.png"
                    alt=""
                  />
                </div>

                <div class="bg-info-color d-flex justify-content-between ms-3 w-100 rounded-3 p-2">
                  <div>
                    <h6 class="mt-1 fw-bold">
                      <small>ABO</small>
                    </h6>
                    <span class="more-content5">
                      <p>
                        <small>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Magnam, voluptatem sit!
                        </small>
                      </p>
                    </span>
                  </div>
                  <div>
                    <img
                      class="read-more-btn5"
                      src="/img/icons8-down-arrow-24.png"
                      alt=""
                      data-expanded="false"
                    />
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-between mt-4">
                <div>
                  <img
                    class="border border-2 rounded-4 p-2"
                    src="/img/icons8-lock-24.png"
                    alt=""
                  />
                </div>

                <div class="bg-primary-color2 d-flex justify-content-between ms-3 w-100 rounded-3 p-2">
                  <div>
                    <h6 class="mt-1 fw-bold">
                      <small>Veteran ABO</small>
                    </h6>
                    <span class="more-content6">
                      <p>
                        <small>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Magnam, voluptatem sit!
                        </small>
                      </p>
                    </span>
                  </div>
                  <div>
                    <img
                      class="read-more-btn6"
                      src="/img/icons8-down-arrow-24.png"
                      alt=""
                      data-expanded="false"
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default RankMyJurny;

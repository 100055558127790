import { useState, useEffect } from "react";

const CountdownTimer = () => {
    const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

    useEffect(() => {
        // Current Date
        const now = new Date();
        const year = now.getFullYear();
        const month = now.getMonth(); // Current Month

        // Last Date of the Current Month
        const lastDayOfMonth = new Date(year, month + 1, 0, 23, 59, 59);

        const updateCountdown = () => {
            const now = new Date();
            const difference = lastDayOfMonth - now; // Time Difference in Milliseconds

            if (difference > 0) {
                const days = Math.floor(difference / (1000 * 60 * 60 * 24));
                const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
                const minutes = Math.floor((difference / (1000 * 60)) % 60);
                const seconds = Math.floor((difference / 1000) % 60);

                setTimeLeft({ days, hours, minutes, seconds });
            } else {
                setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
            }
        };

        // Start Interval
        const timer = setInterval(updateCountdown, 1000);

        // Cleanup Interval on Unmount
        return () => clearInterval(timer);
    }, []);

    return (
        <div style={{ textAlign: "center", fontSize: "24px", fontWeight: "bold" }}>
            {/* <h2>Month Ends In:</h2> */}
            <p>{timeLeft.days} : {timeLeft.hours} : {timeLeft.minutes} : {timeLeft.seconds} </p>
        </div>
    );
};

export default CountdownTimer;

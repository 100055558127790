import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
// import { allapiAction } from "../../../Redux/common/action";
// import { URL } from "../../../Redux/common/url";
// import Footer from "../Layout/Footer";
// import Header from "../Layout/Header";
// import Sidebar from "../Layout/Sidebar";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import moment from "moment";

import ImageUploading from "react-images-uploading";
import { toast } from "react-toastify";
// import dateFormat, { masks } from "dateformat";

import ReactToPrint from "react-to-print";
// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import Header2 from "../../../component/sidebar/Header2";
// import Sidebar from "../../../component/sidebar/Sidebar";
// import { ExportToExcel } from "../../../component/ExportToExcel";
import { allapiAction } from "../../Redux/common/action";
import { ExportToExcel } from "../ExportToExcel";
import Sidebar from "../Layout/Sidebar";
import HeaderAccount from "../Layout/HeaderAccount";
import Chart from "react-google-charts";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SignUplist2 from "./SignUplist2";
import PurchasTeam2 from "./PurchasTeam2";
function SignUplistTab() {
  const [first, setfirst] = useState("sign-up-team");
  return (
    <div>
      <div className="row mb-5">
        <div className="col-6">
          <div className="mt-3">
            <span
              //   to="/home/sign-up-team"
              onClick={() => setfirst("sign-up-team")}
              className={first === "sign-up-team" ? " activem" : "inactivem "}
            >
              Community Member
            </span>
          </div>
        </div>
        <div className="col-6">
          <div className="mt-3">
            <span
              onClick={() => setfirst("purchase-team")}
              className={first === "sign-up-team" ? " inactivem" : "  activem"}
            >
              New Purchase
            </span>
          </div>
        </div>

        {/* <div>
                      
        
                      <Button variant="primary" onClick={gendletoexportdata}>
                        Export
                      </Button>
                    </div> */}
      </div>

{ first === "sign-up-team" &&     <SignUplist2 />}
  { first === "purchase-team" &&   <PurchasTeam2 />}
    </div>
  );
}

export default SignUplistTab;

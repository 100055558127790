// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import { NextSeo } from "next-seo";
// import Link from "next/link";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import FooterMain from "../Layout/FooterMain";
import HeaderMain from "../Layout/HeaderMain";
// import Footer from "../Layout/Footer";
// import Header from "../Layout/Header";

function Faqs() {
  const dispatch = useDispatch();

  const [first, setfirst] = useState("0");

  // getlistfaqs
  // faqs_list
  const faqs_list = useSelector((state) =>
    state?.allapi?.faqs_list ? state?.allapi?.faqs_list : []
  );

  useEffect(() => {
    dispatch(allapiAction.getlistfaqs({}));
    return () => {};
  }, []);

  let pageUrl;

  if (typeof window !== "undefined") {
    if (window.location !== undefined) {
      // Outputs: 'https:'

      pageUrl = window.location.href;
    }
  }
  const urltet = URL.API_BASE_URL_2 + "/faq";

  const [switchValue, setSwitchValue] = useState(false);
  console.log(switchValue);
  const handleSwitchChange = (checked) => {
    console.log(checked?.target?.checked);
    if (checked?.target?.checked) {
      setSwitchValue(checked?.target?.checked);
      localStorage.setItem("lang", "hindi");
      dispatch(allapiAction.getlistfaqs({}));
    } else {
      setSwitchValue(checked?.target?.checked);
      localStorage.setItem("lang", "english");
      dispatch(allapiAction.getlistfaqs({}));
    }
  };

  const [value, setValue] = useState("");

  useEffect(() => {
    // if (typeof window !== "undefined") {
    const dsl = localStorage.getItem("lang");

    console.log(dsl);
    if (dsl) {
      console.log("teat1");
      if (dsl == "hindi") {
        console.log("teat2");
        setSwitchValue(true);
      } else {
        console.log("teat3");
        setSwitchValue(false);
      }
    }
    // } else {
    //   setValue("");
    // }
  }, []);
  return (
    <div>
      {/* <Header /> */}
      {/* <NextSeo
        title={"transparency"}
        description={"transparency"}
        canonical={urltet}
        openGraph={{
          // type: "website",
          locale: "en",

          siteName: "Jewelslly",
          url: urltet,
          title: "transparency",
          description: "transparency",
          type: "website",
          // profile: {
          //   firstName: post?.buseniss_id?.businessName,
          //   city: post?.city,
          //   country: post?.country,
          //   address: post?.buseniss_id?.address,
          //   // lastName: "Last",
          //   // username: "firstlast123",
          //   // gender: "female",
          // },
          // images: [
          //   {
          //     url: URL.API_BASE_URL + post?.buseniss_id?.logo,
          //     width: 800,
          //     height: 600,
          //     alt: post?.buseniss_id?.businessName,
          //     type: "image/jpeg",
          //   },
          // ],
        }}
        twitter={{
          handle: "@handle",
          site: "@jewelslly",
          cardType: "summary_large_image",
          title: "faq",
          description: "faq",
        }}
      /> */}
      <HeaderMain />
      <section className="faq-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 ">
              <div className="faq-title text-center faq-title2 pb-3">
                <h2 className="mt-10">FREQUENTLY ASKED QUESTION</h2>
              </div>
              <Form className="hin">
                <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  label="हिंदी में पढ़े"
                  checked={switchValue}
                  onChange={handleSwitchChange}
                />
              </Form>
            </div>
            {/* switchValue */}

            {switchValue ? (
              <div>
                <div class="mx-2">
                  <div class="row mx-5 mt-5">
                    <div class="col-sm-6 border">
                      <div class="row px-2">
                        <div class="col-sm-12">
                          <h5 class="text-center mt-2">दर्जा</h5>
                          <hr />
                          <details>
                            <summary>
                              एक RIL IBO पार्टनर कितनी रैंक हासिल कर सकता है?
                            </summary>
                            <br />
                            <p>
                              RIL IBO पार्टनर्स सात रैंक हासिल कर सकते हैं -
                              फैशन कंसल्टेंट, फ्रेंचाइजी, बैरन, काउंट,
                              मार्क्विस, ड्यूक और आर्क-ड्यूक। RIL पर कोई भी
                              ग्राहक सामुदायिक नामांकन फॉर्म भरने के बाद फैशन
                              सलाहकार बन सकता है। यह जानने के लिए कि विभिन्न
                              रैंक कैसे प्राप्त करें।
                            </p>
                          </details>
                          <hr />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="row px-2">
                        <div class="col-sm-12 border">
                          <h5 class="text-center mt-2">पंजीकरण</h5>
                          <hr />
                          <details>
                            <summary>
                              फैशन सलाहकार बनने के लिए क्या आवश्यकताएँ हैं?
                            </summary>
                            <br />
                            <p>
                              एक ग्राहक के रूप में साइन अप करने के बाद आपको फैशन
                              सलाहकार बनने के लिए सामुदायिक नामांकन फॉर्म भरना
                              होगा। प्रत्येक व्यक्ति जिसकी आयु 18 वर्ष से अधिक
                              है और वह भारतीय नागरिक है, फैशन सलाहकार बनने के
                              लिए पात्र है। साइन अप करने के लिए आपको किसी मौजूदा
                              IBO पार्टनर द्वारा भी आमंत्रित किया जाना होगा।
                              फैशन सलाहकार के रूप में नामांकन के लिए आपको एक वैध
                              सरकारी आईडी की भी आवश्यकता होगी। कृपया अधिक
                              जानकारी के लिए बेचें और कमाएँ पर जाएँ।
                            </p>
                          </details>
                          <hr />
                          <details>
                            <summary>
                              RIL फैशन सलाहकार होने का क्या मतलब है?{" "}
                            </summary>

                            <p>
                              <ul>
                                एक बार जब आप RIL सलाहकार बन जाते हैं, तो आप एक
                                जीवंत समुदाय का हिस्सा बन जाते हैं। RIL समुदाय
                                के सदस्य के कुछ फायदे हैं:
                                <br />
                                <li> - आप एसोर्ट उत्पाद खरीद सकते हैं,</li>
                                <li>
                                  - आप विक्रेता भागीदारों के लिए हमारे वफादारी
                                  और इनाम कार्यक्रम में भाग ले सकते हैं
                                </li>
                                <li>
                                  - आप अनूठे प्रमोशन और ऑफ़र का लाभ उठाने के
                                  पात्र होंगे
                                </li>
                              </ul>
                            </p>
                          </details>
                          <hr />
                          <details>
                            <summary>
                              क्या मुझे RIL फैशन सलाहकार बनने के लिए भुगतान करना
                              होगा?
                            </summary>
                            <br />
                            <p>
                              आप सामुदायिक नामांकन फॉर्म भरकर RIL फैशन सलाहकार
                              के रूप में निःशुल्क नामांकन कर सकते हैं। कुछ नियम
                              एवं शर्तों के अधीन।
                              <br />
                              RIL किसी भी प्रकार के प्रशिक्षण की मेजबानी या कोई
                              राशि नहीं लेता है। एक फैशन सलाहकार के रूप में,
                              आपको केवल उन RIL उत्पादों के लिए भुगतान करना होगा
                              जो आप हमारी वेबसाइट पर ऑर्डर करते हैं।
                            </p>
                          </details>
                          <hr />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mx-5 mt-5">
                  <div class="col-sm-6">
                    <div class="row px-2">
                      <div class="col-sm-12 border">
                        <h5 class="text-center mt-2">ऑर्डरिंग</h5>
                        <hr />
                        <details>
                          <summary>
                            मैं एक ग्राहक के रूप में ऑर्डर कैसे दूं?
                          </summary>
                          <br />
                          <p>
                            RIL पर उत्पाद ऑर्डर करना बेहद आसान है, बस 3 चरण।
                            <br />
                            एक ग्राहक या समुदाय सदस्य के रूप में वेबसाइट पर
                            ऑर्डर देने के लिए -
                            <br />
                            <Link to={"https://rilindia.online/"}>
                              {" "}
                              rilindia.online
                            </Link>{" "}
                            पर अपने खाते में साइन-अप/लॉगिन करें हमारी साइट
                            ब्राउज़ करें अपना ऑर्डर ऑनलाइन दें.
                          </p>
                        </details>
                        <hr />
                        <details>
                          <summary>
                            मेरा ऑर्डर डिलीवर होने में कितना समय लगेगा?
                          </summary>
                          <br />
                          <p>
                            RIL इंडिया 5-7 कार्य दिवसों के भीतर सभी ऑर्डर डिलीवर
                            करता है। अप्रत्याशित परिस्थितियों में आपका ऑर्डर
                            डिलीवर होने में थोड़ा अधिक समय लग सकता है।
                          </p>
                        </details>
                        <hr />
                        <details>
                          <summary>
                            क्या RIL इंडिया मनी रिफंड गारंटी प्रदान करता है?
                          </summary>
                          <br />
                          <p>
                            RIL इंडिया विभिन्न श्रेणियों में ट्रेंडी और उच्च
                            गुणवत्ता वाले जीवनशैली उत्पादों में माहिर है।
                            <br />
                            हम अपने उत्पाद पर इतना अधिक विश्वास करते हैं कि हम
                            अपने द्वारा बेचे जाने वाले सभी उत्पादों पर 100%
                            संतुष्टि की गारंटी देते हैं। यदि आप हमारे उत्पाद से
                            पूरी तरह संतुष्ट नहीं हैं तो आप पूर्ण वापसी के लिए
                            चालान की तारीख से 17 दिनों के भीतर इसे वापस कर सकते
                            हैं।
                            <br />
                            <ul>
                              किसी उत्पाद को वापस करते समय निम्नलिखित शर्तें
                              लागू होती हैं:
                              <li>
                                - उत्पाद चालान की तारीख से 17 दिनों के भीतर वापस
                                किया जाना चाहिए।
                              </li>
                              <li>
                                - किसी आइटम को वापस करने से पहले सभी
                                टैग/लेबल/पैकेजिंग बरकरार रहनी चाहिए।
                              </li>
                              <li>
                                - यदि भौतिक उत्पाद बेचे गए उत्पाद के विवरण से
                                मेल नहीं खाता है या उत्पाद उस समय सीमा के भीतर
                                नहीं आता है जिसके दौरान इसे वापस किया जा सकता
                                है, तो RIL इंडिया किसी भी दावे को स्वीकार या
                                अस्वीकार करने का अधिकार सुरक्षित रखता है।
                              </li>
                              <li>
                                - ग्राहक को या तो उसके स्रोत बैंक खाते में
                                धनवापसी की पेशकश की जाएगी।
                              </li>
                              <li>
                                - किसी वस्तु या आइटम की शिपिंग के लिए सभी
                                हैंडलिंग शुल्क ग्राहक द्वारा वहन किया जाना
                                चाहिए।
                              </li>
                            </ul>
                          </p>
                        </details>
                        <hr />
                        <details>
                          <summary>
                            मैं एक ग्राहक के रूप में ऑर्डर कैसे दूं?
                          </summary>
                          <br />
                          <p>
                            RIL पर उत्पाद ऑर्डर करना बेहद आसान है, बस 3 चरण।
                            <br />
                            एक ग्राहक या समुदाय सदस्य के रूप में वेबसाइट पर
                            ऑर्डर देने के लिए -
                            <br />
                            <Link to="https://rilindia.online/">
                              rilindia.online
                            </Link>{" "}
                            पर अपने खाते में साइन-अप/लॉगिन करें हमारी साइट
                            ब्राउज़ करें अपना ऑर्डर ऑनलाइन दें.
                          </p>
                        </details>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 border">
                    <div class="row px-2">
                      <div class="col-sm-12">
                        <h5 class="text-center mt-2">शिपिंग</h5>
                        <hr />
                        <details>
                          <summary>भेजने का शुल्क</summary>
                          <br />
                          <p>
                            हम बेहतरीन स्थिति में और जल्द से जल्द एसॉर्ट से
                            खरीदे गए प्रोडक्ट्स को भेजने का प्रयास करते हैं।
                            भेजने के शुल्क नीचे दिया गया हैं:
                            <br />
                            <br />
                            <ul>
                              <li>
                              10001  रुपये और उससे अधिक की ख़रीददारी करने पर:
                              699  रुपये भेजने का शुल्क
                              </li>
                              <br />
                              <br />
                              {/* <li>
                                10001 रुपये से लेकर 49999 की ख़रीददारी करने पर:
                                699 रुपये भेजने का शुल्क
                              </li>
                              <br />
                              <br />
                              <li>
                                5001 रुपये से लेकर 10000 की ख़रीददारी करने पर:
                                249 रुपये भेजने का शुल्क
                              </li>
                              <br />
                              <br /> */}
                              <li>
                              10000 रुपये तक की ख़रीददारी करने पर: 199 रुपये
                                भेजने का शुल्क
                              </li>
                              <br />
                              <br />
                              <li>
                                यदि ऑर्डर कैसिल या होता है या गुम हो जाता है तो
                                हम पूरे पैसे वापस कर देंगे।
                              </li>
                              <br />
                              <br />
                              <li>
                                यदि ऑर्डर रिटर्न टो ओरिजिन हो जाता है तो हम
                                शिपिंग चार्जेज को छोड़कर ऑर्डर के पूरे पैसे वापस
                                कर देंगे।
                              </li>
                              <br />
                              <br />
                              <li>
                                एक बार जब ऑर्डर डिलीवर हो जाता है और आपके द्वारा
                                लौटा दिया जाता है तो हम शिपिंग चार्ज को छोड़कर
                                ऑर्डर राशि वापस कर देंगे।
                              </li>
                              <br />
                              <br />
                              <br />
                              <li>
                                यदि आप सीमित ऑर्डर रद्द करते हैं तो शिपिंग शुल्क
                                वापस नहीं किया जाएगा।
                              </li>
                              <br />
                              <br />* नियम और शर्तें लागू
                            </ul>
                          </p>
                        </details>
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div class="row px-4 mt-5">
                  <div class="col-sm-6 border ">
                    <div class="row px-2">
                      <div class="col-12 ">
                        <h5 class="text-center mt-2">RANKS</h5>
                        <hr />
                        <details>
                          <summary>
                            How many ranks can a RIL IBO Partner achieve?{" "}
                          </summary>
                          <br />
                          <p>
                            RIL IBO Partners can achieve seven ranks - Fashion 
                            Consultant, Franchise, Baron, Count, Marquise, Duke
                            and Arch-Duke. Any customer on RIL can become
                            Fashion Consultant after filling community
                            enrollment form. To know how to achieve various
                            Ranks.
                          </p>
                        </details>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 ">
                    <div class="row px-2">
                      <div class="col-12 border">
                        <h5 class="text-center mt-2">REGISTRATION</h5>
                        <hr />
                        <details>
                          <summary>
                            What are the requirements to become a Fashion
                            Consultant?{" "}
                          </summary>
                          <br />
                          <p>
                            After signing up as a customer you need to fill the
                            community enrollment form to become Fashion
                            Consultant.
                            <br />
                            Everyone who is over 18 years of age and is an
                            Indian citizen is eligible to become a Fashion
                            Consultant. You will need to be invited by an
                            existing IBO Partner as well to Sign Up.
                            <br />
                            You will also need a valid Government ID to enroll
                            as a Fashion Consultant. Please visit Sell & Earn
                            for more details.
                          </p>
                        </details>
                        <hr />
                        <details>
                          <summary>
                            What does it mean to be a RIL Fashion Consultant?{" "}
                          </summary>

                          <p>
                            <ul>
                              Once you become a RIL Consultant, you become a
                              part of a vibrant community. Some of the
                              advantages of a RIL community member are:
                              <br />
                              <li> - You can purchase RIL products,</li>
                              <li>
                                - You can take part in our loyalty and reward
                                programme for Seller Partners
                              </li>
                              <li>
                                - You would be eligible to take advantage of
                                unique promotions and offers
                              </li>
                            </ul>
                          </p>
                        </details>
                        <hr />
                        <details>
                          <summary>
                            Do I have to pay to become a RIL Fashion Consultant?
                          </summary>
                          <br />
                          <p>
                            You can enroll as a RIL Fashion Consultant for FREE
                            by filling community enrollment form. Subject to
                            certain terms and conditions.
                            <br />
                            RIL also does not host or charge any amount for any
                            kind of training. As a Fashion Consultant, you only
                            have to pay for the RIL products that you order on
                            our website.
                          </p>
                        </details>
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row px-4 mt-5">
                  <div class="col-sm-6  ">
                    <div class="row px-2">
                      <div class="col-12 border">
                        <h5 class="text-center mt-2">ORDERING</h5>
                        <hr />
                        <details>
                          <summary>
                            How do I place an order as a customer?
                          </summary>
                          <br />
                          <p>
                            Ordering a Product at RIL is Super easy, Just 3
                            Steps.
                            <br />
                            To Place an order on the website as a customer or
                            community Member -
                            <br />
                            Sign-Up/Login to your account at{" "}
                            <Link to="https://rilindia.online/">
                              rilindia.online
                            </Link>{" "}
                            Browse our site Place your order online.
                          </p>
                        </details>
                        <hr />
                        <details>
                          <summary>
                            How much time will it take for my order be
                            delivered?
                          </summary>
                          <br />
                          <p>
                            RIL INDIA delivers all orders within 5-7 working
                            days. In case of unforseen circumstances it may take
                            little longer to deliver your order.
                          </p>
                        </details>
                        <hr />
                        <details>
                          <summary>
                            Does RIL INDIA provide Money refund Guarantee?
                          </summary>
                          <br />
                          <p>
                            RIL INDIA specializes in trendy and high quality
                            lifestyle products in various categories.
                            <br />
                            We believe strongly in our product so much so that
                            we offer a 100% satisfaction guarantee on all the
                            products we sell. If you are not completely
                            satisfied with our product you may return it within
                            17 days from the date of invoice for a full refund.
                            <br />
                            <ul>
                              The Following conditions apply when returning a
                              product:
                              <li>
                                - The product must be returned within 17 days
                                from the date of invoice.
                              </li>
                              <li>
                                - All tags/labels/packaging should be intact
                                before returning an item.
                              </li>
                              <li>
                                - RIL INDIA reserves the right to accept or
                                reject any claim in case the physical product
                                does not match the description of the product
                                sold or if the product does not fall within the
                                timeline during which it can be returned.
                              </li>
                              <li>
                                - The Customer will either be offered a refunded
                                to his or her source bank account.
                              </li>
                              <li>
                                - All handling charges for shipping an item or
                                items must be borne by the Customer.
                              </li>
                            </ul>
                          </p>
                        </details>
                        <hr />
                        <details>
                          <summary>
                            {" "}
                            After placing the order how i will be communicated
                            about my order confirmation?
                          </summary>
                          <br />
                          <p>
                            For each order you will receive an invoice number.
                            The invoice number will be written clearly on your
                            invoice and will be visible on your confirmation
                            screen and will be available in your order history
                            as well.
                            <br />
                            The invoice number helps us identify you when you
                            contact RIL Customer Care. It also helps in solving
                            potential problems more quickly if you need help.
                            <br />
                            You invoice number is also needed in case you need
                            to make a complaint or return a product.
                          </p>
                        </details>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 border">
                    <div class="row px-2">
                      <div class="col-12 ">
                        <h5 class="text-center mt-2">SHIPPING</h5>
                        <hr />
                        <details>
                          <summary>Shipping Charges </summary>
                          <br />
                          <p>
                            We strive to deliver products purchased from RIL in
                            excellent condition and in the fastest time
                            possible. Shipping charges are mentioned below:
                            <br />
                            <br />
                            Upto Rs 1 to 10000: Rs 199 
                            <br />
                            From Rs 10001  to 41000: Rs 699 
                            <br />
                            {/* From 2500.01 to 5000: Rs 199
                            <br />
                            From 5000.01 to 10000: Rs 299
                            <br />
                            From 10000.01 to 20000: Rs 499
                            <br />
                            From 20000.01 to 40000: Rs 699
                            <br />
                            From 40000.01 to 50000 and above: Rs 799
                            <br /> */}
                            If the order is cancelled or lost, we will refund
                            the complete order amount.
                            <br />
                            If order is returned to origin then we will refund
                            the order amount excluding the shipping charges.
                            Once the order is delivered and returned by you then
                            we will refund the order amount excluding the
                            shipping charges.
                            <br />
                            If you cancel partial order, shipping charges will
                            not be refunded.
                            <br />* Terms and conditions apply
                          </p>
                        </details>
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* <div className="col-md-12 ">
              <div className="faq" id="accordion">
                {faqs_list &&
                  faqs_list?.map((data, i) => {
                    return (
                      <div className="card" key={i}>
                        <div
                          className="card-header"
                          id="faqHeading-1"
                          onClick={() => {
                            setfirst(i);
                          }}
                        >
                          <div className="mb-0">
                            <h5
                              className="faq-title newfaq"
                              data-toggle="collapse"
                              data-target="#faqCollapse-1"
                              data-aria-expanded="true"
                              data-aria-controls="faqCollapse-1"
                            >
                              <span className="badge">{i + 1}</span>
                              {data?.title}
                            </h5>
                          </div>
                        </div>
                        <div
                          id="faqCollapse-1"
                          className={first == i ? "" : "collapse"}
                          aria-labelledby="faqHeading-1"
                          data-parent="#accordion"
                        >
                          <div
                            className="card-body"
                            dangerouslySetInnerHTML={{
                              __html: data?.discription,
                            }}
                          ></div>
                        </div>
                      </div>
                    );
                  })}

                
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <FooterMain />
      {/* <Footer /> */}
    </div>
  );
}

export default Faqs;

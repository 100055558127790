import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../Redux/common/action";
import { useParams } from "react-router-dom";
import moment from "moment";
import ReactToPrint from "react-to-print";
import Button from "react-bootstrap/Button";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
function Invoice() {
  const order_details = useSelector((state) =>
    state?.allapi?.order_details ? state?.allapi?.order_details : {}
  );
  const dispatch = useDispatch();
  const componentRef = useRef();
  const { id } = useParams();

  console.log(order_details);
  const shippingCharge = order_details?.sub_total > 10000 ? 699 : 199;
  console.log(order_details?.orderproductsList);

  const totalSubtotalPricegstdd = order_details?.orderproductsList?.reduce(
    (total, obj) => {
      return total + (obj.sub_total * obj?.gst) / 100;
    },
    0
  );
  const totalSubtotalPricegst = totalSubtotalPricegstdd;
  useEffect(() => {
    dispatch(allapiAction.getInvoice1({}));
    dispatch(allapiAction.getuserOrderDetailsPub({ _id: id }));
    return () => {};
  }, []);

  const generatePDF = () => {
    const invoice = componentRef.current;

    html2canvas(invoice, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 100; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

      const pdfBlob = pdf.output("blob");

      // Open PDF in a new tab using window.URL.createObjectURL
      const pdfURL = window.URL.createObjectURL(pdfBlob);
      window.open(pdfURL, "_blank");
      // pdf.save("invoice.pdf");
    });
  };

  //      function exportToPDF() {
  //       const { jsPDF } = window.jspdf;
  //       const pdf = new jsPDF();
  //       pdf.text("Booking Data", 10, 10);

  //       // Capture the table element
  //       const table = document.querySelector("table");

  //       // Remove the last column from the table for PDF export
  //       removeLastColumn(table);

  //       // Use autoTable to handle table data
  //       pdf.autoTable({
  //           html: table,
  //           startY: 20, // Set the starting Y coordinate
  //           margin: { horizontal: 10 },
  //           pageBreak: 'auto', // Automatically handle page breaks
  //           styles: {
  //               fontSize: 8, // Smaller font size to fit more content
  //               cellPadding: 2, // Reduce cell padding to save space
  //               overflow: 'linebreak', // Ensure overflow is managed
  //           },
  //       });

  //       // Save the PDF
  //       pdf.save("booking_data.pdf");

  //       // Revert the changes to the table after exporting
  //       addLastColumn(table);
  //   }
  return (
    <div>
      <div className="container img100" ref={componentRef}>
        <div className="row">
          <div className="col-lg-12">
            <div class="p-1">
              <div class="row px-5 mt-3">
                <div class="col-sm-6">
                  {/* <p>5/5/24, 3:47 PM</p> */}
                  <p>{moment(new Date()).format("DD/MM/yyyy hh:mm A")}</p>
                </div>

                <div class="col-sm-6">
                  <p>Invoice | RIL INDIA</p>
                </div>
              </div>
              <br />
              <br />
              <br />
              <br />
              <div class="row px-5">
                <div class="col-sm-6"></div>

                <div class="col-sm-6">
                  <p>Invoice #:20240428342 Bill Code: #001176</p>
                  <p>Gst No:07AALCR1095C1ZF</p>
                  <p>Resolved Improve Life India Marketing Pvt. Ltd.</p>
                  <p>
                    Amrit vihar street no 7 a block Burari north Delhi India
                    110084
                  </p>
                </div>
              </div>

              <br />
              <br />
              <div class="row px-5">
                <div class="col-sm-6">
                  <h3>Billed To. :</h3>
                  <p class="d-inline">
                    <b> Name:</b> {order_details?.address_id?.name}
                  </p>
                  {/* <br /> */}
                  {/* <p class="d-inline">
                       <b>Last Name:</b> Deuri
                     </p> */}
                  <br />
                  <p class="d-inline">
                    <b>Billing Address:</b>
                  </p>
                  <br />
                  <p class="d-inline">
                    {order_details?.address_id?.address},
                    {order_details?.address_id?.city},
                    {order_details?.address_id?.state}
                    <br />
                    ZIP: {order_details?.address_id?.pincode}
                    <br />
                    Phone: {order_details?.address_id?.phone}
                    <br />
                    E-mail: {order_details?.address_id?.email}
                  </p>
                </div>
                <div class="col-sm-6"></div>
              </div>

              <br />
              <div class="row px-5">
                <div class="col-sm-6">
                  <h3>Payment Details:</h3>
                  <p class="d-inline">
                    <b>Payment Status :</b> <i>Fully Paid</i>
                  </p>
                  <br />
                  <p class="d-inline">
                    <b>Payment Method :</b> wallet
                  </p>
                </div>
                <div class="col-sm-6">
                  <h3 class="text-right">Order Date:</h3>
                  <br />
                  <p class="text-right">
                    <b>
                      {/* 30 Apr, 2024 */}
                      {moment(order_details?.createdAt).format("DD MMMM, YYYY")}
                    </b>
                  </p>
                </div>
              </div>

              {/* <!-- table start here --> */}

              <table class="table">
                <thead>
                  <tr>
                    <th style={{ borderTop: "none" }} scope="col">
                      <h4 class="text-left">
                        <b>Payment invoice</b>
                      </h4>
                    </th>
                    <th style={{ borderTop: "none" }} scope="col"></th>
                    <th style={{ borderTop: "none" }} scope="col"></th>
                    <th style={{ borderTop: "none" }} scope="col"></th>
                    <th style={{ borderTop: "none" }} scope="col"></th>
                    <th style={{ borderTop: "none" }} scope="col"></th>
                  </tr>

                  {/* <tr>
                       <th scope="col">No</th>
                       <th scope="col">Item</th>
                       <th scope="col">Options</th>
                       <th scope="col">Ouantity</th>
                       <th scope="col">Unit cost</th>
                       <th scope="col">Total</th>
                     </tr> */}
                  <tr className="border-bottom fs-6 fw-bold text-muted">
                    <th>S.No</th>
                    <th className="min-w-175px pb-2">Item</th>
                    <th className="min-w-70px text-end pb-2">Color</th>
                    <th className="min-w-70px text-end pb-2">Size</th>
                    <th className="min-w-70px text-end pb-2">Unit Cost</th>
                    <th className="min-w-80px text-end pb-2">Quantity</th>
                    <th className="min-w-100px text-end pb-2">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {order_details?.orderproductsList &&
                    order_details?.orderproductsList?.map((data, i) => {
                      console.log(data);

                      return (
                        <tr
                          class="text-center"
                          style={{ borderBottom: "2px solid black" }}
                        >
                          <td className="">{i + 1}</td>
                          <td>
                            <div className="d-flex ">
                              <div className="">
                                <div className="fw-bold">
                                  {data?.product_names}
                                </div>
                                <div className="fs-7 text-muted">
                                  {/* Delivery Date: 23/11/2022 */}
                                </div>
                              </div>
                            </div>
                          </td>
                          {/* -end::Product-*/}
                          {/* -begin::SKU-*/}
                          <td className="text-end"> {data?.colorId?.color}</td>
                          <td className="text-end"> {data?.sizeId?.size}</td>
                          <td className="text-end"> {data?.product_price}</td>
                          {/* -end::SKU-*/}
                          {/* -begin::Quantity-*/}
                          <td className="text-end"> {data?.itme_count}</td>
                          {/* -end::Quantity-*/}
                          {/* -begin::Total-*/}
                          <td className="text-end">₹ {data?.sub_total}</td>
                          {/* -end::Total-*/}
                        </tr>
                      );
                    })}
                  <tr>
                    <th style={{ border: "none" }}></th>
                    <th style={{ border: "none" }}></th>
                    <th style={{ border: "none" }}></th>
                    <td style={{ border: "none" }}></td>
                    <td style={{ border: "none" }}></td>
                    <td style={{ border: "none" }} class="text-right">
                      <b>Sub Total Amount: </b>
                    </td>
                    <td style={{ border: "none" }} class="text-right">
                      ₹ {order_details?.sub_total}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ border: "none" }}></th>
                    <th style={{ border: "none" }}></th>
                    <th style={{ border: "none" }}></th>
                    <td style={{ border: "none" }}></td>
                    <td style={{ border: "none" }}></td>
                    <td style={{ border: "none" }} class="text-right">
                      <b>Tax:</b>
                    </td>
                    <td style={{ border: "none" }} class="text-right">
                      ₹ {totalSubtotalPricegst}
                    </td>
                  </tr>
                  {/* ''<tr>
                                     <td colspan="6" className="text-end f-w-600">
                                       SubTotal
                                     </td>
                                     <td className="text-right">
                                       ₹{order_details?.sub_total}
                                     </td>
                                   </tr>
                                   <tr>
                                     <td colspan="6" className="text-end f-w-600">
                                       Shipping
                                     </td>
                                     <td className="text-right">
                                       ₹{shippingCharge}
                                     </td>
                                   </tr>
                                   <tr>
                                     <td colspan="6" className="text-end f-w-600">
                                       Gst
                                     </td>
                                     <td className="text-right">
                                       ₹{totalSubtotalPricegst}
                                     </td>
                                   </tr>
                                   <tr>
                                     <td colspan="6" className="text-end f-w-600">
                                       Grand Total
                                     </td>
                                     <td className="text-right f-w-600">
                                       ₹{order_details?.totalAmount}
                                     </td>
                             '' */}
                  <tr>
                    <th style={{ border: "none" }}></th>
                    <th style={{ border: "none" }}></th>
                    <th style={{ border: "none" }}></th>
                    <td style={{ border: "none" }}></td>
                    <td style={{ border: "none" }}></td>
                    <td style={{ border: "none" }} class="text-right">
                      <b>Shipping:</b>
                    </td>
                    <td style={{ border: "none" }} class="text-right">
                      ₹ {shippingCharge}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ border: "none" }}></th>
                    <th style={{ border: "none" }}></th>
                    <th style={{ border: "none" }}></th>
                    <th style={{ border: "none" }}></th>
                    <td style={{ border: "none" }}></td>
                    <td style={{ border: "none" }} class="text-right">
                      <b>Grand Total:</b>
                    </td>
                    <td style={{ border: "none" }} class="text-right">
                      ₹ {order_details?.totalAmount}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="row">
                <div className="col-3"></div>
                <div className="col-9 text-right mb-15 mt-30">
                  <p>
                    This is a computer generated receipt ,no signature required
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        {/* <button
          className="ant-btn ant-btn-default"
          onClick={() => {
            generatePDF();
          }}
        >
          Download
        </button> */}
        <ReactToPrint
          trigger={() => (
            <Button variant="primary">Print / Download</Button>
            // <button className='bg-blue-500 ml-5 text-white font-bold py-2 px-8 rounded shadow border-2 border-blue-500 hover:bg-transparent hover:text-blue-500 transition-all duration-300'>
            //   Print / Download
            // </button>
          )}
          content={() => componentRef.current}
        />
      </div>
    </div>
  );
}

export default Invoice;

// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import FooterMain from "../Layout/FooterMain";
import HeaderMain from "../Layout/HeaderMain";

function ShopCartApp() {
  const get_all_cart_itme = useSelector((state) =>
    state?.allapi?.get_all_cart_itme ? state?.allapi?.get_all_cart_itme : []
  );

  const totalSubtotalPrice = get_all_cart_itme.reduce((total, obj) => {
    return total + obj.subtotalprice;
  }, 0);
  const totalSubtotalPricegst = get_all_cart_itme.reduce((total, obj) => {
    return total + (obj.subtotalprice * obj?.gst) / 100;
  }, 0);

  console.log(totalSubtotalPricegst);

  const dispatch = useDispatch();

  const hendleCartClear = () => {
    if (localStorage.getItem("access_token")) {
      //   setitmeChange([]);
      dispatch(allapiAction.removeAllCartItem());
      return () => {};
    } else {
      //   setitmeChange([]);
      //   setArr([]);
      localStorage.setItem("cart_product", JSON.stringify([]));
      dispatch(allapiAction.getcartProduct({ data: [] }));
      return () => {};
    }

    // removeAllCartItem
  };

  const removeCart = (e, color, size) => {
    if (localStorage.getItem("access_token")) {
      dispatch(
        allapiAction.removeCartItem({
          //   userid: get_user_profile?.id,
          product_id: e,
          color: color,
          size: size,
        })
      );
      //   setTimeout(() => {
      //     dispatch(allapiAction.getcartitem({}));
      //   }, 500);
      return () => {};
    } else {
      var lists = get_all_cart_itme.filter((x) => {
        return x.product_id != e;
      });

      dispatch(
        allapiAction.getcartProduct({
          data: lists,
          //   userid: get_user_profile?.id,
        })
      );
      //   setArr(lists);
      localStorage.setItem("cart_product", JSON.stringify(lists));
      return () => {};
    }
  };

  const addItmeCount = (e, color, size) => {
    if (localStorage.getItem("access_token")) {
      dispatch(
        allapiAction.addItemCartCount({
          //   userid: get_user_profile?.id,
          product_id: e,
          color: color,
          size: size,
        })
      );
      return () => {};
    } else {
      const finalresult =
        get_all_cart_itme &&
        get_all_cart_itme?.map((data) => {
          data.item = data?.product_id == e ? data?.item + 1 : data?.item;
          return data;
        });
      //   setitmeChange(finalresult);
      //   setArr(finalresult);
      dispatch(
        allapiAction.getcartProduct({
          data: finalresult,
          //   userid: get_user_profile?.id,
        })
      );
      localStorage.setItem("cart_product", JSON.stringify(finalresult));
    }
  };
  const removeItmeCount = (e, color, size) => {
    if (localStorage.getItem("access_token")) {
      dispatch(
        allapiAction.removeItemCartCount({
          //   userid: get_user_profile?.id,
          product_id: e,
          color: color,
          size: size,
        })
      );
      return () => {};
    } else {
      const secondObj = get_all_cart_itme.find((item) => item._id == e);
      if (secondObj?.item > 1) {
        const finalresult =
          get_all_cart_itme &&
          get_all_cart_itme?.map((data) => {
            data.item = data?.product_id == e ? data?.item - 1 : data?.item;
            return data;
          });
        //   setitmeChange(finalresult);
        //   setArr(finalresult);
        dispatch(
          allapiAction.getcartProduct({
            data: finalresult,
            //   userid: get_user_profile?.id,
          })
        );
        localStorage.setItem("cart_product", JSON.stringify(finalresult));
      } else {
        toast.error("minimum quntity 1 select");
      }
    }
  };
  const shippingCharge = totalSubtotalPrice > 10000 ? 699 : 199;

  const totalAmount =
    totalSubtotalPrice + shippingCharge + totalSubtotalPricegst;
  return (
    <div className="AppPro">
      <HeaderMain />
      <main className="main">
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            {/* <div className="breadcrumb">
              <Link to="/" rel="nofollow">
                <i className="fi-rs-home mr-5"></i>home
              </Link>
              <span></span> Shop
              <span></span> Cart
            </div> */}
          </div>
        </div>
        <div className="container-fluid mb-80 mt-10">
          {get_all_cart_itme?.length == 0 && (
            <div
              className="row justify-content-center align-items-center text-center"
              style={{
                height: "55vh",
                backgroundImage: "url(/wishlist.png)",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <div className="col-12">
                <div style={{ width: "50px", margin: "auto" }}>
                  <img src="/bag.png" alt="bag" style={{ width: "100%" }} />
                </div>

                <h4>Oops! Your bag is empty!</h4>
                <p className="  mt-3">
                  Time to add some amazing items and start shopping!
                </p>
                <Link
                  to="/products?searchp="
                  className="btn btn-outline-danger mt-4"
                >
                  <i className="fi-rs-shopping-bag mr-5"></i>Shop Now
                </Link>
              </div>
            </div>
          )}

          {get_all_cart_itme?.length > 0 && (
            <div className="row">
              <div className="col-lg-8 mb-10">
                <h1 className="heading-2 mb-10">Your Bag</h1>
                <div className="d-flex justify-content-between">
                  <h6 className="text-body">
                    There are{" "}
                    <span className="text-brand">
                      {get_all_cart_itme?.length}
                    </span>{" "}
                    products in your cart
                  </h6>
                  <h6 className="text-body">
                    <a
                      onClick={() => {
                        hendleCartClear();
                      }}
                      className="text-muted"
                    >
                      <i className="fi-rs-trash mr-5"></i>Clear Cart
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          )}
          {get_all_cart_itme?.length > 0 && (
            <div className="row">
              <div className="col-lg-8">
                {get_all_cart_itme &&
                  get_all_cart_itme?.map((data, i) => {
                    return (
                      <div class="cart-card row align-items-center">
                        <div className="col-2 imghe">
                          <img
                            src={URL.API_BASE_URL + data?.featureImage}
                            alt="Product Image"
                            class="img-fluid"
                            style={{ width: "60px", height: "60px" }}
                          />
                        </div>
                        <div className="col-9">
                          <div class="flex-grow-1">
                            <h5 class="mb-1">{data?.title}</h5>
                            {/* <p class="text-muted mb-2">Mattifies & Blurs Pores</p> */}
                            <p class="text-muted mb-2">
                              {" "}
                              {data?.productcolorId?.color}
                            </p>
                            <p class="text-muted mb-2">
                              {data?.size} , ₹{data?.afterdiscountprice}{" "}
                            </p>
                            {/* <p class="text-muted mb-2">
                            30ml • Blur Perfect Primer
                          </p> */}
                            <div class="d-flex align-items-center">
                              <label for="quantity" class="me-2">
                                Quantity:
                              </label>
                              <div class="cart-item">
                                <button
                                  class="quantity-btn"
                                  onClick={() => {
                                    removeItmeCount(
                                      data?.productId?._id,
                                      data?.productcolorId?._id,
                                      data?._id
                                    );
                                  }}
                                >
                                  -
                                </button>
                                <span class="quantity">{data?.item}</span>
                                <button
                                  onClick={() => {
                                    addItmeCount(
                                      data?.productId?._id,
                                      data?.productcolorId?._id,
                                      data?._id
                                    );
                                  }}
                                  class="quantity-btn"
                                >
                                  +
                                </button>
                              </div>
                              {/* <select
                              id="quantity"
                              class="form-select form-select-sm quantity-selector"
                            >
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3" selected>
                                3
                              </option>
                              <option value="4">4</option>
                            </select> */}
                            </div>
                          </div>
                        </div>
                        <div className="col-1">
                          <div class="text-end">
                            <a
                              onClick={(e) => {
                                removeCart(
                                  data?.productId?._id,
                                  data?.productcolorId?._id,
                                  data?._id
                                );
                              }}
                              className="text-body"
                            >
                              {/* <i className="fi-rs-trash"></i> */}
                              <i className="fi-rs-trash mr-5"></i>
                              {/* <i class="fa fa-times" aria-hidden="true"></i> */}
                            </a>

                            {/* <button
                            class="btn btn-light text-danger p-0"
                            title="Remove item"
                          >
                            <i className="fi-rs-trash mr-5"></i>
                          </button> */}
                          </div>
                        </div>
                        <div className="col-12">
                          <div class="text-end">
                            <p class="mt-2 mb-0">
                              {/* <span class="item-price me-2">₹2697</span> */}
                              <span class="discounted-price">
                                ₹{data?.subtotalprice}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                {/* <div class="cart-card d-flex align-items-center">
                <img
                  src="https://via.placeholder.com/50"
                  alt="Product Image"
                  class="img-fluid me-3"
                  style={{ width: "60px", height: "60px" }}
                />
                <div class="flex-grow-1">
                  <h5 class="mb-1">Lakme Unreal Blur Perfect Face Primer</h5>
                  <p class="text-muted mb-2">Mattifies & Blurs Pores</p>
                  <p class="text-muted mb-2">30ml • Blur Perfect Primer</p>
                  <div class="d-flex align-items-center">
                    <label for="quantity" class="me-2">
                      Quantity:
                    </label>
                    <select
                      id="quantity"
                      class="form-select form-select-sm quantity-selector"
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3" selected>
                        3
                      </option>
                      <option value="4">4</option>
                    </select>
                  </div>
                </div>
                <div class="text-end">
                  <button
                    class="btn btn-light text-danger p-0"
                    title="Remove item"
                  >
                    <i class="bi bi-trash"></i>
                  </button>
                  <p class="mt-2 mb-0">
                    <span class="item-price me-2">₹2697</span>
                    <span class="discounted-price">₹2157</span>
                  </p>
                </div>
              </div> */}
                {/* <div class="text-end mt-3">
                <p class="mb-0">
                  You pay <span class="fw-bold">₹2157</span>
                </p>
              </div> */}

                <div className="shopping-summery">
                  {/* <table className="">
                  <thead>
                    <tr className="main-heading">
                       
                      <th scope="col" colspan="2">
                        Product
                      </th>
                      <th scope="col" style={{ minWidth: "100px" }}>
                        Color
                      </th>
                      <th scope="col" style={{ minWidth: "100px" }}>
                        Size
                      </th>
                      <th scope="col" style={{ minWidth: "100px" }}>
                        Unit Price
                      </th>
                      <th scope="col" style={{ minWidth: "100px" }}>
                        Quantity
                      </th>
                      <th scope="col" style={{ minWidth: "100px" }}>
                        Subtotal
                      </th>
                      <th
                        scope="col"
                        className="end"
                        style={{ minWidth: "100px" }}
                      >
                        Remove
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {get_all_cart_itme &&
                      get_all_cart_itme?.map((data, i) => {
                        console.log(data);
                        return (
                          <tr className="pt-30 oncartl" key={i}>
                             
                            <td className="image product-thumbnail ">
                              <img
                                src={URL.API_BASE_URL + data?.featureImage}
                                alt={data?.title}
                                height="100px"
                                width={"100px"}
                              />
                            </td>
                            <td className="product-des product-name">
                              <h6 className="mb-5">
                                <Link
                                  className="product-name mb-10 text-heading"
                                  to={"/product-details/" + data?.pro_sulg}
                                >
                                  {data?.title}
                                </Link>
                              </h6>
                              
                            </td>
                            <td className="price" data-title="Price">
                              <h6 className="text-body">
                                {data?.productcolorId?.color}{" "}
                              </h6>
                            </td>
                            <td className="price" data-title="Price">
                              <h6 className="text-body quantity">
                                {data?.size}{" "}
                              </h6>
                            </td>
                            <td className="price" data-title="Price">
                              <h4 className="text-body">
                                ₹{data?.afterdiscountprice}{" "}
                              </h4>
                            </td>
                            <td
                              className="text-center detail-info"
                              data-title="Stock"
                            >
                               
                              <div class="cart-item">
                                <button
                                  class="quantity-btn"
                                  onClick={() => {
                                    removeItmeCount(
                                      data?.productId?._id,
                                      data?.productcolorId?._id,
                                      data?._id
                                    );
                                  }}
                                >
                                  -
                                </button>
                                <span class="quantity">{data?.item}</span>
                                <button
                                  onClick={() => {
                                    addItmeCount(
                                      data?.productId?._id,
                                      data?.productcolorId?._id,
                                      data?._id
                                    );
                                  }}
                                  class="quantity-btn"
                                >
                                  +
                                </button>
                              </div>
                            </td>
                            <td className="price" data-title="Price">
                              <h4 className="text-brand">
                                ₹{data?.subtotalprice}{" "}
                              </h4>
                            </td>
                            <td
                              className="action text-center"
                              data-title="Remove"
                            >
                              <a
                                onClick={(e) => {
                                  removeCart(
                                    data?.productId?._id,
                                    data?.productcolorId?._id,
                                    data?._id
                                  );
                                }}
                                className="text-body"
                              >
                              
                                <i class="fa fa-times" aria-hidden="true"></i>
                              </a>
                            </td>
                          </tr>
                        );
                      })}

                    
                  </tbody>
                </table> */}
                </div>
                <div className="divider-2 mb-30"></div>
                {/* <div className="cart-action d-flex justify-content-between">
                <a className="btn ">
                  <i className="fi-rs-arrow-left mr-10"></i>Continue Shopping
                </a>
                <a className="btn  mr-10 mb-sm-15">
                  <i className="fi-rs-refresh mr-10"></i>Update Cart
                </a>
              </div> */}
              </div>
              <div className="col-lg-4">
                <div className="border  checkoutbx p-md-4 cart-totals ml-30">
                  <div className="table-responsive">
                    <table className="table no-border">
                      <tbody>
                        <tr>
                          <td className="cart_total_label">
                            <h6 className="">Subtotal</h6>
                            {/* text-muted */}
                          </td>
                          <td className="cart_total_amount">
                            <h4 className="text-brand text-end">
                              ₹{totalSubtotalPrice}
                            </h4>
                          </td>
                        </tr>
                        {/* <tr>
                        <td scope="col" colspan="2">
                          <div className="divider-2 mt-10 mb-10"></div>
                        </td>
                      </tr> */}
                        <tr>
                          <td className="cart_total_label">
                            <h6 className="">Shipping</h6>
                          </td>
                          <td className="cart_total_amount">
                            <h5 className="text-heading text-end">
                              {totalSubtotalPrice > 0 && shippingCharge}
                            </h5>
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="cart_total_label">
                            <h6 className="">gst</h6>
                          </td>
                          <td className="cart_total_amount">
                            <h5 className="text-heading text-end">
                              {totalSubtotalPricegst}
                            </h5>
                          </td>{" "}
                        </tr>
                        {/* <tr>
                        <td scope="col" colspan="2">
                          <div className="divider-2 mt-10 mb-10"></div>
                        </td>
                      </tr> */}
                        <tr>
                          <td className="cart_total_label">
                            <h6 className="">Amount</h6>
                          </td>
                          <td className="cart_total_amount">
                            <h4 className="text-brand text-end">
                              ₹{totalAmount}
                            </h4>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {get_all_cart_itme?.length > 0 && (
                    <Link to="/checkout-address" className="btn mb-20 w-100">
                      Proceed To CheckOut
                      <i className="fi-rs-sign-out ml-15"></i>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </main>
      <FooterMain />
    </div>
  );
}

export default ShopCartApp;
